import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'

import ButtonBase from '../../../components/partials/button'
import BaseDialog from '../../../components/dialog'
import iconTimes from '../../../assets/images/icon-times.svg'
import { customStyles } from '../../../constants/customStyles'
import { GROUP_PRINCIPAL_TO_PRINCIPAL_ACCOUNT } from '../../../queries/adminPortal'
import { useSession } from '../../../helpers/checkSession'
import { notify } from '../../../components/toastify'
import { MESSAGES, STATUS } from '../../../constants/messages'

interface PropsAction {
  modalIsOpen?: boolean
  hiddenBlock?: string
  handleCloseDialog?: (val: boolean) => void
  setIsDisable?: (val: boolean) => void
  isValueInputPrincipalSelected?: { idPrincipal: string; namePrincipal: string }
  isValueInputPAccountSelectedTo?: { idPrincipalAccountTo: string; namePrincipalAccountTo: string }
  handleSubmitPrincipal?: () => void
}

const GroupPrincipalToPAccount: React.FunctionComponent<PropsAction> = ({
  modalIsOpen,
  handleCloseDialog = () => null,
  setIsDisable = () => false,
  isValueInputPrincipalSelected = { idPrincipal: '', namePrincipal: '' },
  isValueInputPAccountSelectedTo = { idPrincipalAccountTo: '', namePrincipalAccountTo: '' },
  handleSubmitPrincipal = () => null,
}) => {
  const { handleSubmit, reset } = useForm<PropsAction>()
  const { handleErrorSession } = useSession()
  const [GroupPrincipalToPrincipalAccount] = useMutation(GROUP_PRINCIPAL_TO_PRINCIPAL_ACCOUNT)

  const onSubmit = handleSubmit(async () => {
    const variables = {
      principalId: isValueInputPrincipalSelected.idPrincipal,
      principalAccountId: isValueInputPAccountSelectedTo.idPrincipalAccountTo,
    }
    try {
      const dataRes = await GroupPrincipalToPrincipalAccount({ variables })
      if (dataRes) {
        notify(MESSAGES.SUCCESS.S_CREATED_GROUP_PRINCIPAL_TO_PACC, STATUS.SUCCESS)
        handleCloseDialog(false)
        reset()
      }
      setIsDisable(true)
      handleSubmitPrincipal()
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_CREATED_GROUP_P_TO_PACC)
    }
  })

  const handleGroupPToPAcc = async () => {
    await onSubmit()
  }

  const handleCloseGroupPToPAccDialog = () => {
    handleCloseDialog(false)
    reset()
  }

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={customStyles}>
      <div className="w-[378px] p-1">
        <div className="absolute right-4 top-4">
          <img
            src={iconTimes}
            alt="close"
            className="cursor-pointer"
            onClick={handleCloseGroupPToPAccDialog}
          />
        </div>
        <p className="text-center text-headline5A mb-2 ml-4">Group Principal Account</p>
        <p className="text-center text-body1 mb-2 pt-2">
          Do you want to group
          <span className="font-semibold"> {isValueInputPrincipalSelected.namePrincipal} </span>
          to
          <span className="font-semibold">
            {' '}
            {isValueInputPAccountSelectedTo.namePrincipalAccountTo}
          </span>
          ?
        </p>
        <div className="flex w-[372px]">
          <ButtonBase
            type="submit"
            className="w-[180px] mt-4 bg-primary-1 min-w-min text-center"
            onClick={handleGroupPToPAcc}
            bgDisabled="bg-primary-shade3"
          >
            Group
          </ButtonBase>
          <ButtonBase
            type="submit"
            className="w-[180px] mt-4 ml-3 justify-around border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4"
            onClick={handleCloseGroupPToPAccDialog}
            bgDisabled="bg-primary-shade3"
          >
            Cancel
          </ButtonBase>
        </div>
      </div>
    </BaseDialog>
  )
}

export default GroupPrincipalToPAccount
