import React from 'react'
import classNames from 'classnames'

import {
  RegisterOptions,
  DeepMap,
  FieldError,
  UseFormRegister,
  Path,
  Controller,
  FieldValues,
  Control,
} from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import InputBase, { InputProps } from './inputBase'
import { FormErrorMessage } from './errorMessageBase'
import { get } from 'lodash'
import { checkTenDigits } from '../../utils/regex'

export type FormInputProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>
  rules?: RegisterOptions
  register?: UseFormRegister<TFormValues>
  errors?: Partial<DeepMap<TFormValues, FieldError>>
  inputClassName?: string
  control?: Control<FieldValues, any> | any
  value?: string
  maxLength?: number
} & Omit<InputProps, 'name'>

export const FormInputPhoneNumber = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  className,
  inputClassName,
  control,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value,
  maxLength,
  ...props
}: FormInputProps<TFormValues>): JSX.Element => {
  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMessages = get(errors, name)
  const hasError = !!(errors && errorMessages)
  const [isErrorPhone, setIsErrorPhone] = React.useState<boolean>(true)

  const formatPhoneNumber = (value: any) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, '')

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 4) return phoneNumber

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }

  const handlePaste = (event: any) => {
    checkTenDigits(event.clipboardData.getData('text'))
    setIsErrorPhone(!checkTenDigits(event.clipboardData.getData('text')))
  }

  React.useEffect(() => {
    const handlePasteAnywhere = (event: any) => {
      setIsErrorPhone(!checkTenDigits(event.clipboardData.getData('text')))
      handlePaste(event.clipboardData.getData('text'))
    }

    window.addEventListener('paste', handlePasteAnywhere)

    return () => {
      window.removeEventListener('paste', handlePasteAnywhere)
    }
  }, [])

  return (
    <div className={className} aria-live="polite">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <InputBase
            isError={hasError || isErrorPhone}
            onChange={(e: any) => {
              onChange(e)
            }}
            onPaste={handlePaste}
            value={maxLength ? value : formatPhoneNumber(value)}
            aria-invalid={hasError || isErrorPhone}
            className={classNames(
              {
                'border-red-900 focus:border-red-900': hasError,
                '!border-neutral-6 focus:border-neutral-3': !isErrorPhone,
              },
              inputClassName,
            )}
            {...props}
            {...(register && register(name, rules))}
            maxLength={maxLength ? maxLength : 14}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name as any}
        render={({ message }) => (
          <FormErrorMessage className="mt-1">{isErrorPhone && message}</FormErrorMessage>
        )}
      />
    </div>
  )
}
