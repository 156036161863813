import ActivitiesManagement from './'
import { PageRoute } from '../../routes/routerInterface'
import { checkProdSite } from '../../helpers/checkProdSite'

const activitiesManagementRouters: PageRoute[] = [
  {
    path: '/activities-management',
    element: ActivitiesManagement,
    isProtected: true,
    permission: checkProdSite(true, 'Developer'),
  },
]

export default activitiesManagementRouters
