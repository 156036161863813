import React from 'react'
import IconCopy from '../../../assets/images/icon-copy-bg.svg'
import { MESSAGES } from '../../../constants/messages'
import { onHandleCopyItem } from '../../../helpers/functionUtils'

interface Props {
  principalAccountDetailInfo?: any | object
}

const classInfoKey = 'my-3 text-neutral-3 text-body2 leading-6 min-w-[160px]'
const classInfoVal = 'my-3 ml-6 text-neutral-3 text-body1 font-semibold min-w-[460px]'

const PrincipalAccountInfo: React.FC<Props> = ({ principalAccountDetailInfo }) => {
  const onHandleCopyPrincipalKey = async (event: any, principalKey: string) => {
    onHandleCopyItem(
      principalKey,
      MESSAGES.INFO.I_COPIED_PRINCIPAL_ACCOUNT_KEY,
      MESSAGES.ERROR.E_COPY_FAILED,
    )
    event.preventDefault()
  }

  const handleShowInfo = (info: string) => {
    if (info) {
      return info
    } else {
      return `No data available`
    }
  }

  return (
    <div className="flex">
      <div>
        <p className={classInfoKey}>Business email</p>
        <p className={classInfoKey}>Contact name</p>
        <p className={classInfoKey}>Phone number</p>
        <p className={classInfoKey}>Address</p>
        <p className={classInfoKey}>Number of Principal</p>
        <p className={classInfoKey}>Principal account key</p>
      </div>
      <div>
        <p className={classInfoVal}>
          {handleShowInfo(principalAccountDetailInfo?.principalAccountBusinessEmail)}
        </p>
        <p className={classInfoVal}>
          {handleShowInfo(principalAccountDetailInfo?.principalAccountContactName)}
        </p>
        <p className={classInfoVal}>
          {handleShowInfo(principalAccountDetailInfo?.principalAccountPhoneNumber)}
        </p>
        <p className={classInfoVal}>
          {handleShowInfo(principalAccountDetailInfo?.principalAccountAddressLine1)}
        </p>
        <p className={classInfoVal}>{handleShowInfo(principalAccountDetailInfo?.countPrincipal)}</p>
        <div className={`${classInfoVal} flex`}>
          <p className="text-ellipsis overflow-hidden">
            {handleShowInfo(principalAccountDetailInfo?.principalAccountId)}
          </p>{' '}
          <img
            src={IconCopy}
            alt="IconCopy"
            className="cursor-pointer mt-[-6px] ml-3"
            onClick={(event) =>
              onHandleCopyPrincipalKey(event, principalAccountDetailInfo?.principalAccountId) ?? ''
            }
          />
        </div>
      </div>
    </div>
  )
}

export default PrincipalAccountInfo
