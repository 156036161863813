import React from 'react'
import AgenciesList from '../developer-portal/table/my-agencies-list/myAgencies'

const MyAgencies = () => {
  return (
    <div className="px-20 py-10">
      <div className="flex items-center justify-between">
        <p className="text-headline5A text-neutral-1 md:ml-[-40px]">Agency list</p>
      </div>
      <div className="md:mr-[-40px] md:ml-[-40px]">
        <AgenciesList />
      </div>
    </div>
  )
}

export default MyAgencies
