import React, { FC } from 'react'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'

import BaseDialog from '../../../components/dialog'
import InputBase from '../../../components/partials/inputBase'
import iconTimes from '../../../assets/images/icon-times.svg'
import iconQues from '../../../assets/images/icon-question.svg'
import ButtonBase from '../../../components/partials/button'
import { defaultCustomStyles } from '../../../constants/customStyles'

type Props = {
  handleCloseDialog?: (val: boolean) => void
  modalIsOpen?: boolean
  handleGenerateKey?: () => void
  handleChangeKey?: (val: string) => void
  keyValue?: string
  isDisableGenerateKey?: boolean
  errorInvalidKey?: string
}

const GenerateKeyDialog: FC<Props> = ({
  handleCloseDialog = () => null,
  handleGenerateKey = () => null,
  handleChangeKey = () => null,
  modalIsOpen,
  keyValue,
  isDisableGenerateKey,
  errorInvalidKey,
}) => {
  const handleChange = (val: string) => {
    handleChangeKey(val)
  }

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={defaultCustomStyles}>
      <div>
        <div className="flex justify-between">
          <div className="absolute right-4 top-4">
            <img
              src={iconTimes}
              alt=""
              className="cursor-pointer"
              onClick={() => handleCloseDialog(false)}
            />
          </div>
        </div>
        <p className="text-center text-headline5 text-neutral-1 mb-6">Generate a key</p>
        <div className="flex mb-3">
          <p className="text-hairline1 text-neutral-2">Key name</p>
          <img
            data-for="main"
            data-tip="What do you want to call this key?
            Hint your server name or anything
            to recognize how you use this key"
            data-iscapture="true"
            src={iconQues}
            alt=""
            className="ml-[10px] rounded-sm"
          />
        </div>
        <InputBase
          placeholder="Enter your key name"
          type="text"
          className={classNames([
            'w-full',
            `${errorInvalidKey && 'border-2 border-secondary-bold2 focus:border-secondary-bold2'}`,
          ])}
          value={keyValue}
          handleChange={handleChange}
        />
        <p className="mt-1 text-red-900 font-medium text-sm">{errorInvalidKey}</p>
        <ButtonBase
          type="submit"
          className="w-full text-neutral-8 mt-4 bg-primary-1 "
          bgDisabled="bg-primary-shade3"
          onClick={handleGenerateKey}
          disabled={isDisableGenerateKey}
        >
          Generate
        </ButtonBase>
        <ReactTooltip
          id="main"
          place="top"
          type="dark"
          effect="float"
          multiline={true}
          className="w-[280px] p-4 rounded-lg"
          offset={{ top: 0, left: 0 }}
        />
      </div>
    </BaseDialog>
  )
}

export default GenerateKeyDialog
