export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    boxShadow: '8px 11px 21px 0px rgba(148,144,144,0.75)',
    background: '#fdfdfd',
  },
}

export const defaultCustomStyles = {
  content: {
    width: '420px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '24px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    boxShadow: '8px 11px 21px 0px rgba(148,144,144,0.75)',
    background: '#fdfdfd',
    border: '1px solid #F2F2F2',
  },
}

export const styleRequestAccount = {
  content: {
    width: '90%',
    maxWidth: '812px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    boxShadow: '8px 11px 21px 0px rgba(148,144,144,0.75)',
    background: '#fdfdfd',
  },
}
