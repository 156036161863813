import React, { FC, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'

import BaseDialog from '../../../components/dialog'
import ButtonBase from '../../../components/partials/button'
import { FormInput } from '../../../components/partials/formInput'
import { FormInputPhoneNumber } from '../../../components/partials/formInputPhoneNumber'
import { RootState } from '../../../store'
import { setSpamming } from '../../../redux/actions/users'
import { REQUEST_AN_ACCOUNT } from '../../../queries/quickStart'
import { notify } from '../../../components/toastify'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { alphabetPattern, emailPattern, americanPhoneNumberPattern } from '../../../utils/regex'
import iconTimes from '../../../assets/images/icon-times.svg'
import { useSession } from '../../../helpers/checkSession'
import DropdownBase from '../../../components/partials/dropDown'
import { AccountType, optionsAccountType } from './requestAnAccountModel'
import { styleRequestAccount } from '../../../constants/customStyles'

export type RegistrationFormFields = {
  accountType: string
  organizationName: string
  partyId?: string
  kind?: string
  businessSponsorFirstName: string
  businessSponsorLastName: string
  businessSponsorEmail: string
  businessSponsorPhoneNumber?: string
  firstName: string
  lastName: string
  email: string
  phoneNumber?: string
}

type Props = {
  handleCloseDialog?: (val: boolean) => void
  modalIsOpen?: boolean
  carrierList?: any
}

const RequestAnAccount: FC<Props> = ({
  handleCloseDialog = () => null,
  modalIsOpen,
  carrierList,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<RegistrationFormFields>()
  let captcha: ReCAPTCHA
  const googleSiteKey = process.env.REACT_APP_DEVELOPMENT_GG_SITE_KEY
  const dispatch = useDispatch()
  const { handleErrorSession } = useSession()
  const spammingCounting = useSelector((state: RootState) => state.userReducers?.spammingCount)
  const [requestAccount] = useMutation(REQUEST_AN_ACCOUNT)
  const [isInvalid, setIsInvalid] = useState(false)
  const [isAccountType, setIsAccountType] = useState<AccountType>({
    id: '',
    name: '',
    kind: null,
  })
  const [isRequiredAccountType, setIsRequiredAccountType] = useState<boolean>(
    !!getValues('accountType'),
  )
  const [isRequiredOrganizationName, setIsRequiredOrganizationName] = useState<boolean>(
    !!getValues('organizationName'),
  )

  const onSubmit = handleSubmit(async (data) => {
    const kind = isAccountType?.kind
    const variables = {
      kind: kind,
      organizationName: data?.organizationName.trim(),
      partyId: kind === 'carriers' ? data?.partyId : null,
      businessSponsorFirstName: data?.businessSponsorFirstName.trim(),
      businessSponsorLastName: data?.businessSponsorLastName.trim(),
      businessSponsorEmail: data?.businessSponsorEmail.trim(),
      businessSponsorPhoneNumber: data?.businessSponsorPhoneNumber,
      firstName: data?.firstName.trim(),
      lastName: data?.lastName.trim(),
      email: data?.email.trim(),
      phoneNumber: data?.phoneNumber,
    }
    try {
      const dataRes = await requestAccount({ variables: variables })
      if (dataRes) {
        notify(MESSAGES.SUCCESS.S_CREATED_ACCOUNT, STATUS.SUCCESS)
        handleCloseDialog(false)
        handleCloseRequestAccountDialog()
        reset()
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_CREATE_ACCOUNT)
      // handle count spamming when the user requests account fail
      dispatch(setSpamming({ count: spammingCounting + 1, date: new Date().getTime() }))
      resetCaptcha()
    }
  })

  const setCaptchaRef = (ref: ReCAPTCHA | null) => {
    if (ref) {
      return (captcha = ref)
    }
  }

  const resetCaptcha = () => {
    captcha.reset()
  }

  const handleCloseRequestAccountDialog = () => {
    handleCloseDialog(false)
    setIsAccountType({
      id: '',
      name: '',
      kind: null,
    })
    setValue('organizationName', '')
    setIsRequiredAccountType(false)
    setIsRequiredOrganizationName(false)
    reset()
  }

  const handleCheckReCaptcha = () => {
    setIsInvalid(false)
  }

  const handleTrimValues = () => {
    if (getValues('accountType')) {
      setIsRequiredAccountType(false)
    } else {
      setIsRequiredAccountType(true)
    }
    if (getValues('organizationName')) {
      setIsRequiredOrganizationName(false)
    } else {
      setIsRequiredOrganizationName(true)
    }
    setValue('businessSponsorFirstName', getValues('businessSponsorFirstName').trim())
    setValue('businessSponsorLastName', getValues('businessSponsorLastName').trim())
    setValue('businessSponsorEmail', getValues('businessSponsorEmail').trim())
    setValue('firstName', getValues('firstName').trim())
    setValue('lastName', getValues('lastName').trim())
    setValue('email', getValues('email').trim())
  }

  const handleSubmitForm = async () => {
    await handleTrimValues()
    await onSubmit()
  }

  const handleChangeAccountType = (val: any) => {
    setIsAccountType(val)
    setValue('accountType', val?.name)
    setValue('kind', val?.kind)
    setIsRequiredAccountType(false)
    if (val?.kind === null) {
      setValue('organizationName', 'Propeller')
    } else if (val?.kind === 'agencies') {
      setValue('organizationName', '')
    }
  }

  const handleChangeOrganizationName = (val: any) => {
    setValue('organizationName', val?.name)
    setValue('partyId', val?.id)
    setIsRequiredOrganizationName(false)
  }

  useEffect(() => {
    if (spammingCounting >= 5) {
      setIsInvalid(true)
    }
  }, [spammingCounting])

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={styleRequestAccount}>
      <div className="px-4">
        <div className="flex justify-between">
          <div />
          <img
            src={iconTimes}
            alt=""
            className="-mb-4 -mr-3 cursor-pointer"
            onClick={handleCloseRequestAccountDialog}
          />
        </div>
        <p className="mb-2 font-semibold text-headline3">Request an account</p>
        <hr className="mt-3 border-t-neutral-5" />
        <div className="overflow-y-scroll cus-scrollbar -mr-2 h-[582px]">
          <form onSubmit={handleSubmitForm}>
            <div className="grid grid-cols-2 gap-6 mt-6">
              <div className="">
                <p className="mb-2 font-semibold text-hairline1">Account type</p>
                <DropdownBase
                  options={optionsAccountType}
                  handleOptions={handleChangeAccountType}
                  placeHolder="Choose account type"
                  isError={isRequiredAccountType}
                />
              </div>
            </div>
            {isRequiredAccountType && (
              <p className="block mt-1 text-sm text-left text-red-900">
                {MESSAGES.ERROR.E_REQUIRED_FIELD}
              </p>
            )}
            <hr className="mt-6 mb-6 border-t-neutral-6" />
            <p className="mb-5 text-headline5A">Business management contact</p>
            <div className="grid grid-cols-2 gap-6 mb-6">
              {isAccountType?.id === '' ? (
                <div>
                  <p className="mb-2 font-semibold text-hairline1">Organization name</p>
                  <FormInput<RegistrationFormFields>
                    id="organizationName"
                    type="text"
                    name="organizationName"
                    label="organizationName"
                    placeholder="Please choose Account Type first"
                    inputClassName="w-full mt-1"
                    disable
                    register={register}
                    errors={errors}
                  />
                </div>
              ) : (
                <>
                  {isAccountType?.name === 'Agency Mgt Provider developer' ? (
                    <div>
                      <p className="mb-2 font-semibold text-hairline1">Organization name</p>
                      <FormInput<RegistrationFormFields>
                        id="organizationName"
                        type="text"
                        name="organizationName"
                        label="organizationName"
                        placeholder="Enter your organization name"
                        className=""
                        inputClassName="w-full mt-1"
                        register={register}
                        rules={{
                          required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                          pattern: alphabetPattern,
                          maxLength: 200,
                        }}
                        errors={errors}
                      />
                    </div>
                  ) : (
                    <div>
                      {isAccountType?.name === 'Propeller' ? (
                        <>
                          <p className="mb-2 font-semibold text-hairline1">Organization name</p>
                          <FormInput<RegistrationFormFields>
                            id="organizationName"
                            type="text"
                            name="organizationName"
                            label="organizationName"
                            value={'Propeller'}
                            className=""
                            inputClassName="w-full mt-1"
                            register={register}
                            rules={{
                              required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                              pattern: alphabetPattern,
                              maxLength: 200,
                            }}
                            errors={(watch('organizationName') as any) || errors}
                          />
                        </>
                      ) : (
                        <>
                          <div>
                            <p className="mb-2 font-semibold text-hairline1">Organization name</p>
                            <DropdownBase
                              options={carrierList?.getPartyName}
                              handleOptions={handleChangeOrganizationName}
                              placeHolder="Choose your organization name"
                              isError={isRequiredOrganizationName}
                            />
                          </div>
                          {isRequiredOrganizationName && (
                            <p className="block mt-1 text-sm text-left text-red-900">
                              {MESSAGES.ERROR.E_REQUIRED_FIELD}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="grid grid-cols-2 gap-6">
              <div className="">
                <p className="mb-2 font-semibold text-hairline1">First name</p>
                <FormInput<RegistrationFormFields>
                  id="businessSponsorFirstName"
                  type="text"
                  name="businessSponsorFirstName"
                  label="First Name"
                  placeholder="Enter your first name"
                  className=""
                  inputClassName="w-full mt-1"
                  register={register}
                  rules={{
                    required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                    pattern: alphabetPattern,
                    maxLength: 200,
                  }}
                  errors={errors}
                />
              </div>
              <div className="">
                <p className="mb-2 font-semibold text-hairline1">Last name</p>
                <FormInput<RegistrationFormFields>
                  id="businessSponsorLastName"
                  type="text"
                  name="businessSponsorLastName"
                  label="Last Name"
                  placeholder="Enter your last name"
                  className=""
                  inputClassName="w-full mt-1"
                  register={register}
                  rules={{
                    required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                    pattern: alphabetPattern,
                    maxLength: 200,
                  }}
                  errors={errors}
                />
              </div>
              <div className="">
                <p className="mb-2 font-semibold text-hairline1">Business email</p>
                <FormInput<RegistrationFormFields>
                  id="businessSponsorEmail"
                  type="email"
                  name="businessSponsorEmail"
                  label="Email Address"
                  placeholder="Enter email with your company domain"
                  className=""
                  inputClassName="w-full mt-1"
                  register={register}
                  rules={{
                    required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                    pattern: emailPattern,
                    maxLength: 200,
                  }}
                  errors={errors}
                />
              </div>
              <div className="">
                <span className="mb-2 font-semibold text-hairline1">Phone number</span>
                <span className="text-body3"> (Optional)</span>
                <FormInputPhoneNumber<RegistrationFormFields>
                  control={control}
                  id="businessSponsorPhoneNumber"
                  type="text"
                  name="businessSponsorPhoneNumber"
                  label="Phone number"
                  placeholder="Enter your phone number "
                  className=""
                  inputClassName="w-full mt-1"
                  register={register}
                  rules={{ required: false, pattern: americanPhoneNumberPattern }}
                  errors={errors}
                  value={getValues().businessSponsorPhoneNumber}
                />
              </div>
            </div>
            <hr className="mt-6 mb-6 border-t-neutral-6" />
            <p className="mb-5 text-headline5A">Developer requester’s information</p>
            <div className="grid grid-cols-2 gap-6 mb-6">
              <div className="">
                <p className="mb-2 font-semibold text-hairline1">First name</p>
                <FormInput<RegistrationFormFields>
                  id="firstName"
                  type="text"
                  name="firstName"
                  label="First Name"
                  placeholder="Enter your first name"
                  className=""
                  inputClassName="w-full mt-1"
                  register={register}
                  rules={{
                    required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                    pattern: alphabetPattern,
                    maxLength: 200,
                  }}
                  errors={errors}
                />
              </div>
              <div className="">
                <p className="mb-2 font-semibold text-hairline1">Last name</p>
                <FormInput<RegistrationFormFields>
                  id="lastName"
                  type="text"
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter your last name"
                  className=""
                  inputClassName="w-full mt-1"
                  register={register}
                  rules={{
                    required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                    pattern: alphabetPattern,
                    maxLength: 200,
                  }}
                  errors={errors}
                />
              </div>
              <div className="">
                <p className="mb-2 font-semibold text-hairline1">Business email</p>
                <FormInput<RegistrationFormFields>
                  id="email"
                  type="email"
                  name="email"
                  label="Email Address"
                  placeholder="Enter email with your company domain"
                  className=""
                  inputClassName="w-full mt-1"
                  register={register}
                  rules={{
                    required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                    pattern: emailPattern,
                    maxLength: 200,
                  }}
                  errors={errors}
                />
              </div>
              <div className="">
                <p className="mb-2 font-semibold text-hairline1">Phone number</p>
                <FormInputPhoneNumber<RegistrationFormFields>
                  control={control}
                  id="phoneNumber"
                  type="text"
                  name="phoneNumber"
                  label="Phone number"
                  placeholder="Enter your phone number "
                  className=""
                  inputClassName="w-full mt-1"
                  register={register}
                  rules={{
                    required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                    pattern: americanPhoneNumberPattern,
                  }}
                  errors={errors}
                  value={watch('phoneNumber')}
                />
              </div>
            </div>
          </form>
          {spammingCounting >= 5 && (
            <ReCAPTCHA
              ref={(r) => setCaptchaRef(r)}
              sitekey={`${googleSiteKey}`}
              onChange={handleCheckReCaptcha}
            />
          )}
          <div className="pb-6" />
        </div>
        <hr className="mb-2 border-t-neutral-6" />
        <div className="flex">
          <div className="w-2/4 pr-3 mt-4 mb-3">
            <ButtonBase
              type="submit"
              className="w-full text-center bg-primary-1 min-w-min"
              onClick={() => handleSubmitForm()}
              disabled={isInvalid}
              bgDisabled="bg-primary-shade3"
            >
              Submit
            </ButtonBase>
          </div>
          <div className="w-2/4 pr-3 mt-4 mb-3">
            <ButtonBase
              type="submit"
              className="w-full ml-3 border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4"
              onClick={handleCloseRequestAccountDialog}
              bgDisabled="bg-primary-shade3"
            >
              Cancel
            </ButtonBase>
          </div>
        </div>
      </div>
    </BaseDialog>
  )
}

export default RequestAnAccount
