import React from 'react'
import Iconphone from '../../assets/images/phone.svg'
import IconFB from '../../assets/images/fb.svg'
import IconGmail from '../../assets/images/gmail.svg'
import IconLinkedin from '../../assets/images/linkedin.svg'
import IconTwitter from '../../assets/images/twitter.svg'
import IconWeb from '../../assets/images/web.svg'
import IconPropellerWeb from '../../assets/images/propeller_web.svg'
import IconTerm from '../../assets/images/term.svg'
import LogoPropeller from '../../assets/images/logo-propeller-full.svg'

const Footer = () => {
  return (
    <div className="px-20 mw926:px-10 bg-neutral-7-header-footer border-t border-t-neutral-6">
      <div className="flex items-start pt-5 justify-between">
        <img src={LogoPropeller} alt="Logo Propeller" className="w-[220px] h-[80px]" />
        <div className="flex flex-col items-end pt-5 text-body1 text-neutral-2 lg:w-[496px]">
          <div className="flex flex-wrap md:flex-col md:items-end justify-end items-baseline pb-3 mb-3 border-b-2 border-b-neutral-6">
            <a href="tel:815-595-2088" className="flex items-center">
              <img src={Iconphone} alt="Icon Phone" className="inline mr-2" /> 815 595 2088
            </a>
            <a
              href="https://www.propellerbonds.com"
              target="_blank"
              rel="noreferrer"
              className="ml-10 flex items-center mw1314:py-4"
            >
              <img src={IconPropellerWeb} alt="Icon Web" className="inline mr-2" /> Propellerbonds
              website
            </a>
            <a
              href="https://propellerapi.uat.getpropellerbonds.com/"
              target="_blank"
              rel="noreferrer"
              className="ml-10 lg:ml-0 md:pb-4 flex items-center"
            >
              <img src={IconWeb} alt="Icon Web" className="inline mr-2" /> Production website
            </a>
            <p className="ml-10 md:ml-0 flex items-center">
              <img src={IconTerm} alt="Icon Term" className="inline mr-2" />
              <a href="https://www.propellerbonds.com/privacy-policy/">Privacy</a>
              <span className="h-4 w-[1px] mx-2 bg-neutral-6"></span>
              <a href="https://www.propellerbonds.com/terms-conditions/">Terms &#38; Conditions</a>
            </p>
          </div>
          <div className="flex">
            <a href="mailto:apis@propellerbonds.com" className="ml-6">
              <img src={IconGmail} alt="Icon Gmail" height={'24px'} />
            </a>
            <a
              href="https://www.linkedin.com/company/propellerbonds/"
              target="_blank"
              className="ml-6"
              rel="noreferrer"
            >
              <img src={IconLinkedin} alt="Icon Linkedin" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100076601568684"
              target="_blank"
              className="ml-6"
              rel="noreferrer"
            >
              <img src={IconFB} alt="Icon Facebook" />
            </a>
            <a
              href="https://twitter.com/propeller_bonds"
              target="_blank"
              className="ml-6"
              rel="noreferrer"
            >
              <img src={IconTwitter} alt="Icon Twitter" />
            </a>
          </div>
          <p className="text-right text-body3 text-neutral-4 font-medium pb-2 pt-10">
            © 2022 | <span className="text-primary-shade2">Propeller</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
