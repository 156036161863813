import classNames from 'classnames'
import React from 'react'

interface Props {
  floatRight?: boolean
}
const ShadowTab: React.FC<Props> = ({ floatRight = '' }) => {
  const shadowClass = classNames('w-[48px] h-[72px] mb-[-72px] opacity-90 from-neutral-8', {
    'bg-gradient-to-r': !floatRight,
    'bg-gradient-to-l': floatRight,
    'float-right': floatRight,
  })
  return <div className={shadowClass} />
}

export default ShadowTab
