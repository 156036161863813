import React, { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../components/layouts/footer'
import Header from '../components/layouts/header'
import UpToTop from '../components/partials/upToTop'
import { pathnameStaticPage } from '../constants/pathname'

const Page = () => {
  const { pathname } = useLocation()
  const [isShowUpToTop, setIsShowUpToTop] = useState<boolean>(false)
  const [isStaticPage, setIsStaticPage] = useState<boolean>(false)

  window.addEventListener('scroll', () => {
    if (window.scrollY > 250) {
      setIsShowUpToTop(true)
    } else {
      setIsShowUpToTop(false)
    }
  })

  const handleScroll = () => {
    onHandleScroll()
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)

    // Remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [])
  const scrollRef = useRef<HTMLDivElement>(null)

  const onHandleScroll = () => {
    const scrollTop = scrollRef?.current?.scrollTop
    if ((scrollTop as number) > 250) {
      setIsShowUpToTop(true)
    } else {
      setIsShowUpToTop(false)
    }
  }

  const handleScrollToTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0
    }
  }

  useEffect(() => {
    document.body.scrollTo(0, 0)
    window.scrollTo(0, 0)
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0
    }
    setIsStaticPage(pathnameStaticPage.includes(pathname))
  }, [pathname])

  return (
    <div
      className="bg-neutral-8 flex flex-col h-[100vh] max-w-[1440px] m-auto overflow-auto"
      ref={scrollRef}
      onScroll={onHandleScroll}
    >
      <Header />
      <div className="flex-1 pt-[72px] bg-neutral-8">
        <Outlet />
      </div>
      {isShowUpToTop && isStaticPage && <UpToTop handleScrollToTop={handleScrollToTop} />}
      <Footer />
    </div>
  )
}
export default Page
