import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import ButtonBase from '../../../components/partials/button'
import { MESSAGES } from '../../../constants/messages'
import { useSession } from '../../../helpers/checkSession'
import { SEARCH_PRINCIPAL_ACCOUNT } from '../../../queries/adminPortal'
import {
  searchPrincipal,
  updateTakeItemSearchPrincipalAccount,
} from '../../../redux/actions/adminPortal'
import { RootState } from '../../../store'
import PrincipalAccountTable from '../tables/principalAccount'
import IconPAccFound from '../../../assets/images/icon-pacc-found.svg'
import IconLoadMore from '../../../assets/images/icon-load-more.svg'

interface Props {
  handleOpenAndClosePrincipalDetail?: (val: boolean) => void
  handleSetPrincipalAccountDetail?: (data: object) => void
}

const PrincipalAccount: React.FC<Props> = ({
  handleOpenAndClosePrincipalDetail = () => null,
  handleSetPrincipalAccountDetail = () => null,
}) => {
  const { handleErrorSession } = useSession()
  const [take, setTake] = useState(4)
  const dispatch = useDispatch()

  const searchPrincipalState = useSelector(
    (state: RootState) => state?.adminPortalReducer?.searchPrincipal,
  )

  const searchPrincipalNameState = useSelector(
    (state: RootState) => state?.adminPortalReducer?.valuePrincipalName,
  )

  const searchPrincipalAddressState = useSelector(
    (state: RootState) => state?.adminPortalReducer?.valuePrincipalAddress,
  )

  const [searchPrincipalAccount] = useMutation(SEARCH_PRINCIPAL_ACCOUNT)

  const handleSearchPAcc = async (skipItem: number) => {
    let res = null
    const variables = {
      data: {
        // change principal account name when click search
        principalAccountName: searchPrincipalNameState,
        principalAccountAddressLine1: searchPrincipalAddressState,
        skip: searchPrincipalState.data.length,
        take: 4,
      },
    }
    try {
      const resPrincipal = await searchPrincipalAccount({
        variables: variables,
      })
      res = await resPrincipal.data.searchPrincipalAccountByNameAndAddress
      if (searchPrincipalState.data) {
        res = {
          count: searchPrincipalState.count,
          data: [...searchPrincipalState.data, ...res.data],
        }
      }
      dispatch(searchPrincipal(res))
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_TRY_AGAIN)
    }
    await setTake(take + skipItem)
    await dispatch(updateTakeItemSearchPrincipalAccount(take + skipItem))
  }

  return (
    <div>
      <div>
        {searchPrincipalState?.data && (
          <div className="flex justify-start mt-10">
            <img src={IconPAccFound} alt="IconPAccFound" />
            <p className="text-hairline1 font-semibold mt-1 ml-3">
              {searchPrincipalState?.count} Principal{' '}
              {searchPrincipalState?.count > 1 ? 'Accounts' : 'Account'} found
            </p>
          </div>
        )}
        {searchPrincipalState?.data?.map((item: any, idx: number) => (
          <div key={idx}>
            <PrincipalAccountTable
              data={item?.principals}
              dataDetail={searchPrincipalState?.data[idx]}
              isLastItem={searchPrincipalState?.data?.length === idx + 1}
              handleOpenAndClosePrincipalDetail={handleOpenAndClosePrincipalDetail}
              handleSetPrincipalAccountDetail={handleSetPrincipalAccountDetail}
            />
          </div>
        ))}
      </div>
      <div>
        {/* Button Load more just appears when current list data is less than total principal accounts */}
        {searchPrincipalState?.count > searchPrincipalState?.data?.length && (
          <div className="text-center">
            <ButtonBase
              type="submit"
              className="w-[138px] px-3 h-10 rounded-[20px] border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4"
              onClick={() => handleSearchPAcc(4)}
              bgDisabled="bg-primary-shade3"
            >
              <div className="flex">
                <img src={IconLoadMore} alt="IconLoadMore" />
                <p className="text-hairline2 ml-2 mt-[3px]">Load more</p>
              </div>
            </ButtonBase>
          </div>
        )}
      </div>
    </div>
  )
}

export default PrincipalAccount
