export const tableHeader = [
  {
    minW: 'min-w-[28px]',
    label: 'No.',
  },
  {
    minW: 'w-[160px] min-w-[100px]',
    label: 'Principal name',
  },
  {
    minW: 'w-[256px] min-w-[160px]',
    label: 'Principal key',
  },
  {
    minW: 'w-[156px] min-w-[100px]',
    label: 'Agency name',
  },
  {
    minW: 'w-[320px] min-w-[140px]',
    label: 'Address line 1',
  },
  {
    minW: 'w-[130px] min-w-[60px]',
    label: 'Records count',
  },
]

export const fakeData = {
  count: 32,
  data: [
    {
      countPrincipal: 3,
      principalAccountPhoneNumber: '012-458-1234',
      principalAccountContactName: 'Copart Corp 01',
      principalAccountBusinessEmail: 'datist@cmcglobal.vn',
      principalAccountName: '',
      principalAccountId: '',
      principals: [
        {
          principalName: 'Copart Angel 1',
          principalKey:
            'MFwwDQYJKoZIhvcNAQEBBQADMFwwDQYJvcNAQEBBQADMFwwDQYJKoQADMFwwDQYKoZIhvcNAQEBBBQAD',
          agencyName: 'Kelli Altenwerth 1',
          principalAddressLine1: '0 / 77 Purdy Creeee, West Arthur',
          recordsCount: 123,
          principalAccountId: 'abc123',
        },
        {
          principalName: 'Copart Angel 2',
          principalKey:
            'MFwwDQYJKoZIhvcNAQEBvcNAQEBBQADMFwwDQYJKoQADMFwwDQYBQQEBBADBBQADMFwwDQYJKoAQEBBQAD',
          agencyName: 'Kelli Altenwerth 2',
          principalAddressLine1: '2715 Ash Dr. San Jiii, South Dakota',
          recordsCount: 232,
          principalAccountId: 'abc321',
        },
        {
          principalName: 'Copart Angel 3',
          principalKey:
            'MFwwDQYJKoQADMFwwDQvcNAQEBBQADMFwwDQYJKoQADMFwwDQYYJKoZIhvcNABQADQEBIhvcNAQEBBQAD',
          agencyName: 'Kelli Altenwerth 3',
          principalAddressLine1: '3 / 621 Juvenal Ruiii, Port Vestachester',
          recordsCount: 333,
          principalAccountId: 'abc214',
        },
      ],
    },
    {
      countPrincipal: 10,
      principalAccountPhoneNumber: '',
      principalAccountContactName: 'Copart Corp 03',
      principalAccountBusinessEmail: '',
      principalAccountName: '',
      principalAccountId: '',
      principals: [
        {
          principalName: 'Copart Angel 7',
          principalKey:
            'MFwwDQYJKoZIhvcNAQEBBQADMFwwDQYJvcNAQEBBBQADMFwwDQYJasasasasMFwwDQYKoZIhvcNAQEBBBQAD',
          agencyName: 'Datist ne',
          principalAddressLine1: '0 / 77 Purdy Kii, West Arthur',
          recordsCount: 211,
          principalAccountId: '2122ad',
        },
        {
          principalName: 'Copart Angel 8',
          principalKey:
            'MFwwDQYJKoZIhvcNAQEBvcNAQEBBQADMFwwDQYJKoQADMFwwDQYBQQEBBADBBQADMFwwDQYJKoAQEBBQAD',
          agencyName: 'Datist neeee',
          principalAddressLine1: '2715 Ash Dr. Hu, South Dakota',
          recordsCount: 432,
          principalAccountId: 'aadad',
        },
        {
          principalName: 'Copart Angel 9',
          principalKey:
            'MFwwDQYJKoQADMFwwDQvcNAQEBBQADMFwwDQYJKoQADMFwBQADMFwwDQYJasasaDQEBIhvcNAQEBBQAD',
          agencyName: 'Datist neeeeee',
          principalAddressLine1: '3 / 621 Juvenal Reee, Port Vestachester',
          recordsCount: 143,
          principalAccountId: 'dad1212',
        },
        {
          principalName: 'Copart Angel 10',
          principalKey:
            'MFwwDQYJKoZIhvcNAQEBBQADMFwwDQYJvcNAQEBBQADMFwwDQYJKoQJasasasasMFwwDQasasYKoZIhvcNAQEBBBQAD',
          agencyName: 'Datist day',
          principalAddressLine1: '0 / 77 Purdy Kii, West Arthur',
          recordsCount: 211,
          principalAccountId: 'gdfg456',
        },
        {
          principalName: 'Copart Angel 11',
          principalKey:
            'MFwwDQYJKoZIhvcNAQEBvcNAQEBBQADMFadadadadwwDQYJKoQADMFwwDQYBQQEBBADBBQADMFwwDQYJKoAQEBBQAD',
          agencyName: 'Datist dayyyyy',
          principalAddressLine1: '2715 Ash Dr. Hu, South Dakota',
          recordsCount: 432,
          principalAccountId: 'asas5454',
        },
      ],
    },
  ],
}
