import React, { useState } from 'react'
import devDocs from '../../assets/images/dev-docs.svg'
import sandBox1 from '../../assets/images/sandbox1.svg'
import sandBox2 from '../../assets/images/sandbox2.svg'
import sandBox3 from '../../assets/images/sandbox3.svg'
import sandBox4 from '../../assets/images/sandbox4.svg'
import sandBox6 from '../../assets/images/sandbox6.svg'
import sandBox7 from '../../assets/images/sandbox7.svg'
import sandBox8 from '../../assets/images/sandbox8.svg'
import sandBox10 from '../../assets/images/sandbox10.svg'
import sandBox11 from '../../assets/images/sandbox11.svg'
import sandBox13 from '../../assets/images/sandbox13.svg'
import sandBox14 from '../../assets/images/sandbox14.svg'
import sandBox15 from '../../assets/images/sandbox15.svg'
import sandBox16 from '../../assets/images/sandbox16.svg'
import sandBox17 from '../../assets/images/sandbox17.svg'
import sandBox18 from '../../assets/images/sandbox18.svg'
import sandBox19 from '../../assets/images/sandbox19.svg'
import sandBox20 from '../../assets/images/sandbox20.svg'
import sandBox21 from '../../assets/images/sandbox21.svg'
import sandBox22 from '../../assets/images/sandbox22.svg'
import sandBox23 from '../../assets/images/sandbox23.svg'
import sandBox24 from '../../assets/images/sandbox24.svg'
import sandBox25 from '../../assets/images/sandbox25.svg'
import sandBox26 from '../../assets/images/sandbox26.svg'
import sandBox27 from '../../assets/images/sandbox27.svg'
import apiSandbox from '../../assets/images/api-sandbox.svg'
import devPortal from '../../assets/images/dev-portal.svg'
import generateKey from '../../assets/images/generate-key.svg'
import copyKey from '../../assets/images/copy-key.svg'
import request from '../../assets/images/request.svg'
import table1 from '../../assets/images/table1.svg'
import table2 from '../../assets/images/table2.svg'
import table3 from '../../assets/images/table3.svg'
import table4 from '../../assets/images/table4.svg'
import CollapseItem from '../../components/partials/collapseItem'
import UpToTop from '../../components/partials/upToTop'

const ApiDocs = () => {
  const [listCollapsesOpen, setListCollapsesOpen] = useState<number[]>([])
  const [isUpToTop, setIsUpToTop] = useState<boolean>(false)

  const handleOpenCollapse = (value: number) => {
    if (listCollapsesOpen.includes(value)) {
      setListCollapsesOpen(listCollapsesOpen.filter((val) => val != value))
    } else {
      setListCollapsesOpen(listCollapsesOpen.concat(value))
    }
  }

  window.addEventListener('scroll', () => {
    if (window.scrollY > 250) {
      setIsUpToTop(true)
    } else {
      setIsUpToTop(false)
    }
  })

  return (
    <div className="px-20 mt-8 md:px-10">
      <CollapseItem
        title="Introduction"
        id={1}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapsesOpen}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med py-4">
          <p className="mb-4 mt-2">
            To help you go through the development process smoothly and effectively, below are the
            documents to guide you step-by-step. While the APIs are easy, the more significant
            challenge will be integrating the Propeller transaction model into your system.
          </p>
          <p className="mb-1">
            Our suggested approach is to first review and understand our transaction model and then
            consider how you what to handle this in the integration with your system. Then review
            the data that is available at the moment. If you have any questions, please contact us
            via the email below.
          </p>
        </div>
      </CollapseItem>
      <CollapseItem
        title="Propeller Transaction Model"
        id={2}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapsesOpen}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med py-4">
          <p className="mb-4 mt-2">
            Propeller serves many carriers, and Transaction status is enumerated by the following
            transactions:
          </p>
          <div className="ml-3 pb-4">
            <div className="flex">
              <div className="w-[3px] h-[3px] border border-black-800 bg-black-800 rounded-full mt-[13px] mr-4" />
              <span>Edit - Increase</span>
            </div>
            <div className="flex">
              <div className="w-[3px] h-[3px] border border-black-800 bg-black-800 rounded-full mt-[13px] mr-4" />
              <span>Edit - Decrease</span>
            </div>
            <div className="flex">
              <div className="w-[3px] h-[3px] border border-black-800 bg-black-800 rounded-full mt-[13px] mr-4" />
              <span>Edit - Extend</span>
            </div>
            <div className="flex">
              <div className="w-[3px] h-[3px] border border-black-800 bg-black-800 rounded-full mt-[13px] mr-4" />
              <span>Edit - Non-Financial</span>
            </div>
            <div className="flex">
              <div className="w-[3px] h-[3px] border border-black-800 bg-black-800 rounded-full mt-[13px] mr-4" />
              <span>Credit Adjust</span>
            </div>
            <div className="flex">
              <div className="w-[3px] h-[3px] border border-black-800 bg-black-800 rounded-full mt-[13px] mr-4" />
              <span>Cancel</span>
            </div>
            <div className="flex">
              <div className="w-[3px] h-[3px] border border-black-800 bg-black-800 rounded-full mt-[13px] mr-4" />
              <span>Reinstate</span>
            </div>
          </div>
          <p className="mb-3 text-body1-med font-bold">
            Propeller “Transactions Types” and rules and examples.
          </p>
          <p className="mb-4 mt-2">
            Propeller transactions are all cumulative; the most recent record presented is the
            changed record and represents the current state of the bond, including any changes with
            the exception of financial flows, which are the:
          </p>
          <div className="ml-3 pb-4">
            <div className="flex">
              <div className="w-[3px] h-[3px] border border-black-800 bg-black-800 rounded-full mt-[13px] mr-4" />
              <span>transactionPremium</span>
            </div>
            <div className="flex">
              <div className="w-[3px] h-[3px] border border-black-800 bg-black-800 rounded-full mt-[13px] mr-4" />
              <span>carrierTransactionCommissionAmount,</span>
            </div>
            <div className="flex">
              <div className="w-[3px] h-[3px] border border-black-800 bg-black-800 rounded-full mt-[13px] mr-4" />
              <span>and the bondAmountChangesPositiveAndNegative.</span>
            </div>
          </div>
          <p className="mb-4 mt-2">
            These financial changes are additive to the record and describe the financial impact of
            the change. There is a free text field,{' '}
            <span className="text-body1-med font-bold">“transactionComment”</span> which is used
            where the fields don’t fully describe the change or approval that has been obtained or
            any other supporting information required on any transaction.
          </p>
          <div className="ml-6 italic">
            <p>
              Integration Suggestion - Depending on the ability of your system to recognise changes
              and apply this to your records, we would recommend that apart from{' '}
              <span className="text-body1-med font-bold">
                “Edit - Non Financial” you route any records where
              </span>{' '}
              “transactionComment” are present for manual review as our underwriters have
              specifically left a message about this bond record transaction.
            </p>
          </div>
          <p className="mb-4 mt-2">
            The principle is that you can take “New Business” as the first record and add all the
            financial flow records, and derive the final amounts of the bondAmount by adding the sum
            of all “bondAmountChangesPositiveAndNegative” changes.
          </p>
          <p className="mb-4 mt-2">
            The total transaction premium by adding the sum of transactionPremium records and the
            same with the carrierTransactionCommissionAmount.
          </p>
          <p className="mb-3 text-body1-med font-bold">New Business Example</p>
          <p className="mb-4 mt-2">
            <span className="text-body1-med font-bold">Example: </span>
            If previously purchased, a bond where the transactionPremium was originally $100 and the
            bondAmount increased by $2,000 from $10,000 to $12,000.
          </p>
          <p className="mb-4 mt-2">
            This changed the total transaction Premium from $100 to $110 increases, then the Bond
            Amount would be reflected in an Edit-Increase transaction record as $11,000, the
            “bondAmountChangesPositiveAndNegative” would contain the positive $1000 the but
            transaction Premium change would be +$10 commission changes would reflect the commission
            percentage an of the $10 change. (If the bond had a minimum transaction premium, then
            this base would block the transaction premium falling below this in an Edit -Decrease
            transaction.)
          </p>
          <img src={table1} alt="" className="rounded-t-[16px]" />
          <p className="mb-4 mt-4">
            Similarly, if the change was to decrease the bondAmount by $2000 then the figures would
            be reflected as negative in the following fields
          </p>
          <img src={table2} alt="" className="rounded-t-[16px]" />
          <p className="mb-4 mt-4">
            When a bond is first created it is described by the transaction “New Business” it is
            assumed that preceding the transaction all adjustments for credit and pricing have
            occurred.
          </p>
          <p className="mb-4 mt-2">
            “New Business” occurs only once; after the bond is created, then everything else uses
            one of the enumerated transaction types to describe its status and can do so multiple
            times apart from “Cancel”, which can only happen once unless the bond is reinstated via
            the “Reinstate” transaction type.
          </p>
          <p className="mb-3 text-body1-med font-bold">Renewal Example</p>
          <p className="mb-4 mt-2">
            “Renewal”’s are similar to{' '}
            <span className="text-body1-med font-bold">“New Business”</span> and will contain the
            full amount of the transaction premium and commissions and change “effectiveDate” and
            “expiryDate” In most cases where the “Renewal” doesn’t contain changes, it should look
            like the original “New Business” transaction from a financial flows perspective.
          </p>
          <img src={table3} alt="" className="rounded-t-[16px]" />
          <p className="mb-3 text-body1-med font-bold mt-4">Specific Transaction Type rules.</p>
          <p className="mb-4 mt-2">
            1{' '}
            <span className="text-body1-med font-bold ml-3">
              The “Transaction Type” is impacted by the type of bond
            </span>
          </p>
          <p className="mb-4 mt-2">
            2 <span className="text-body1-med font-bold ml-3">“Cancel” </span>Transactions - Bonds
            will either be cancelled, closed or expire “Term” bonds like Notary do not receive a
            “cancel transaction” as they just expire on our system and no cancellation notice is
            sent to carriers “Term” is a specific renewal type that does not renew or notify.
          </p>
          <p className="mb-4 mt-2">
            3 <span className="text-body1-med font-bold ml-3">Financial Changes.</span>
          </p>
          <div className="ml-6">
            <p className="mb-4 mt-2">
              “Edit -Increase”, “Edit - Decrease”, and “Credit Adjust” are transactions where there
              is a financial impact on the bonds.
            </p>
            <p className="mb-4 mt-2">
              If the amount of the bond increases or decreases, the increase or decrease amount is
              reflected in the “bondAmountChangesPositiveAndNegative” field. The bondAmount field
              will reflect the current state of the record i.e. the previous bondAmount + the change
              as described above.
            </p>
            <p className="mb-4 mt-2">
              A “Credit Adjust” transaction only changes the fields that are impacted if there is no
              change in the bondAmount then the bond amount changes are null or zero?, and only the
              carrier transactionPremium, carrierTransactionCommissionAmount are changed.
            </p>
            <p className="mb-4 mt-2">
              Also, “Credit Adjust” a credit adjustment could also include a “Edit -Increase” or
              “Edit - Decrease” as a final adjustment. In this case, there would be the same
              adjustments as to bondAmount, transactionPremuim,
              “bondAmountChangesPositiveAndNegative“,carrierTransactionCommissionAmout but the
              transaction would be labelled as “Credit Adjust”.
            </p>
          </div>
          <p className="mb-4 mt-2">
            4 <span className="text-body1-med font-bold ml-3">“Renewal” </span>must have the same
            bond number and the change “effectiveDate” and “expiryDate” and can contain other
            changes like increases and decreases, address changes and follow the same rules where
            the record sent is the final record; anything that isn’t explained as a financial flow
            record increase or decrease will be supported by a “transactionComment” to describe the
            additional changes.
          </p>
          <p className="mb-4 mt-2">
            5 <span className="text-body1-med font-bold ml-3">“Edit - Non Financial” </span>is used
            for transactions where the changes don’t cause a financial transaction. An example would
            be correcting a principal name or address- the transaction flagged with this type would
            contain the latest record of the changes and a free text field”Transaction Comment”
            describing the change. There would be null or zero entries in bondAmount,
            carrierTransactionCommissionAmount, bondAmountChangesPositiveAndNegative
            transactionPremium fields.
          </p>
          <p className="mb-4 mt-2">
            6 <span className="text-body1-med font-bold ml-3">“Edit - Extend” </span>is used when a
            date is extended. The record contains the new dates; any financial changes are explained
            by the combination of the carrierTransactionCommissionAmount,
            bondAmountChangesPositiveAndNegative transactionPremium fields. However, there could be
            instances where there are no financial impacts. The dateChangedFlagExtendTransaction
            field is set to yes.
          </p>
          <p className="mb-4 mt-2">
            7 <span className="text-body1-med font-bold ml-3">“Cancel” </span>Cancellation Rule - If
            a bond is cancelled flat, there are no changes to the record apart from the “Cancel”
            transaction type and zero in the carrierTransactionCommissionAmount,
            bondAmountChangesPositiveAndNegative, transactionPremium, however, if a cancellation
            results in a refund then only the carrierTransactionCommissionAmount, and
            transactionPremium are supplied as negatives. The bondAmount remains static at the last
            value.
          </p>
          <p className="mb-4 mt-2">
            8 <span className="text-body1-med font-bold ml-3">“Reinstate” </span>a reinstatement
            message contains the bond number and bondAmount if any financial changes are required,
            it will be reflected in carrierTransactionCommissionAmount,
            bondAmountChangesPositiveAndNegative transactionPremium fields.
          </p>
          <img src={table4} alt="" className="rounded-t-[16px] mb-1" />
        </div>
      </CollapseItem>
      <CollapseItem
        title="What is the API Sandbox"
        id={3}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapsesOpen}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med pb-4">
          <p className="pt-4 mb-6">
            Powered by Apollo GraphQL, Propeller is proud to integrate the GraphQL Sandbox as a tool
            for developers a holistic tool to learn how to use the APIs in the most effective (and
            fun) way!
          </p>
          <img src={devDocs} alt="" className="mb-6" />
          <p className="mb-4">
            The API Sandbox is meant to be used to provide developers with a data schema from
            introspection provides access to a subset of GraphQL devtools:
          </p>
          <div className="ml-3">
            <li className="mb-2">
              <a
                className="text-primary-shade2 underline"
                href="https://www.apollographql.com/docs/studio/explorer/explorer"
                target="_blank"
                rel="noreferrer"
              >
                The Explorer
              </a>
            </li>
            <li className="mb-2">An overview of the Apollo Explorer</li>
            <li className="mb-2">
              <a
                className="text-primary-shade2 underline"
                href="https://www.youtube.com/watch?v=j8b0Bda_TIw&ab_channel=ApolloGraphQL"
                target="_blank"
                rel="noreferrer"
              >
                Schema reference
              </a>
            </li>
            <li className="mb-2">
              <a
                className="text-primary-shade2 underline"
                href="https://www.apollographql.com/docs/studio/schema-checks"
                target="_blank"
                rel="noreferrer"
              >
                Schema checks{''}
              </a>{' '}
              <span>and diffs against registered graphs (for logged-in users)</span>
            </li>
          </div>
        </div>
      </CollapseItem>
      <CollapseItem
        title="How to view the field type and description on each API"
        id={4}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapsesOpen}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med pb-4">
          <div className="py-6">
            <p className="mb-4">
              When using an API with various fields inside it, you may want to inspect the field
              data type and its description. Below is a thorough tutorial to show you the way to do
              it on the API Sandbox.
            </p>
            <div className="ml-3">
              <li className="mb-2">
                <span className="font-bold">Step 1: </span>{' '}
                <span>
                  On the API Sandbox, navigate to the sidebar menu and click on the first button -
                  Schema.
                </span>
              </li>
              <li className="mb-2">
                <span className="font-bold">Step 2: </span>{' '}
                <span>
                  Click to choose the type of GraphQL API you want to view - query or mutation.
                </span>
              </li>
              <li className="mb-2">
                <span className="font-bold">Step 3: </span>{' '}
                <span>Click to choose the exact the API you want to view.</span>
              </li>
            </div>
          </div>
          <img src={sandBox1} alt="" className="mb-6" />
          <li className="mb-2 ml-3">
            <span className="font-bold">Step 4: </span>{' '}
            <span>
              In the API fields’ details screen, you can view each field name, its data type, and
              its description here.
            </span>
          </li>
          <img src={sandBox2} alt="" className="mb-4 mt-4" />
        </div>
      </CollapseItem>
      <CollapseItem
        title="How to set up the security process with the Access Key"
        id={5}
        listCollapse={listCollapsesOpen}
        handleOpenCollapse={handleOpenCollapse}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med pb-4">
          <div className="py-6">
            <p className="mb-4">
              Data security is the most important value we are willing to go the extra mile to
              protect. Hence, all the API calls are required an access key to run. Below is a
              detailed tutorial to help you to set up the key on the Propeller website and use it to
              call the APIs on the API sandbox.
            </p>
            <li className="mb-2 ml-3">
              <span className="font-bold">Step 1: </span>{' '}
              <span>
                Navigate to the Developer Portal tab and choose the Development management tab. On
                the Access Key management section, click the Generate a key button to generate your
                first access key
              </span>
            </li>
          </div>
          <img src={devPortal} alt="" className="mb-6" />
          <li className="mb-5 ml-3">
            <span className="font-bold">Step 2: </span>{' '}
            <span>Fill in key name and choose to generate the key.</span>
          </li>
          <img src={generateKey} alt="" className="mb-6" />
          <li className="mb-5 ml-3">
            <span className="font-bold">Step 3: </span>{' '}
            <span>Hover on the access key, and click on the copy button to copy the key.</span>
          </li>
          <img src={copyKey} alt="" className="mb-6" />
          <li className="mb-5 ml-3">
            <span className="font-bold">Step 4: </span>{' '}
            <span>
              Navigate to the tab <span className="font-bold">Developer docs</span> and choose the
              tab <span className="font-bold">API Sandbox</span>. In this screen, you shall see the
              API Sandbox frame.
            </span>
          </li>
          <img src={apiSandbox} alt="" className="mb-6" />
          <li className="mb-5 ml-3">
            <span className="font-bold">Step 5: </span>{' '}
            <span>
              At the bottom of the frame, you shall see the Variables and Headers input, choose
              Headers.
            </span>
          </li>
          <img src={sandBox3} alt="" className="mb-6" />
          <li className="mb-5 ml-3">
            <span className="font-bold">Step 6: </span> <span>Choose to add a New Header.</span>
          </li>
          <img src={sandBox4} alt="" className="mb-6" />
          <li className="mb-5 ml-3">
            <span className="font-bold">Step 7: </span>{' '}
            <span>
              In the header key input, choose the <span className="font-bold">Authorization</span>{' '}
              from the drop-down list.
            </span>
          </li>
          <img src={sandBox6} alt="" className="mb-6" />
          <li className="mb-5 ml-3">
            <span className="font-bold">Step 8: </span>{' '}
            <span>
              In the key input, write <span className="font-bold">Bearer + Access key</span> (Paste
              the Access key you’ve just copied). By now, you just successfully set up the security
              and authorization process to access your data!
            </span>
          </li>
          <img src={sandBox7} alt="" className="mb-6" />
          <li className="mb-5 ml-3">
            <span className="font-bold">Step 9: </span>{' '}
            <span>
              Start to use the API Sandbox by choosing the API you want to run! You should get the
              response after hitting the operation button here.
            </span>
          </li>
          <img src={sandBox8} alt="" className="mb-3" />
        </div>
      </CollapseItem>
      <CollapseItem
        title="How to get the CURL statement on the API Sandbox"
        id={6}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapsesOpen}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med pb-4">
          <div className="py-6">
            <p className="mb-4">
              Sometimes, you may want to get a CURL statement for an API syntax; you can do it
              easily with the API Sandbox as the following step-by-step instructions.
            </p>
            <li className="mb-2 ml-3">
              <span className="font-bold">Step 1: </span>{' '}
              <span>
                After adding in the query on the API Sandbox, you can copy the curl statement
                clicking on this button.
              </span>
            </li>
          </div>
          <img src={sandBox10} alt="" className="mb-6" />
          <p className="text-body1-med my-5">And choose to copy operation to CURL.</p>
          <img src={sandBox11} alt="" className="mb-6" />
          <li className="mb-5 ml-3">
            <span className="font-bold">Step 2: </span>{' '}
            <span>You shall get the CURL statement as the example below.</span>
          </li>
          <img src={request} alt="" className="mb-6 ml-3" />
        </div>
      </CollapseItem>
      <CollapseItem
        title="How to rename fields in API Sandbox"
        id={7}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapsesOpen}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med pb-4">
          <div className="py-6">
            <p className="mb-4">
              When working with a GraphQL API, you may want to rename a field to something other
              than what the API has to offer. Aliases in GraphQL can help you to solve this exact
              problem. And the best thing about it is that it is super simple to do so.
            </p>
            <p className="mb-4">
              In a query syntax, choose any field name you want to change, and just simply put the
              new field name right before it and separate them by a colon and a space character.
            </p>
            <p className="mb-4">changedFieldName: originalFieldName</p>
            <p className="mb-4">See the example below when the user wants to change id to my_id</p>
            <p className="mb-4">my_id: id</p>
          </div>
          <img src={sandBox13} alt="" className="mb-4" />
        </div>
      </CollapseItem>
      <CollapseItem
        title="How to setup security on the webhook"
        id={8}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapsesOpen}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med pb-4">
          <div className="py-6">
            <p className="mb-3 text-body1-med font-bold text-indicator-red">
              … text section to be added soon.
            </p>
            <p className="mb-3 text-body1-med font-bold">WebHook Recap </p>
            <p className="mb-4">
              Below is the workflow between the webhook message exchange between Propeller system to
              the Carrier site, and the details for the retry scheme of the webhook.
            </p>
            <p>
              In a query syntax, choose any field name you want to change, and just simply put the
              new field name right before it and separate them by a colon and a space character.
            </p>
          </div>
          <img src={sandBox14} alt="" className="mb-4" />
        </div>
      </CollapseItem>
      <CollapseItem
        title="How to call GraphQL API mutation to set the “Last read record confirmed”"
        id={9}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapsesOpen}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med pb-4">
          <p className="pt-6">
            One of the most important tasks you want to perform is to update the last read record to
            inform us about your acknowledgement of the available data. You can do it easily with
            the GraphQL API mutation updateLastRecordForCarrier as in the API Sandbox here.
          </p>
          <p className="mt-3 mb-2">
            Please note that before calling any API, you should already have the Authorization
            Header set up.
          </p>
          <li className="mb-3 mt-4 ml-3">
            <span className="font-bold">Step 1: </span>{' '}
            <span>
              Go to the API Sandbox, choose the documentation and choose the Root types Mutation.
            </span>
          </li>
          <img src={sandBox15} alt="" className="mb-4 mt-4" />
          <li className="my-6 ml-3">
            <span className="font-bold">Step 2: </span>{' '}
            <span>
              Choose the GraphQL mutation{' '}
              <span className="font-bold">updateLastRecordForCarrier</span>.
            </span>
          </li>
          <img src={sandBox16} alt="" className="mb-4" />
          <p className="mt-3 mb-4">
            You shall see the details of the API below. Choose all arguments and fields, which shall
            be shown on the Operation screen.
          </p>
          <img src={sandBox17} alt="" className="mb-4" />
          <div className="ml-3">
            <li className="my-6">
              <span className="font-bold">Step 3: </span>{' '}
              <span>
                Choose to write the <span className="font-bold">lastRecordRead</span> number you
                wish to update on the Variables section.
              </span>
            </li>
            <li className="my-6">
              <span className="font-bold">Step 4: </span>{' '}
              <span>
                Click on the <span className="font-bold">UpdateLastRecordForCarrier</span>button to
                run the API mutation, and get the response on the Response box on the right.
              </span>
            </li>
          </div>
          <img src={sandBox18} alt="" className="mb-1" />
          <p className="pt-6">
            Another way to use the API is to copy and call with the CURL Statement. You can easily
            copy the statement with the 2-step method as in the picture below on the API Sandbox,
            provided that you have finished adding the API syntax.
          </p>
          <img src={sandBox19} alt="" className="mb-4 mt-6" />
          <img src={sandBox20} alt="" className="mb-2 mt-8" />
        </div>
      </CollapseItem>
      <CollapseItem
        title="How to call GraphQL API query to get a single bond data"
        id={10}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapsesOpen}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med pb-4">
          <p className="pt-6">
            Another API that may interest you is the one that assists you in retrieving single bond
            data. You can do it easily with the GraphQL API query bondData as in the API Sandbox.
          </p>
          <p className="mt-3 mb-2">
            Please note that before calling any API, you should already have the Authorization
            Header set up.
          </p>
          <li className="my-5 mt-4 ml-3">
            <span className="font-bold">Step 1: </span>{' '}
            <span>
              Go to the API Sandbox, choose the documentation and choose the Root types query.
            </span>
          </li>
          <img src={sandBox21} alt="" />
          <li className="my-6 ml-3">
            <span className="font-bold">Step 2: </span>{' '}
            <span>
              Inside the API query list, choose the GraphQL query{' '}
              <span className="font-bold">bondData</span>.
            </span>
          </li>
          <img src={sandBox22} alt="" className="mb-1" />
          <p className="py-6">
            Choose the Arguments and the Fields you wish to retrieve the data. You shall see all the
            chosen elements shown on the screen below.
          </p>
          <img src={sandBox23} alt="" />
          <li className="my-6 ml-3">
            <span className="font-bold">Step 3: </span>{' '}
            <span>
              Write the bond number you want to retrieve data in the Variables box as below.
            </span>
          </li>
          <img src={sandBox24} alt="" />
          <li className="my-6 ml-3">
            <span className="font-bold">Step 4: </span>{' '}
            <span>
              Click the BondData button to run the API query, and you shall see the response in the
              Response box on the right.
            </span>
          </li>
          <img src={sandBox25} alt="" />
          <p className="py-6">
            Another way to use the API is to copy and call with the CURL Statement. You can easily
            copy the statement with the 2-step method as in the picture below on the API Sandbox,
            provided that you have finished adding the API syntax.
          </p>
          <img src={sandBox26} alt="" />
          <img src={sandBox27} alt="" className="mt-6" />
        </div>
      </CollapseItem>
      <CollapseItem
        title="How to call GraphQL API query to get the a range of bond data"
        id={11}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapsesOpen}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med pb-4">
          <p className="pt-6">Call with API Sandbox</p>
          <p className="mt-3 mb-2">Call with CURL Statement</p>
        </div>
      </CollapseItem>
      <CollapseItem
        title="Limitations and differences in Production"
        id={12}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapsesOpen}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med pb-4">
          <p className="pt-6">
            Our production site follows best practices, for instance, the is no introspection
            capability on it so you need to build your queries in our sandbox and transfer them
            there.
          </p>
          <p className="text-bold">Polling</p>
          <p className="pt-6">
            Propeller does support polling but it is discouraged and has the following restrictions:
          </p>
          <div className="ml-3">
            <li className="my-6">
              <span>
                Post requests to any query can be called not more often than once every 15 minutes.
                The webhook system will still fire and just timeout.
              </span>
            </li>
            <li className="mt-6">
              <span>
                This limit applies to any query of 1000 records and we implement paging to manage
                this therefore if your application had 5000 records you could not query them all
                within one hour and would have to break this call into 5 calls.
              </span>
            </li>
          </div>
        </div>
      </CollapseItem>
      <div className="mt-10" />
      {isUpToTop && <UpToTop />}
    </div>
  )
}

export default ApiDocs
