import MyAccount from '.'
import { PageRoute } from '../../routes/routerInterface'

const myAccountRoutes: PageRoute[] = [
  {
    path: '/my-account',
    element: MyAccount,
    isProtected: true,
  },
]

export default myAccountRoutes
