export const tableHeader = [
  {
    minW: 'min-w-[28px]',
    label: 'No.',
  },
  {
    minW: 'w-[101px] min-w-[50px]',
    label: 'Request date',
  },
  {
    minW: 'w-[152px] min-w-[92px]',
    label: 'Organization Type',
  },
  {
    minW: 'w-[148px] min-w-[76px]',
    label: 'Role',
  },
  {
    minW: 'w-[156px] min-w-[80px]',
    label: 'Full name',
  },
  {
    minW: 'w-[132px] min-w-[68px]',
    label: 'Phone number',
  },
  {
    minW: 'w-[228px] min-w-[120px]',
    label: 'Business email',
  },
  {
    minW: 'w-[172px] min-w-[92px]',
    label: 'Organization name',
  },
  {
    minW: 'min-w-[24px]',
    label: '',
  },
]
