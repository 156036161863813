import React, { FC, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import BaseDialog from '../../../components/dialog'
import ButtonBase from '../../../components/partials/button'
import { notify } from '../../../components/toastify'
import { MESSAGES, STATUS } from '../../../constants/messages'
import iconTimes from '../../../assets/images/icon-times.svg'
import InputBase from '../../../components/partials/inputBase'
import { SET_RETRY_SETTING } from '../../../queries/adminPortal'
import { HandleChangeString } from '../../../helpers/functionUtils'
import { useSession } from '../../../helpers/checkSession'
import { useWindowSize } from '../../../helpers/useWindowSize'
import { screens } from '../../../constants/screens'

type Props = {
  handleCloseDialog?: (val: boolean) => void
  modalIsOpen?: boolean
  dataSetting?: any
  setIsRefetch?: (refetch: boolean) => void
}

const EditWebhookSetting: FC<Props> = ({
  handleCloseDialog = () => null,
  setIsRefetch = () => false,
  modalIsOpen,
  dataSetting,
}) => {
  const [sumValue, setSumValue] = useState<number>(0)

  const windowSize = useWindowSize()
  const [setRetrySetting] = useMutation(SET_RETRY_SETTING)
  const { handleErrorSession } = useSession()

  const customStyles = {
    content: {
      minWidth: Number(windowSize.width) >= screens.lg ? '784px' : '688px',
      padding: '24px',
      top: '50%',
      left: '50%',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      borderRadius: '16px',
      boxShadow: '8px 11px 21px 0px rgba(148,144,144,0.75)',
      background: '#fdfdfd',
    },
  }

  const [webhookSettingForm, setWebhookSettingForm] = useState<any>({
    timeOut: dataSetting?.getRetrySetting?.timeOut,
    value1: dataSetting?.getRetrySetting?.value1,
    value2: dataSetting?.getRetrySetting?.value2,
    value3: dataSetting?.getRetrySetting?.value3,
    try1: dataSetting?.getRetrySetting?.try1,
    try2: dataSetting?.getRetrySetting?.try2,
    try3: dataSetting?.getRetrySetting?.try3,
  })
  const webhookSettingInitial = {
    timeOut: dataSetting?.getRetrySetting?.timeOut,
    value1: dataSetting?.getRetrySetting?.value1,
    value2: dataSetting?.getRetrySetting?.value2,
    value3: dataSetting?.getRetrySetting?.value3,
    try1: dataSetting?.getRetrySetting?.try1,
    try2: dataSetting?.getRetrySetting?.try2,
    try3: dataSetting?.getRetrySetting?.try3,
  }

  const handleChange = (field: string, val: string) => {
    setWebhookSettingForm({ ...webhookSettingForm, [field]: val })
  }

  const handleCancelEdit = () => {
    handleCloseDialog(false)
    setWebhookSettingForm(webhookSettingInitial)
  }

  const onSubmit = async () => {
    const check = {
      timeOut: parseFloat(webhookSettingForm.timeOut),
      try1: parseFloat(webhookSettingForm.try1),
      value1: parseFloat(webhookSettingForm.value1),
      try2: parseFloat(webhookSettingForm.try2),
      value2: parseFloat(webhookSettingForm.value2),
      try3: parseFloat(webhookSettingForm.try3),
      value3: parseFloat(webhookSettingForm.value3),
    }
    try {
      const dataRes = await setRetrySetting({ variables: check })
      if (dataRes) {
        notify(MESSAGES.SUCCESS.S_UPDATED_WEBHOOK_SETTING, STATUS.SUCCESS)
        handleCloseDialog(false)
        setIsRefetch(true)
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_UPDATED_WEBHOOK_SETTING)
      setWebhookSettingForm(webhookSettingInitial)
      handleCloseDialog(false)
    }
  }

  useEffect(() => {
    setSumValue(
      (webhookSettingForm.value1 / 60) * webhookSettingForm.try1 +
        webhookSettingForm.value2 * webhookSettingForm.try2 +
        webhookSettingForm.value3 * webhookSettingForm.try3,
    )
  }, [webhookSettingForm])

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={customStyles}>
      <div>
        <div className="absolute right-4 top-4">
          <img src={iconTimes} alt="" className="cursor-pointer" onClick={handleCancelEdit} />
        </div>
        <p className="pb-6 text-headline5 text-neutral-1">Webhook setting</p>
        <form onSubmit={onSubmit}>
          {/* TABLE */}
          <div className="relative overflow-auto border rounded-xl">
            <table className="w-full text-left">
              <thead className="bg-primary-shade4 text-body1 text-neutral-2">
                <tr>
                  <th scope="col" className="font-semibold py-5 pl-6 w-[50%]">
                    Timeout
                  </th>
                  <th className="font-semibold py-5 px-2 w-[25%]">Value</th>
                  <th className="font-semibold py-5 pr-6 pl-2 whitespace-nowrap w-[25%]">
                    Try times
                  </th>
                </tr>
              </thead>
              <tbody className="text-body2 text-neutral-3">
                <tr className="h-3"></tr>
                <tr className="border-b-neutral-6 border-b h-[82px]">
                  <td className="pl-6">
                    <p>Webhook no response timeout</p>
                    <p>{`(0 second < x <= 15 seconds)`}</p>
                  </td>
                  <td className="px-2">
                    <div className="flex items-center justify-between">
                      <span className="min-w-[56%] pr-2 font-semibold">
                        <InputBase
                          type="number"
                          className="w-20"
                          value={webhookSettingForm.timeOut}
                          handleChange={(e) => {
                            handleChange('timeOut', e)
                          }}
                        />
                      </span>
                      <span className="w-[44%] font-medium">
                        {HandleChangeString(webhookSettingForm.timeOut, 'Second')}
                      </span>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr className="border-b-neutral-6 border-b h-[82px]">
                  <td className="pl-6">
                    <p>Waiting time between retries</p>
                  </td>
                  <td className="px-2">
                    <div className="flex items-center justify-between">
                      <span className="min-w-[56%] pr-2 font-semibold">
                        <InputBase
                          type="number"
                          className="w-20 mr-2 h-14"
                          value={webhookSettingForm.value1}
                          handleChange={(e) => {
                            handleChange('value1', e)
                          }}
                        />
                      </span>
                      <span className="w-[44%] font-medium">
                        {HandleChangeString(webhookSettingForm.value1, 'Minute')}
                      </span>
                    </div>
                  </td>
                  <td className="px-2">
                    <div className="flex items-center justify-between">
                      <span className="min-w-[56%] pr-2 font-semibold">
                        <InputBase
                          type="number"
                          className="w-20 mr-2 h-14"
                          value={webhookSettingForm.try1}
                          handleChange={(e) => {
                            handleChange('try1', e)
                          }}
                        />
                      </span>
                      <span className="w-[44%] font-medium">
                        {HandleChangeString(webhookSettingForm.try1, 'Time')}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr className="border-b-neutral-6 border-b h-[82px]">
                  <td className="pl-6">
                    <p>Waiting time between next layer of retries</p>
                  </td>
                  <td className="px-2">
                    <div className="flex items-center justify-between">
                      <span className="min-w-[56%] pr-2 font-semibold">
                        <InputBase
                          type="number"
                          className="w-20 mr-2 h-14"
                          value={webhookSettingForm.value2}
                          handleChange={(e) => {
                            handleChange('value2', e)
                          }}
                        />
                      </span>
                      <span className="w-[44%] font-medium">
                        {HandleChangeString(webhookSettingForm.value2, 'Hour')}
                      </span>
                    </div>
                  </td>
                  <td className="px-2">
                    <div className="flex items-center justify-between">
                      <span className="min-w-[56%] pr-2 font-semibold">
                        <InputBase
                          type="number"
                          className="w-20 mr-2 h-14"
                          value={webhookSettingForm.try2}
                          handleChange={(e) => {
                            handleChange('try2', e)
                          }}
                        />
                      </span>
                      <span className="w-[44%] font-medium">
                        {HandleChangeString(webhookSettingForm.try2, 'Time')}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr className="border-b-neutral-6 border-b h-[82px]">
                  <td className="pl-6">
                    <p>Waiting time between last layer of retries</p>
                  </td>
                  <td className="px-2">
                    <div className="flex items-center justify-between">
                      <span className="min-w-[56%] pr-2 font-semibold">
                        <InputBase
                          type="number"
                          className="w-20 mr-2 h-14"
                          value={webhookSettingForm.value3}
                          handleChange={(e) => {
                            handleChange('value3', e)
                          }}
                        />
                      </span>
                      <span className="w-[44%] font-medium">
                        {HandleChangeString(webhookSettingForm.value3, 'Hour')}
                      </span>
                    </div>
                  </td>
                  <td className="px-2">
                    <div className="flex items-center justify-between">
                      <span className="min-w-[56%] pr-2 font-semibold">
                        <InputBase
                          type="number"
                          className="w-20 mr-2 h-14"
                          value={webhookSettingForm.try3}
                          handleChange={(e) => {
                            handleChange('try3', e)
                          }}
                        />
                      </span>
                      <span className="w-[44%] font-medium">
                        {HandleChangeString(webhookSettingForm.try3, 'Time')}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr className="h-[82px]">
                  <td className="pl-6">
                    <p>
                      Mark webhook as not functioning and stop trying to&nbsp;
                      <br className="mw1023:hidden" />
                      call the website again
                    </p>
                  </td>
                  <td className="p-2">
                    <div className="flex items-center justify-between">
                      <span className="min-w-[56%] max-w-[25px] break-all pl-4 pr-2 font-semibold">
                        {Number.isNaN(sumValue) ? 0 : sumValue.toFixed(0)}
                      </span>
                      <span className="w-[44%] font-medium">
                        {HandleChangeString(Number(sumValue.toFixed(0)), 'Hour')}
                      </span>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr className="h-3"></tr>
              </tbody>
            </table>
          </div>
          <hr className="my-6" />
        </form>
        <div className="flex justify-between gap-3">
          <ButtonBase
            type="submit"
            className="w-full text-center bg-primary-1 min-w-min"
            onClick={onSubmit}
            bgDisabled="bg-primary-shade3"
          >
            Save change
          </ButtonBase>
          <ButtonBase
            className="justify-around w-full border-2 border-primary-1 text-primary-1 bg-white-50 hover:bg-primary-shade4"
            onClick={handleCancelEdit}
          >
            <p>Cancel</p>
          </ButtonBase>
        </div>
      </div>
    </BaseDialog>
  )
}

export default EditWebhookSetting
