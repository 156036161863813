import React, { useEffect, useRef, useState } from 'react'
import Moment from 'react-moment'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import InfiniteScroll from 'react-infinite-scroll-component'

import {
  ActivitiesGroupModel,
  ActivitiesLogModel,
  CarrierGroupModel,
  JsonPacketGroupModel,
  RoleModel,
  SystemMonitoringFilterModel,
  SystemMonitoringHeaderModel,
  tableHeaderActivitiesMng,
  tableHeaderActivitiesMngByAMS,
} from './activitiesMngModels'
import IconHoverCopyByAMS from '../../../../../assets/images/icon-hover-copy.svg'
import iconFilter from '../../../../../assets/images/icon-filter.svg'
import iconFiltered from '../../../../../assets/images/icon-filtered.svg'
import { optionHover } from '../component/activitiesLogModel'
import { useSession } from '../../../../../helpers/checkSession'
import { LocationState } from '../../../../../constants/interface'
import { RootState } from '../../../../../store'
import { MESSAGES } from '../../../../../constants/messages'
import { FILTER_BY_GROUP, FILTER_BY_MULTI_COLUMN } from '../../../../../queries/adminPortal'
import {
  clearDateTimeFilterForAdminByAMS,
  setActivitiesGroupTypeForAdminByAMS,
  setAMSForAdminByAMS,
  setRequestJsonPacketForAdminByAMS,
} from '../../../../../redux/actions/adminPortal'
import {
  onHandleCopyItem,
  onHandleFormatDate,
  onHandleSplitUserName,
} from '../../../../../helpers/functionUtils'
import { CODE_TIMEOUT } from '../../../../../constants/statusCode'
import { KIND, styleJsonPacket } from '../../../../../constants/common'
import Loading from '../../../../../components/loading'
import Filter, { handleGetListFilterForAdminType } from '../component/filterBase'
import DatePickerFilter from '../component/dateTimePicker'

const classFilterResponsive = 'md:relative md:right-[-5px] md:ml-[-12px]'

type Props = {
  isAgencyByAdmin?: boolean
}

const ActivitiesManagementAdminAMSTable: React.FunctionComponent<Props> = ({ isAgencyByAdmin }) => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const locationState: LocationState = state as object
  const { timeOutSession, handleErrorSession } = useSession()

  const dispatchForAdminByAMS = useDispatch()
  const carrierGroupTypeForAdminByAMS = useSelector(
    (state: RootState) => state?.adminPortalReducer?.carrierGroupType,
  )
  const activitiesGroupTypeForAdminByAMS = useSelector(
    (state: RootState) => state?.adminPortalReducer?.activitiesGroupType,
  )
  const requestJsonGroupPacketForAdminByAMS = useSelector(
    (state: RootState) => state?.adminPortalReducer.requestJsonPacket,
  )

  const requestFromDateForAdminByAMS = useSelector(
    (state: RootState) => state?.adminPortalReducer?.fromDateByAMS,
  )
  const requestToDateForAdminByAMS = useSelector(
    (state: RootState) => state?.adminPortalReducer?.toDateByAMS,
  )

  const [initialSkipForAdminByAMS] = useState<number>(0)
  const [listActivitiesForAdminByAMS, setListActivitiesForAdminByAMS] = useState<
    ActivitiesLogModel[]
  >([])
  const [filterLoading, setFilterLoading] = useState<boolean>(false)

  const [idSelectForAdminByAMS, setIdSelectForAdminByAMS] = useState<string>('')
  const [isOpenFilterForAdminByAMS, setIsOpenFilterForAdminByAMS] = useState<boolean>(false)
  const [listAMSFilterForAdminByAMS, setListAMSFilterForAdminByAMS] = useState<string[]>([])
  const [listActivitiesFilterForAdminByAMS, setListActivitiesFilterForAdminByAMS] = useState<
    string[]
  >([])
  const [listJsonPacketFilterForAdminByAMS, setListJsonPacketFilterForAdminByAMS] = useState<
    string[]
  >([])
  const [fromDateForAdminByAMS, setFromDateForAdminByAMS] = useState<string | null>(
    requestFromDateForAdminByAMS,
  )
  const [toDateForAdminByAMS, setToDateForAdminByAMS] = useState<string | null>(
    requestToDateForAdminByAMS,
  )
  const [hoverForAdminByAMS, setHoverForAdminByAMS] = useState<optionHover>({
    activityId: null,
    status: false,
  })
  const [hasMoreForAdminByAMS, setHasMoreForAdminByAMS] = useState<boolean>(true)
  const [showAMSForAdminByAMS, setShowAMSForAdminByAMS] = useState<CarrierGroupModel[]>([])
  const [showActivitiesGroupForAdminByAMS, setShowActivitiesGroupForAdminByAMS] = useState<
    ActivitiesGroupModel[]
  >([])
  const [showJsonPacketGroupForAdminByAMS, setShowJsonPacketGroupForAdminByAMS] = useState<
    JsonPacketGroupModel[]
  >([])

  const [listAMSFilterForAdminByAMSOriginal, setListAMSFilterForAdminByAMSOriginal] = useState<
    string[]
  >([])
  const [listActivitiesFilterForAdminByAMSOriginal, setListActivitiesFilterForAdminByAMSOriginal] =
    useState<string[]>([])
  const [listJsonPacketFilterForAdminByAMSOriginal, setListJsonPacketFilterForAdminByAMSOriginal] =
    useState<string[]>([])

  // ref
  const btnRefForAdminByAMS = useRef<HTMLDivElement>(null)
  const filterRefForAdminByAMS = useRef<HTMLDivElement>(null)

  // get data activities log
  const locationStateDataByAMS = {
    partyId: locationState?.partyId,
    kind: locationState?.kind,
    skip: initialSkipForAdminByAMS,
    take: SystemMonitoringFilterModel.DEFAULT_OFFSET,
  }

  const filterByMulColumnByAMS = {
    skip: initialSkipForAdminByAMS,
    take: SystemMonitoringFilterModel.DEFAULT_OFFSET,
    kind: KIND.AGENCIES,
  }

  const {
    loading: getActivitiesLogLoadingForAdminByAMS,
    error: getActivitiesLogErrorForAdminByAMS,
    data: getActivitiesDataForAdminByAMS,
    fetchMore: fetchMoreForGetActivitiesForAdminByAMS,
  } = useQuery(FILTER_BY_MULTI_COLUMN, {
    fetchPolicy: 'no-cache',
    variables: {
      data: locationState ? locationStateDataByAMS : filterByMulColumnByAMS,
    },
  })

  // get data filter carrier
  const { error: getGroupAMSTypeErrorForAdminByAMS, data: getGroupAMSTypeDataForAdminByAMS } =
    useQuery(FILTER_BY_GROUP, {
      variables: {
        data: {
          columnName: SystemMonitoringFilterModel.TYPE_CARRIER,
          kind: KIND.AGENCIES,
        },
      },
    })

  // get data filter activities type
  const {
    error: getGroupActivitiesTypeErrorForAdminByAMS,
    data: getGroupActivitiesTypeDataForAdminByAMS,
  } = useQuery(FILTER_BY_GROUP, {
    variables: {
      data: {
        columnName: SystemMonitoringFilterModel.TYPE_ACTIVITIES,
        kind: KIND.AGENCIES,
      },
    },
  })

  // get data filter Json Packet
  const { error: getGroupJsonPacketErrorForAdminByAMS, data: getGroupJsonPacketDataForAdminByAMS } =
    useQuery(FILTER_BY_GROUP, {
      variables: {
        data: {
          columnName: SystemMonitoringFilterModel.TYPE_REQUEST_JSON_PACKET,
          kind: KIND.AGENCIES,
        },
      },
    })

  // set list default
  const setListFilterGroupOriginal = () => {
    setListAMSFilterForAdminByAMS(listAMSFilterForAdminByAMSOriginal)
    setListActivitiesFilterForAdminByAMS(listActivitiesFilterForAdminByAMSOriginal)
    setListJsonPacketFilterForAdminByAMS(listJsonPacketFilterForAdminByAMSOriginal)
  }

  const onHandleShowIcon = (id: string, label: string) => {
    if (isOpenFilterForAdminByAMS && id === idSelectForAdminByAMS) {
      return iconFiltered
    }
    if (
      listAMSFilterForAdminByAMS?.length > 0 &&
      label === SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL
    ) {
      return iconFiltered
    }
    if (
      listActivitiesFilterForAdminByAMS?.length > 0 &&
      label === SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL
    ) {
      return iconFiltered
    }
    if (
      listJsonPacketFilterForAdminByAMS?.length > 0 &&
      label === SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL
    ) {
      return iconFiltered
    }
    if (
      (fromDateForAdminByAMS !== null || toDateForAdminByAMS !== null) &&
      label === SystemMonitoringHeaderModel.TYPE_DATE_TIME_LABEL
    ) {
      return iconFiltered
    }
    return iconFilter
  }

  const handleOpenFilterForAdminByAMS = (id: string) => {
    setListFilterGroupOriginal()
    if (idSelectForAdminByAMS === id) {
      setIdSelectForAdminByAMS('')
      return
    }
    setIsOpenFilterForAdminByAMS(true)
    setIdSelectForAdminByAMS(id.toString())
  }

  const onHandleCheckedValueForAdminByAMS = (value: any, identify: string) => {
    switch (identify) {
      case SystemMonitoringHeaderModel.TYPE_AMS_LABEL:
        if (listAMSFilterForAdminByAMS.includes(value)) {
          return setListAMSFilterForAdminByAMS(
            listAMSFilterForAdminByAMS.filter((val: any) => val != value),
          )
        } else {
          return setListAMSFilterForAdminByAMS([...listAMSFilterForAdminByAMS, value])
        }
      case SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL:
        if (listActivitiesFilterForAdminByAMS.includes(value)) {
          return setListActivitiesFilterForAdminByAMS(
            listActivitiesFilterForAdminByAMS.filter((val) => val != value),
          )
        } else {
          return setListActivitiesFilterForAdminByAMS([...listActivitiesFilterForAdminByAMS, value])
        }
      case SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL:
        if (listJsonPacketFilterForAdminByAMS.includes(value)) {
          return setListJsonPacketFilterForAdminByAMS(
            listJsonPacketFilterForAdminByAMS.filter((val) => val != value),
          )
        } else {
          return setListJsonPacketFilterForAdminByAMS([...listJsonPacketFilterForAdminByAMS, value])
        }
      default:
    }
  }

  // handle change input
  const onInputHandler = (callBackValue: string, identify: string) => {
    if (identify !== null) {
      switch (identify) {
        case SystemMonitoringHeaderModel.TYPE_AMS_LABEL:
          if (carrierGroupTypeForAdminByAMS !== null && carrierGroupTypeForAdminByAMS.length > 0) {
            setShowAMSForAdminByAMS(
              carrierGroupTypeForAdminByAMS.filter((item) => {
                return item?.key?.toLowerCase()?.includes(callBackValue.toLowerCase())
              }),
            )
          }
          break
        case SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL:
          if (
            activitiesGroupTypeForAdminByAMS !== null &&
            activitiesGroupTypeForAdminByAMS.length > 0
          ) {
            setShowActivitiesGroupForAdminByAMS(
              activitiesGroupTypeForAdminByAMS.filter((item) => {
                return item?.key?.toLowerCase()?.includes(callBackValue.toLowerCase())
              }),
            )
          }
          break
        case SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL:
          if (
            requestJsonGroupPacketForAdminByAMS !== null &&
            requestJsonGroupPacketForAdminByAMS.length > 0
          ) {
            setShowJsonPacketGroupForAdminByAMS(
              requestJsonGroupPacketForAdminByAMS.filter((item) => {
                return item?.key?.toLowerCase()?.includes(callBackValue.toLowerCase())
              }),
            )
          }
          break
        default:
      }
    }
  }

  // fetch more data
  const fetchMoreDataForAdminByAMS = async () => {
    const activitiesData = {
      skip: listActivitiesForAdminByAMS?.length,
      take: SystemMonitoringFilterModel.DEFAULT_OFFSET,
      partyName:
        listAMSFilterForAdminByAMS !== null && listAMSFilterForAdminByAMS?.length > 0
          ? listAMSFilterForAdminByAMS
          : null,
      activityType:
        listActivitiesFilterForAdminByAMS !== null && listActivitiesFilterForAdminByAMS?.length > 0
          ? listActivitiesFilterForAdminByAMS
          : null,
      jsonPacket:
        listJsonPacketFilterForAdminByAMS !== null && listJsonPacketFilterForAdminByAMS?.length > 0
          ? listJsonPacketFilterForAdminByAMS
          : null,
      fromDate: fromDateForAdminByAMS !== null ? fromDateForAdminByAMS : null,
      toDate: toDateForAdminByAMS !== null ? toDateForAdminByAMS : null,
      kind: KIND.AGENCIES,
    }
    try {
      const response = await fetchMoreForGetActivitiesForAdminByAMS({
        variables: {
          data: locationState
            ? { ...activitiesData, partyId: locationState?.partyId, kind: locationState?.kind }
            : activitiesData,
        },
      })
      if (!getActivitiesLogLoadingForAdminByAMS && !getActivitiesLogErrorForAdminByAMS) {
        const data = response.data.filterByMultiColumnByAdmin
        if (!data?.length || data?.length == undefined) {
          setHasMoreForAdminByAMS(false)
          return
        }
        const dataState = [...listActivitiesForAdminByAMS, ...data]

        setTimeout(() => {
          setListActivitiesForAdminByAMS(dataState)
        }, 100)
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_SHOW_ACTIVITIES_LOG)
    }
  }

  const onHandleCopyJsonAMSPacket = async (jsonPacket: string) => {
    await onHandleCopyItem(
      jsonPacket,
      MESSAGES.INFO.I_COPIED_JSON_PACKET,
      MESSAGES.ERROR.E_COPY_FAILED,
    )
  }
  const onHandleCopyJsonAMSResponse = async (jsonResponse: string) => {
    await onHandleCopyItem(
      jsonResponse,
      MESSAGES.INFO.I_COPIED_JSON_RESPONSE,
      MESSAGES.ERROR.E_COPY_FAILED,
    )
  }

  // handle check list filter
  const handleGetListFilterForAdminByAMS = (field: string) => {
    switch (field) {
      case SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL:
        return new Object({
          group: showAMSForAdminByAMS,
          identify: SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL,
        })
      case SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL:
        return new Object({
          group: showActivitiesGroupForAdminByAMS,
          identify: SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL,
        })
      case SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL:
        return new Object({
          group: showJsonPacketGroupForAdminByAMS,
          identify: SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL,
        })
      default:
    }
  }

  const handleNavigateToAMSDetail = (organizationName: string, partyId: string, kind: string) => {
    navigate('/admin-portal/detail', {
      state: {
        carrierName: organizationName,
        partyId: partyId,
        kind: kind,
      },
    })
  }

  // Reset group type, set value from store reducer
  const resetGroupTypeForAdminByAMS = () => {
    setShowAMSForAdminByAMS(carrierGroupTypeForAdminByAMS)
    setShowActivitiesGroupForAdminByAMS(activitiesGroupTypeForAdminByAMS)
    setShowJsonPacketGroupForAdminByAMS(requestJsonGroupPacketForAdminByAMS)
  }

  // get list data for filter
  const handleListActivitiesTypeForAdminByAMS = (field: string) => {
    switch (field) {
      case SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL:
        return new Object({
          listChecked: listAMSFilterForAdminByAMS,
          identify: SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL,
        })
      case SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL:
        return new Object({
          listChecked: listActivitiesFilterForAdminByAMS,
          identify: SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL,
        })
      case SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL:
        return new Object({
          listChecked: listJsonPacketFilterForAdminByAMS,
          identify: SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL,
        })
      default:
        break
    }
  }

  // handle submit filter
  const onHandleApplyFilterForAdminByAMS = async () => {
    setIsOpenFilterForAdminByAMS(false)
    const fromDateRequest = onHandleFormatDate(requestFromDateForAdminByAMS) as string
    const toDateRequest = onHandleFormatDate(requestToDateForAdminByAMS) as string

    setFromDateForAdminByAMS(fromDateRequest ? fromDateRequest : null)
    setToDateForAdminByAMS(toDateRequest ? toDateRequest : null)
    setFilterLoading(true)

    const getActivitiesData = {
      skip: initialSkipForAdminByAMS,
      take: SystemMonitoringFilterModel.DEFAULT_OFFSET,
      partyName:
        listAMSFilterForAdminByAMS !== null && listAMSFilterForAdminByAMS.length > 0
          ? listAMSFilterForAdminByAMS
          : null,
      activityType:
        listActivitiesFilterForAdminByAMS !== null && listActivitiesFilterForAdminByAMS.length > 0
          ? listActivitiesFilterForAdminByAMS
          : null,
      jsonPacket:
        listJsonPacketFilterForAdminByAMS !== null && listJsonPacketFilterForAdminByAMS.length > 0
          ? listJsonPacketFilterForAdminByAMS
          : null,
      fromDate: fromDateRequest !== null && fromDateRequest !== undefined ? fromDateRequest : null,
      toDate: toDateRequest !== null && toDateRequest !== undefined ? toDateRequest : null,
      kind: KIND.AGENCIES,
    }
    try {
      const request = await fetchMoreForGetActivitiesForAdminByAMS({
        variables: {
          data: locationState
            ? { ...getActivitiesData, partyId: locationState?.partyId, kind: locationState?.kind }
            : getActivitiesData,
        },
      })
      if (!getActivitiesLogLoadingForAdminByAMS && !getActivitiesLogErrorForAdminByAMS) {
        const data = request.data.filterByMultiColumnByAdmin
        setListAMSFilterForAdminByAMSOriginal(listAMSFilterForAdminByAMS)
        setListActivitiesFilterForAdminByAMSOriginal(listActivitiesFilterForAdminByAMS)
        setListJsonPacketFilterForAdminByAMSOriginal(listJsonPacketFilterForAdminByAMS)

        if (!data.length || data === undefined) {
          setListActivitiesForAdminByAMS([])
          setHasMoreForAdminByAMS(true)
          return
        }
        setTimeout(() => {
          setListActivitiesForAdminByAMS(data)
        }, 500)
      }
      setFilterLoading(false)
    } catch (error) {
      setFilterLoading(false)
      handleErrorSession(error, MESSAGES.ERROR.E_SHOW_ACTIVITIES_LOG)
    } finally {
      setFilterLoading(false)
      setIsOpenFilterForAdminByAMS(false)
      setIdSelectForAdminByAMS('')
      resetGroupTypeForAdminByAMS()
    }
  }

  // handle cancel filter
  const onHandleClickCancelForAdminByAMS = () => {
    setListFilterGroupOriginal()
    setIsOpenFilterForAdminByAMS(false)
    setIdSelectForAdminByAMS('')
    resetGroupTypeForAdminByAMS()
  }

  // handle click outside
  useEffect(() => {
    const handleOutsideClickForAdminByAMS = (e: any) => {
      if (
        filterRefForAdminByAMS &&
        !filterRefForAdminByAMS.current?.contains(e.target) &&
        e.target.id !== 'filter-ref'
      ) {
        setIsOpenFilterForAdminByAMS(false)
        setIdSelectForAdminByAMS('')
        setListFilterGroupOriginal()
      }
    }
    document.addEventListener('mousedown', handleOutsideClickForAdminByAMS)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClickForAdminByAMS)
    }
  }, [isOpenFilterForAdminByAMS])

  // Listening for update fromDate or toDate to filter
  useEffect(() => {
    if (requestFromDateForAdminByAMS || requestToDateForAdminByAMS) {
      onHandleApplyFilterForAdminByAMS()
    }
  }, [requestFromDateForAdminByAMS, requestToDateForAdminByAMS])

  // set data for list activities log
  useEffect(() => {
    setListActivitiesForAdminByAMS(getActivitiesDataForAdminByAMS?.filterByMultiColumnByAdmin)
  }, [getActivitiesDataForAdminByAMS])

  useEffect(() => {
    setShowAMSForAdminByAMS(getGroupAMSTypeDataForAdminByAMS?.filterByGroupByAdmin)
    setShowActivitiesGroupForAdminByAMS(
      getGroupActivitiesTypeDataForAdminByAMS?.filterByGroupByAdmin,
    )
    setShowJsonPacketGroupForAdminByAMS(getGroupJsonPacketDataForAdminByAMS?.filterByGroupByAdmin)
    dispatchForAdminByAMS(
      setAMSForAdminByAMS(getGroupAMSTypeDataForAdminByAMS?.filterByGroupByAdmin),
    )
    dispatchForAdminByAMS(
      setActivitiesGroupTypeForAdminByAMS(
        getGroupActivitiesTypeDataForAdminByAMS?.filterByGroupByAdmin,
      ),
    )
    dispatchForAdminByAMS(
      setRequestJsonPacketForAdminByAMS(getGroupJsonPacketDataForAdminByAMS?.filterByGroupByAdmin),
    )
  }, [
    getGroupAMSTypeDataForAdminByAMS,
    getGroupActivitiesTypeDataForAdminByAMS,
    getGroupJsonPacketDataForAdminByAMS,
  ])

  // clear store
  useEffect(() => {
    dispatchForAdminByAMS(clearDateTimeFilterForAdminByAMS())
    setFromDateForAdminByAMS(null)
    setToDateForAdminByAMS(null)
  }, [])

  // handle session timeout
  useEffect(() => {
    if (
      getActivitiesLogErrorForAdminByAMS?.graphQLErrors[0]?.extensions?.code ||
      getGroupJsonPacketErrorForAdminByAMS?.graphQLErrors[0]?.extensions?.code ||
      getGroupActivitiesTypeErrorForAdminByAMS?.graphQLErrors[0]?.extensions?.code ||
      getGroupAMSTypeErrorForAdminByAMS?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT
    ) {
      timeOutSession()
    }
  }, [
    getActivitiesLogErrorForAdminByAMS,
    getGroupAMSTypeErrorForAdminByAMS,
    getGroupActivitiesTypeErrorForAdminByAMS,
    getGroupJsonPacketErrorForAdminByAMS,
  ])

  // handle responsive for request id json header at 1024px and 768px
  const handleResponsiveForRequestIDJsonDevAdmin = (label: string) => {
    if (locationState && label === SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL) {
      return 'md:!mt-0 lg:mt-[-24px]'
    }
  }

  return (
    <div>
      <div className="relative overflow-auto border rounded-xl">
        <table className="w-full text-left">
          <thead className="bg-primary-shade4 text-body1 text-neutral-2 md:pr-4">
            <tr>
              {tableHeaderActivitiesMng
                .slice(!locationState ? 0 : 1, tableHeaderActivitiesMng.length)
                .map((item: any, idx) => (
                  <th
                    key={idx}
                    className={`font-semibold py-5 pl-6 ${
                      idx === 0 && isAgencyByAdmin
                        ? tableHeaderActivitiesMngByAMS?.styles
                        : item.styles
                    }`}
                  >
                    <div
                      className={`flex ${handleResponsiveForRequestIDJsonDevAdmin(
                        idx === 0 && isAgencyByAdmin
                          ? tableHeaderActivitiesMngByAMS?.label
                          : item.label,
                      )}`}
                    >
                      <span className={`${item.isOneLineRes && 'lg:mt-[-3.5px]'}`}>
                        {idx === 0 && isAgencyByAdmin
                          ? tableHeaderActivitiesMngByAMS?.name
                          : item.name}
                      </span>
                      <div
                        ref={btnRefForAdminByAMS}
                        className={`${item.isOneLineRes && 'lg:mt-[-3.5px]'}`}
                      >
                        {idx === 0 && item?.isFilter && isAgencyByAdmin
                          ? null
                          : item?.isFilter && (
                              <div
                                className={`w-5 h-5 ${classFilterResponsive}`}
                                onClick={() => {
                                  handleOpenFilterForAdminByAMS(idx.toString())
                                }}
                              >
                                <img
                                  id="filter-ref"
                                  src={onHandleShowIcon(idx.toString(), item.label)}
                                  alt="Icon-Filter"
                                  className="cursor-pointer mt-0.5 ml-2"
                                />
                              </div>
                            )}
                        {item.isFilter &&
                        item.label !== SystemMonitoringHeaderModel.TYPE_DATE_TIME_LABEL ? (
                          <Filter
                            isOpenFilter={isOpenFilterForAdminByAMS}
                            id={idx.toString()}
                            idSelected={idSelectForAdminByAMS}
                            objectGroupType={
                              handleGetListFilterForAdminByAMS(
                                item.label,
                              ) as handleGetListFilterForAdminType
                            }
                            handleClickCancel={onHandleClickCancelForAdminByAMS}
                            onRef={filterRefForAdminByAMS}
                            inputHandler={onInputHandler}
                            handCheckedValue={onHandleCheckedValueForAdminByAMS}
                            handleFilter={onHandleApplyFilterForAdminByAMS}
                            listCheckedInGroupType={handleListActivitiesTypeForAdminByAMS(
                              item.label,
                            )}
                          />
                        ) : (
                          <DatePickerFilter
                            isOpenFilter={isOpenFilterForAdminByAMS}
                            id={idx.toString()}
                            idSelected={idSelectForAdminByAMS}
                            onRef={filterRefForAdminByAMS}
                            handleClickCancel={onHandleClickCancelForAdminByAMS}
                            identifier={RoleModel.ADMIN}
                            isAgencyByAdmin={isAgencyByAdmin}
                          />
                        )}
                      </div>
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
        </table>
        {filterLoading || getActivitiesLogLoadingForAdminByAMS ? (
          <Loading className="relative py-6" height={30} width={30} />
        ) : (
          Boolean(
            listActivitiesForAdminByAMS !== null && listActivitiesForAdminByAMS?.length > 0,
          ) && (
            <InfiniteScroll
              dataLength={listActivitiesForAdminByAMS?.length}
              next={fetchMoreDataForAdminByAMS}
              hasMore={hasMoreForAdminByAMS}
              height={'max-content'}
              style={{ maxHeight: 980 }}
              loader={''}
              endMessage={''}
              className="cus-scrollbar"
            >
              {listActivitiesForAdminByAMS?.map((itemAMS: ActivitiesLogModel, index: number) => (
                <table
                  key={index}
                  onClick={() =>
                    handleNavigateToAMSDetail(
                      itemAMS?.organizationName,
                      itemAMS?.partyId,
                      itemAMS?.kind,
                    )
                  }
                  className="cursor-pointer"
                >
                  <tbody>
                    <tr
                      className="h-[72px] w-full hover:bg-neutral-7 pt-5"
                      onMouseEnter={() => {
                        setHoverForAdminByAMS({
                          activityId: itemAMS?.activityId,
                          status: true,
                        })
                      }}
                      onMouseLeave={() => {
                        setHoverForAdminByAMS({
                          activityId: null,
                          status: false,
                        })
                      }}
                    >
                      {!locationState && (
                        <td
                          className={`${
                            isAgencyByAdmin ? 'w-[20%]' : 'w-[15%]'
                          } py-5 pl-6 break-all align-top`}
                        >
                          <span>{itemAMS?.organizationName}</span>
                        </td>
                      )}
                      <td
                        className={`w-[15%] py-5 break-all pl-6 md:pl-1 align-top ${
                          locationState && 'md:pl-6'
                        }`}
                      >
                        <span>{itemAMS?.activityType}</span>
                      </td>
                      <td
                        className={`w-[13%] py-5 break-all pl-6 md:pl-3 align-top ${
                          locationState && 'md:pl-[20px]'
                        }`}
                      >
                        <span>
                          {
                            <Moment format="yyyy-MM-DD HH:mm:ss.SSS">
                              {itemAMS.createdAt ?? ''}
                            </Moment>
                          }
                        </span>
                      </td>
                      <td className={` w-[22%] lg:pl-8 pl-1 md:w-[19%] align-top`}>
                        <div className="relative w-full break-all ">
                          {hoverForAdminByAMS?.status &&
                          itemAMS.activityId === hoverForAdminByAMS.activityId &&
                          itemAMS?.jsonPacket ? (
                            <div className="h-[86px]">
                              <span
                                style={{ ...styleJsonPacket }}
                                className={`break-all lg:pl-2 pt-[19px] pl-6`}
                              >
                                {itemAMS?.jsonPacket}
                              </span>
                              <button
                                title="Copy this JSON Packet"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  onHandleCopyJsonAMSPacket(itemAMS.jsonPacket)
                                }}
                              >
                                <img
                                  src={IconHoverCopyByAMS}
                                  alt="Icon-Hover-Copy"
                                  className="absolute top-[24px] right-0"
                                />
                              </button>
                            </div>
                          ) : (
                            <span
                              style={{ ...styleJsonPacket }}
                              className={`break-all lg:pl-2 pt-[19px] pl-6`}
                            >
                              {itemAMS?.jsonPacket}
                            </span>
                          )}
                        </div>
                      </td>
                      <td
                        className={`w-[13%] pr-2 py-5 pl-8 break-all align-top ${
                          locationState ? 'md:pl-[35px]' : 'md:pl-[25px]'
                        }`}
                      >
                        <span>{onHandleSplitUserName(itemAMS?.userName as string)}</span>
                      </td>
                       <td className={` w-[22%] lg:pl-8  md:w-[19%] align-top ${
                          locationState ? 'pl-6' : 'pl-8'
                        }` }>
                      <div className="relative w-full break-all ">
                        {hoverForAdminByAMS?.status &&
                          itemAMS.activityId === hoverForAdminByAMS.activityId &&
                          itemAMS?.statusResponse ? (
                            <div className="h-[86px]">
                              <span
                                style={{ ...styleJsonPacket }}
                                className={`break-all lg:pl-2 pt-[19px]`}
                              >
                                {itemAMS?.statusResponse} 
                              </span>
                              <button
                                title="Copy this JSON Response"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  onHandleCopyJsonAMSResponse(itemAMS.statusResponse)
                                }}
                              >
                                <img
                                  src={IconHoverCopyByAMS}
                                  alt="Icon-Hover-Copy"
                                  className="absolute top-[24px] right-0"
                                />
                              </button>
                            </div>
                          ) : (
                            <span
                              style={{ ...styleJsonPacket }}
                              className={`break-all lg:pl-2 pt-[19px] pr-6`}
                            >
                              {itemAMS?.statusResponse}
                            </span>
                          )}
                        </div>
                        </td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </InfiniteScroll>
          )
        )}
        {!listActivitiesForAdminByAMS?.length && !getActivitiesLogLoadingForAdminByAMS && (
          <p className="py-6 text-center text-body1 text-neutral-4">No data available.</p>
        )}
      </div>
    </div>
  )
}

export default ActivitiesManagementAdminAMSTable
