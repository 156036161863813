import SystemMonitoring from '.'
import { checkProdSite } from '../../helpers/checkProdSite'
import { PageRoute } from '../../routes/routerInterface'

const systemMonitoringRoutes: PageRoute[] = [
  {
    path: '/system-monitoring',
    element: SystemMonitoring,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
  },
]

export default systemMonitoringRoutes
