export const optionsAccountType = [
  {
    id: '1',
    name: 'Carrier developer',
    kind: 'carriers',
  },
  {
    id: '2',
    name: 'Agency Mgt Provider developer',
    kind: 'agencies',
  },
  {
    id: '3',
    name: 'Propeller',
    kind: null,
  },
]

export interface AccountType {
  id: string
  name: string
  kind: string | null
}
