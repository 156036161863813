import {
  SET_LOADING,
  SET_UNLOADING,
  LOGIN,
  LOGOUT,
  SET_USER_INFO,
  CLEAR_USER_INFO,
  SET_SPAMMING,
  CLEAR_SPAMMING,
  SET_IS_TIME_OUT,
} from './actionTypes'

//LOADING
export const setLoading = () => {
  return {
    type: SET_LOADING,
  }
}

export const setUnLoading = () => {
  return {
    type: SET_UNLOADING,
  }
}

//LOGIN WITH GOOGLE
export const login = () => {
  return {
    type: LOGIN,
  }
}

export const logout = () => {
  return {
    type: LOGOUT,
  }
}

export const setUserInfo = (value: any) => {
  return {
    type: SET_USER_INFO,
    payload: value,
  }
}

export const clearUserInfo = () => {
  return {
    type: CLEAR_USER_INFO,
  }
}

// SPAMMING
export const setSpamming = (value: object) => {
  return {
    type: SET_SPAMMING,
    payload: value,
  }
}

export const clearSpamming = () => {
  return {
    type: CLEAR_SPAMMING,
  }
}

// TIMEOUT REQUEST
export const setIsTimeOut = (value: boolean) => {
  return {
    type: SET_IS_TIME_OUT,
    payload: value
  }
}