export interface PrincipalAccountInterface {
  principalName: string
  principalKey: string
  agencyName: string
  principalAddressLine1: string
  recordsCount: number
  principalAccountId: string
}

export interface PrincipalAccountDataInterface {
  countPrincipal: number
  principalAccountPhoneNumber: string
  principalAccountContactName: string
  principalAccountBusinessEmail: string
  principalAccountAddressLine1: string
  principalAccountName: string
  principalAccountId: string
}

export interface optionHover {
  principalId: string | number | null
  status: boolean | null
}

export const stylePrincipalKey = {
  display: '-webkit-box',
  WebkitLineClamp: '1',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
} as object
