import React from 'react'
import ReactLoading from 'react-loading'

interface LoadingProps {
  className?: string
  height?: number
  width?: number
}
const MiniLoading: React.FC<LoadingProps> = ({
  className = 'absolute top-[50%] left-[50%]',
  height = 15,
  width = 15,
}) => {
  return (
    <div className={className}>
      <ReactLoading
        className="mx-auto mt-[-10px]"
        type="spin"
        color="#A989D8"
        height={height}
        width={width}
      />
    </div>
  )
}

export default MiniLoading
