import React, { FC } from 'react'
import iconTopArrow from '../../assets/images/icon-top-arrow.svg'

type Props = {
  handleScrollToTop?: () => void
}

export const UpToTop: FC<Props> = ({ handleScrollToTop = () => null }) => {
  const handleUpToTop = () => {
    handleScrollToTop()
  }

  return (
    <div className="flex justify-between sticky mb-4 mr-20 md:mr-10">
      <div />
      <div
        className="w-[157px] h-10 flex justify-between px-3 border-2 border-violet-700 text-violet-800 ml-3 bg-neutral-8 hover:bg-primary-shade4 rounded-[20px] cursor-pointer"
        onClick={handleUpToTop}
      >
        <img src={iconTopArrow} alt="iconTopArrow" height={22} width={22} />
        <p className="text-body1 text-primary-1 font-semibold mt-[6px]">Back to top</p>
      </div>
    </div>
  )
}

export default UpToTop
