import React, { FC } from 'react'

export type ToggleProps = {
  handleToggle: () => void
  value: boolean
}

const CheckBoxBase: FC<ToggleProps> = ({ handleToggle = () => null, value = false }) => {
  const toggleClass = ' transform translate-x-5'
  return (
    <div
      className={`w-[50px] h-[30px] flex items-center rounded-2xl p-[2px] cursor-pointer ${
        value ? 'bg-indicator-green' : 'bg-indicator-red'
      }`}
      onClick={handleToggle}
    >
      {/* Switch */}
      <div
        className={`bg-neutral-8 w-[26px] h-[26px] rounded-2xl shadow-toggle
        transform duration-300 ease-in-out ${value ? toggleClass : null}`}
      ></div>
    </div>
  )
}
export default CheckBoxBase
