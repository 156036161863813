import React, { useEffect, useRef, useState } from 'react'
import Moment from 'react-moment'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import InfiniteScroll from 'react-infinite-scroll-component'

import {
  ActivitiesGroupModel,
  ActivitiesLogModel,
  CarrierGroupModel,
  JsonPacketGroupModel,
  RoleModel,
  SystemMonitoringFilterModel,
  SystemMonitoringHeaderModel,
  tableHeaderActivitiesMng,
} from './activitiesMngModels'
import IconHoverCopy from '../../../../../assets/images/icon-hover-copy.svg'
import iconFilter from '../../../../../assets/images/icon-filter.svg'
import iconFiltered from '../../../../../assets/images/icon-filtered.svg'
import { optionHover } from '../component/activitiesLogModel'
import { useSession } from '../../../../../helpers/checkSession'
import { LocationState } from '../../../../../constants/interface'
import { RootState } from '../../../../../store'
import { MESSAGES } from '../../../../../constants/messages'
import { FILTER_BY_GROUP, FILTER_BY_MULTI_COLUMN } from '../../../../../queries/adminPortal'
import {
  clearDateTimeFilterForAdmin,
  setActivitiesGroupTypeForAdmin,
  setCarrierForAdmin,
  setRequestJsonPacketForAdmin,
} from '../../../../../redux/actions/adminPortal'
import {
  onHandleCopyItem,
  onHandleFormatDate,
  onHandleSplitUserName,
} from '../../../../../helpers/functionUtils'
import { CODE_TIMEOUT } from '../../../../../constants/statusCode'
import { KIND, styleJsonPacket } from '../../../../../constants/common'
import Loading from '../../../../../components/loading'
import Filter, { handleGetListFilterForAdminType } from '../component/filterBase'
import DatePickerFilter from '../component/dateTimePicker'

const classFilterResponsive = 'md:relative md:right-[-5px] md:ml-[-12px]'

const ActivitiesManagementAdminTable = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const locationState: LocationState = state as object
  const { timeOutSession, handleErrorSession } = useSession()

  const dispatchForAdmin = useDispatch()
  const carrierGroupTypeForAdmin = useSelector(
    (state: RootState) => state?.adminPortalReducer?.carrierGroupType,
  )
  const activitiesGroupTypeForAdmin = useSelector(
    (state: RootState) => state?.adminPortalReducer?.activitiesGroupType,
  )
  const requestJsonGroupPacketForAdmin = useSelector(
    (state: RootState) => state?.adminPortalReducer.requestJsonPacket,
  )

  const requestFromDateForAdmin = useSelector(
    (state: RootState) => state?.adminPortalReducer?.fromDate,
  )
  const requestToDateForAdmin = useSelector((state: RootState) => state?.adminPortalReducer?.toDate)

  const [initialSkipForAdmin] = useState<number>(0)
  const [listActivitiesForAdmin, setListActivitiesForAdmin] = useState<ActivitiesLogModel[]>([])
  const [filterLoading, setFilterLoading] = useState<boolean>(false)

  const [idSelectForAdmin, setIdSelectForAdmin] = useState<string>('')
  const [isOpenFilterForAdmin, setIsOpenFilterForAdmin] = useState<boolean>(false)
  const [listCarrierFilterForAdmin, setListCarrierFilterForAdmin] = useState<string[]>([])
  const [listActivitiesFilterForAdmin, setListActivitiesFilterForAdmin] = useState<string[]>([])
  const [listJsonPacketFilterForAdmin, setListJsonPacketFilterForAdmin] = useState<string[]>([])
  const [fromDateForAdmin, setFromDateForAdmin] = useState<string | null>(requestFromDateForAdmin)
  const [toDateForAdmin, setToDateForAdmin] = useState<string | null>(requestToDateForAdmin)
  const [hoverForAdmin, setHoverForAdmin] = useState<optionHover>({
    activityId: null,
    status: false,
  })
  const [hasMoreForAdmin, setHasMoreForAdmin] = useState<boolean>(true)
  const [showCarrierForAdmin, setShowCarrierForAdmin] = useState<CarrierGroupModel[]>([])
  const [showActivitiesGroupForAdmin, setShowActivitiesGroupForAdmin] = useState<
    ActivitiesGroupModel[]
  >([])
  const [showJsonPacketGroupForAdmin, setShowJsonPacketGroupForAdmin] = useState<
    JsonPacketGroupModel[]
  >([])

  const [listCarrierFilterForAdminOriginal, setListCarrierFilterForAdminOriginal] = useState<
    string[]
  >([])
  const [listActivitiesFilterForAdminOriginal, setListActivitiesFilterForAdminOriginal] = useState<
    string[]
  >([])
  const [listJsonPacketFilterForAdminOriginal, setListJsonPacketFilterForAdminOriginal] = useState<
    string[]
  >([])

  // ref
  const btnRefForAdmin = useRef<HTMLDivElement>(null)
  const filterRefForAdmin = useRef<HTMLDivElement>(null)

  // get data activities log
  const locationStateData = {
    partyId: locationState?.partyId,
    kind: locationState?.kind,
    skip: initialSkipForAdmin,
    take: SystemMonitoringFilterModel.DEFAULT_OFFSET,
  }

  const filterByMulColumn = {
    skip: initialSkipForAdmin,
    take: SystemMonitoringFilterModel.DEFAULT_OFFSET,
    kind: KIND.CARRIERS,
  }
  const {
    loading: getActivitiesLogLoadingForAdmin,
    error: getActivitiesLogErrorForAdmin,
    data: getActivitiesDataForAdmin,
    fetchMore: fetchMoreForGetActivitiesForAdmin,
  } = useQuery(FILTER_BY_MULTI_COLUMN, {
    fetchPolicy: 'no-cache',
    variables: {
      data: locationState ? locationStateData : filterByMulColumn,
    },
  })

  // get data filter carrier
  const { error: getGroupCarrierTypeErrorForAdmin, data: getGroupCarrierTypeDataForAdmin } =
    useQuery(FILTER_BY_GROUP, {
      variables: {
        data: {
          columnName: SystemMonitoringFilterModel.TYPE_CARRIER,
          kind: KIND.CARRIERS,
        },
      },
    })

  // get data filter activities type
  const { error: getGroupActivitiesTypeErrorForAdmin, data: getGroupActivitiesTypeDataForAdmin } =
    useQuery(FILTER_BY_GROUP, {
      variables: {
        data: {
          columnName: SystemMonitoringFilterModel.TYPE_ACTIVITIES,
          kind: KIND.CARRIERS,
        },
      },
    })

  // get data filter Json Packet
  const { error: getGroupJsonPacketErrorForAdmin, data: getGroupJsonPacketDataForAdmin } = useQuery(
    FILTER_BY_GROUP,
    {
      variables: {
        data: {
          columnName: SystemMonitoringFilterModel.TYPE_REQUEST_JSON_PACKET,
          kind: KIND.CARRIERS,
        },
      },
    },
  )

  // set list default
  const setListFilterGroupOriginal = () => {
    setListCarrierFilterForAdmin(listCarrierFilterForAdminOriginal)
    setListActivitiesFilterForAdmin(listActivitiesFilterForAdminOriginal)
    setListJsonPacketFilterForAdmin(listJsonPacketFilterForAdminOriginal)
  }

  const onHandleShowIcon = (id: string, label: string) => {
    if (isOpenFilterForAdmin && id === idSelectForAdmin) {
      return iconFiltered
    }
    if (
      listCarrierFilterForAdmin?.length > 0 &&
      label === SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL
    ) {
      return iconFiltered
    }
    if (
      listActivitiesFilterForAdmin?.length > 0 &&
      label === SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL
    ) {
      return iconFiltered
    }
    if (
      listJsonPacketFilterForAdmin?.length > 0 &&
      label === SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL
    ) {
      return iconFiltered
    }
    if (
      (fromDateForAdmin !== null || toDateForAdmin !== null) &&
      label === SystemMonitoringHeaderModel.TYPE_DATE_TIME_LABEL
    ) {
      return iconFiltered
    }
    return iconFilter
  }

  const handleOpenFilterForAdmin = (id: string) => {
    setListFilterGroupOriginal()
    if (idSelectForAdmin === id) {
      setIdSelectForAdmin('')
      return
    }
    setIsOpenFilterForAdmin(true)
    setIdSelectForAdmin(id.toString())
  }

  const onHandleCheckedValueForAdmin = (value: any, identify: string) => {
    switch (identify) {
      case SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL:
        if (listCarrierFilterForAdmin.includes(value)) {
          return setListCarrierFilterForAdmin(
            listCarrierFilterForAdmin.filter((val: any) => val != value),
          )
        } else {
          return setListCarrierFilterForAdmin([...listCarrierFilterForAdmin, value])
        }
      case SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL:
        if (listActivitiesFilterForAdmin.includes(value)) {
          return setListActivitiesFilterForAdmin(
            listActivitiesFilterForAdmin.filter((val) => val != value),
          )
        } else {
          return setListActivitiesFilterForAdmin([...listActivitiesFilterForAdmin, value])
        }
      case SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL:
        if (listJsonPacketFilterForAdmin.includes(value)) {
          return setListJsonPacketFilterForAdmin(
            listJsonPacketFilterForAdmin.filter((val) => val != value),
          )
        } else {
          return setListJsonPacketFilterForAdmin([...listJsonPacketFilterForAdmin, value])
        }
      default:
    }
  }

  // handle change input
  const onInputHandler = (callBackValue: string, identify: string) => {
    if (identify !== null) {
      switch (identify) {
        case SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL:
          if (carrierGroupTypeForAdmin !== null && carrierGroupTypeForAdmin.length > 0) {
            setShowCarrierForAdmin(
              carrierGroupTypeForAdmin.filter((item) => {
                return item?.key?.toLowerCase()?.includes(callBackValue.toLowerCase())
              }),
            )
          }
          break
        case SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL:
          if (activitiesGroupTypeForAdmin !== null && activitiesGroupTypeForAdmin.length > 0) {
            setShowActivitiesGroupForAdmin(
              activitiesGroupTypeForAdmin.filter((item) => {
                return item?.key?.toLowerCase()?.includes(callBackValue.toLowerCase())
              }),
            )
          }
          break
        case SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL:
          if (
            requestJsonGroupPacketForAdmin !== null &&
            requestJsonGroupPacketForAdmin.length > 0
          ) {
            setShowJsonPacketGroupForAdmin(
              requestJsonGroupPacketForAdmin.filter((item) => {
                return item?.key?.toLowerCase()?.includes(callBackValue.toLowerCase())
              }),
            )
          }
          break
        default:
      }
    }
  }

  // fetch more data
  const fetchMoreDataForAdmin = async () => {
    const activitiesData = {
      skip: listActivitiesForAdmin?.length,
      take: SystemMonitoringFilterModel.DEFAULT_OFFSET,
      partyName:
        listCarrierFilterForAdmin !== null && listCarrierFilterForAdmin?.length > 0
          ? listCarrierFilterForAdmin
          : null,
      activityType:
        listActivitiesFilterForAdmin !== null && listActivitiesFilterForAdmin?.length > 0
          ? listActivitiesFilterForAdmin
          : null,
      jsonPacket:
        listJsonPacketFilterForAdmin !== null && listJsonPacketFilterForAdmin?.length > 0
          ? listJsonPacketFilterForAdmin
          : null,
      fromDate: fromDateForAdmin !== null ? fromDateForAdmin : null,
      toDate: toDateForAdmin !== null ? toDateForAdmin : null,
      kind: KIND.CARRIERS,
    }
    try {
      const response = await fetchMoreForGetActivitiesForAdmin({
        variables: {
          data: locationState
            ? { ...activitiesData, partyId: locationState?.partyId, kind: locationState?.kind }
            : activitiesData,
        },
      })
      if (!getActivitiesLogLoadingForAdmin && !getActivitiesLogErrorForAdmin) {
        const data = response.data.filterByMultiColumnByAdmin
        if (!data?.length || data?.length == undefined) {
          setHasMoreForAdmin(false)
          return
        }
        const dataState = [...listActivitiesForAdmin, ...data]

        setTimeout(() => {
          setListActivitiesForAdmin(dataState)
        }, 100)
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_SHOW_ACTIVITIES_LOG)
    }
  }

  const onHandleCopyJsonPacketByCarrier = async (jsonPacket: string) => {
    onHandleCopyItem(jsonPacket, MESSAGES.INFO.I_COPIED_JSON_PACKET, MESSAGES.ERROR.E_COPY_FAILED)
  }
  const onHandleCopyJsonResponseByCarrier = async (jsonResponse: string) => {
    onHandleCopyItem(jsonResponse, MESSAGES.INFO.I_COPIED_JSON_RESPONSE, MESSAGES.ERROR.E_COPY_FAILED)
  }

  // handle check list filter
  const handleGetListFilterForAdmin = (field: string) => {
    switch (field) {
      case SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL:
        return new Object({
          group: showCarrierForAdmin,
          identify: SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL,
        })
      case SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL:
        return new Object({
          group: showActivitiesGroupForAdmin,
          identify: SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL,
        })
      case SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL:
        return new Object({
          group: showJsonPacketGroupForAdmin,
          identify: SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL,
        })
      default:
    }
  }

  const handleNavigateToDetail = (organizationName: string, partyId: string, kind: string) => {
    navigate('/admin-portal/detail', {
      state: {
        carrierName: organizationName,
        partyId: partyId,
        kind: kind,
      },
    })
  }

  // Reset group type, set value from store reducer
  const resetGroupTypeForAdmin = () => {
    setShowCarrierForAdmin(carrierGroupTypeForAdmin)
    setShowActivitiesGroupForAdmin(activitiesGroupTypeForAdmin)
    setShowJsonPacketGroupForAdmin(requestJsonGroupPacketForAdmin)
  }

  // get list data for filter
  const handleListActivitiesTypeForAdmin = (field: string) => {
    switch (field) {
      case SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL:
        return new Object({
          listChecked: listCarrierFilterForAdmin,
          identify: SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL,
        })
      case SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL:
        return new Object({
          listChecked: listActivitiesFilterForAdmin,
          identify: SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL,
        })
      case SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL:
        return new Object({
          listChecked: listJsonPacketFilterForAdmin,
          identify: SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL,
        })
      default:
        break
    }
  }

  // handle submit filter
  const onHandleApplyFilterForAdmin = async () => {
    setIsOpenFilterForAdmin(false)
    const fromDateRequest = onHandleFormatDate(requestFromDateForAdmin) as string
    const toDateRequest = onHandleFormatDate(requestToDateForAdmin) as string

    setFromDateForAdmin(fromDateRequest ? fromDateRequest : null)
    setToDateForAdmin(toDateRequest ? toDateRequest : null)
    setFilterLoading(true)

    const getActivitiesData = {
      skip: initialSkipForAdmin,
      take: SystemMonitoringFilterModel.DEFAULT_OFFSET,
      partyName:
        listCarrierFilterForAdmin !== null && listCarrierFilterForAdmin.length > 0
          ? listCarrierFilterForAdmin
          : null,
      activityType:
        listActivitiesFilterForAdmin !== null && listActivitiesFilterForAdmin.length > 0
          ? listActivitiesFilterForAdmin
          : null,
      jsonPacket:
        listJsonPacketFilterForAdmin !== null && listJsonPacketFilterForAdmin.length > 0
          ? listJsonPacketFilterForAdmin
          : null,
      fromDate: fromDateRequest !== null && fromDateRequest !== undefined ? fromDateRequest : null,
      toDate: toDateRequest !== null && toDateRequest !== undefined ? toDateRequest : null,
      kind: KIND.CARRIERS,
    }
    try {
      const request = await fetchMoreForGetActivitiesForAdmin({
        variables: {
          data: locationState
            ? { ...getActivitiesData, partyId: locationState?.partyId, kind: locationState?.kind }
            : getActivitiesData,
        },
      })
      if (!getActivitiesLogLoadingForAdmin && !getActivitiesLogErrorForAdmin) {
        const data = request.data.filterByMultiColumnByAdmin
        setListCarrierFilterForAdminOriginal(listCarrierFilterForAdmin)
        setListActivitiesFilterForAdminOriginal(listActivitiesFilterForAdmin)
        setListJsonPacketFilterForAdminOriginal(listJsonPacketFilterForAdmin)

        if (!data.length || data === undefined) {
          setListActivitiesForAdmin([])
          setHasMoreForAdmin(true)
          return
        }
        setTimeout(() => {
          setListActivitiesForAdmin(data)
        }, 500)
      }
      setFilterLoading(false)
    } catch (error) {
      setFilterLoading(false)
      handleErrorSession(error, MESSAGES.ERROR.E_SHOW_ACTIVITIES_LOG)
    } finally {
      setFilterLoading(false)
      setIsOpenFilterForAdmin(false)
      setIdSelectForAdmin('')
      resetGroupTypeForAdmin()
    }
  }

  // handle cancel filter
  const onHandleClickCancelForAdmin = () => {
    setListFilterGroupOriginal()
    setIsOpenFilterForAdmin(false)
    setIdSelectForAdmin('')
    resetGroupTypeForAdmin()
  }

  // handle click outside
  useEffect(() => {
    const handleOutsideClickForAdmin = (e: any) => {
      if (
        filterRefForAdmin &&
        !filterRefForAdmin.current?.contains(e.target) &&
        e.target.id !== 'filter-ref'
      ) {
        setIsOpenFilterForAdmin(false)
        setIdSelectForAdmin('')
        setListFilterGroupOriginal()
      }
    }
    document.addEventListener('mousedown', handleOutsideClickForAdmin)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClickForAdmin)
    }
  }, [isOpenFilterForAdmin])

  // Listening for update fromDate or toDate to filter
  useEffect(() => {
    if (requestFromDateForAdmin || requestToDateForAdmin) {
      onHandleApplyFilterForAdmin()
    }
  }, [requestFromDateForAdmin, requestToDateForAdmin])

  // set data for list activities log
  useEffect(() => {
    setListActivitiesForAdmin(getActivitiesDataForAdmin?.filterByMultiColumnByAdmin)
  }, [getActivitiesDataForAdmin])

  useEffect(() => {
    setShowCarrierForAdmin(getGroupCarrierTypeDataForAdmin?.filterByGroupByAdmin)
    setShowActivitiesGroupForAdmin(getGroupActivitiesTypeDataForAdmin?.filterByGroupByAdmin)
    setShowJsonPacketGroupForAdmin(getGroupJsonPacketDataForAdmin?.filterByGroupByAdmin)
    dispatchForAdmin(setCarrierForAdmin(getGroupCarrierTypeDataForAdmin?.filterByGroupByAdmin))
    dispatchForAdmin(
      setActivitiesGroupTypeForAdmin(getGroupActivitiesTypeDataForAdmin?.filterByGroupByAdmin),
    )
    dispatchForAdmin(
      setRequestJsonPacketForAdmin(getGroupJsonPacketDataForAdmin?.filterByGroupByAdmin),
    )
  }, [
    getGroupCarrierTypeDataForAdmin,
    getGroupActivitiesTypeDataForAdmin,
    getGroupJsonPacketDataForAdmin,
  ])

  // clear store
  useEffect(() => {
    dispatchForAdmin(clearDateTimeFilterForAdmin())
    setFromDateForAdmin(null)
    setToDateForAdmin(null)
  }, [])

  // handle session timeout
  useEffect(() => {
    if (
      getActivitiesLogErrorForAdmin?.graphQLErrors[0]?.extensions?.code ||
      getGroupJsonPacketErrorForAdmin?.graphQLErrors[0]?.extensions?.code ||
      getGroupActivitiesTypeErrorForAdmin?.graphQLErrors[0]?.extensions?.code ||
      getGroupCarrierTypeErrorForAdmin?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT
    ) {
      timeOutSession()
    }
  }, [
    getActivitiesLogErrorForAdmin,
    getGroupCarrierTypeErrorForAdmin,
    getGroupActivitiesTypeErrorForAdmin,
    getGroupJsonPacketErrorForAdmin,
  ])

  // handle responsive for request id json header at 1024px and 768px
  const handleResponsiveForRequestIDJsonDevAdmin = (label: string) => {
    if (locationState && label === SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL) {
      return 'md:!mt-0 lg:mt-[-24px]'
    }
  }

  return (
    <div>
      <div className="relative overflow-auto border rounded-xl">
        <table className="w-full text-left">
          <thead className="bg-primary-shade4 text-body1 text-neutral-2 md:pr-4">
            <tr>
              {tableHeaderActivitiesMng
                .slice(!locationState ? 0 : 1, tableHeaderActivitiesMng.length)
                .map((item, idx) => (
                  <th key={idx} className={`font-semibold py-5 pl-6 ${item.styles}`}>
                    <div className={`flex ${handleResponsiveForRequestIDJsonDevAdmin(item.label)}`}>
                      <span className={`${item.isOneLineRes && 'lg:mt-[-3.5px]'}`}>
                        {item.name}
                      </span>
                      <div
                        ref={btnRefForAdmin}
                        className={`${item.isOneLineRes && 'lg:mt-[-3.5px]'}`}
                      >
                        {item.isFilter && (
                          <div
                            className={`w-5 h-5 ${classFilterResponsive}`}
                            onClick={() => {
                              handleOpenFilterForAdmin(idx.toString())
                            }}
                          >
                            <img
                              id="filter-ref"
                              src={onHandleShowIcon(idx.toString(), item.label)}
                              alt="Icon-Filter"
                              className="cursor-pointer mt-0.5 ml-2"
                            />
                          </div>
                        )}
                        {item.isFilter &&
                        item.label !== SystemMonitoringHeaderModel.TYPE_DATE_TIME_LABEL ? (
                          <Filter
                            isOpenFilter={isOpenFilterForAdmin}
                            id={idx.toString()}
                            idSelected={idSelectForAdmin}
                            objectGroupType={
                              handleGetListFilterForAdmin(
                                item.label,
                              ) as handleGetListFilterForAdminType
                            }
                            handleClickCancel={onHandleClickCancelForAdmin}
                            onRef={filterRefForAdmin}
                            inputHandler={onInputHandler}
                            handCheckedValue={onHandleCheckedValueForAdmin}
                            handleFilter={onHandleApplyFilterForAdmin}
                            listCheckedInGroupType={handleListActivitiesTypeForAdmin(item.label)}
                          />
                        ) : (
                          <DatePickerFilter
                            isOpenFilter={isOpenFilterForAdmin}
                            id={idx.toString()}
                            idSelected={idSelectForAdmin}
                            onRef={filterRefForAdmin}
                            handleClickCancel={onHandleClickCancelForAdmin}
                            identifier={RoleModel.ADMIN}
                          />
                        )}
                      </div>
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
        </table>
        {filterLoading || getActivitiesLogLoadingForAdmin ? (
          <Loading className="relative py-6" height={30} width={30} />
        ) : (
          Boolean(listActivitiesForAdmin !== null && listActivitiesForAdmin?.length > 0) && (
            <InfiniteScroll
              dataLength={listActivitiesForAdmin?.length}
              next={fetchMoreDataForAdmin}
              hasMore={hasMoreForAdmin}
              height={'max-content'}
              style={{ maxHeight: 980 }}
              loader={''}
              endMessage={''}
              className="cus-scrollbar"
            >
              {listActivitiesForAdmin?.map((itemCarrier: ActivitiesLogModel, idx: number) => (
                <table
                  key={idx}
                  onClick={() =>
                    handleNavigateToDetail(
                      itemCarrier?.organizationName,
                      itemCarrier?.partyId,
                      itemCarrier?.kind,
                    )
                  }
                  className="cursor-pointer"
                >
                  <tbody>
                    <tr
                      className="h-[72px] w-full hover:bg-neutral-7 pt-5"
                      onMouseEnter={() => {
                        setHoverForAdmin({
                          activityId: itemCarrier?.activityId,
                          status: true,
                        })
                      }}
                      onMouseLeave={() => {
                        setHoverForAdmin({
                          activityId: null,
                          status: false,
                        })
                      }}
                    >
                      {!locationState && (
                        <td className="w-[15%] py-5 break-all pl-6 md:pl-1 align-top">
                          <span>{itemCarrier?.organizationName}</span>
                        </td>
                      )}
                      <td
                        className={`w-[15%] py-5 break-all pl-6 md:pl-1 align-top ${
                          locationState && 'md:pl-6'
                        }`}
                      >
                        <span>{itemCarrier?.activityType}</span>
                      </td>
                      <td
                        className={`w-[13%] py-5 break-all pl-6 md:pl-3 align-top ${
                          locationState && 'md:pl-[20px]'
                        }`}
                      >
                        <span>
                          {
                            <Moment format="yyyy-MM-DD HH:mm:ss.SSS">
                              {itemCarrier.createdAt ?? ''}
                            </Moment>
                          }
                        </span>
                      </td>
                      <td className="w-[22%] lg:pl-8 pl-1 md:w-[19%] align-top">
                        <div className="relative w-full break-all ">
                          {hoverForAdmin?.status &&
                          itemCarrier.activityId === hoverForAdmin.activityId &&
                          itemCarrier?.jsonPacket ? (
                            <div className="h-[86px]">
                              <span
                                style={{ ...styleJsonPacket }}
                                className={`break-all lg:pl-2 pt-[19px] pl-6`}
                              >
                                {itemCarrier?.jsonPacket}
                              </span>
                              <button
                                title="Copy this JSON Packet"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  onHandleCopyJsonPacketByCarrier(itemCarrier.jsonPacket)
                                }}
                              >
                                <img
                                  src={IconHoverCopy}
                                  alt="Icon-Hover-Copy"
                                  className="absolute top-[24px] right-0"
                                />
                              </button>
                            </div>
                          ) : (
                            <span
                              style={{ ...styleJsonPacket }}
                              className={`break-all lg:pl-2 pt-[19px] pl-6`}
                            >
                              {itemCarrier?.jsonPacket}
                            </span>
                          )}
                        </div>
                      </td>
                      <td
                        className={`w-[15%] pr-2 py-5  break-all align-top ${
                          locationState ? 'pl-12' : 'pl-8'
                        }`}
                      >
                        <span>{onHandleSplitUserName(itemCarrier?.userName as string)}</span>
                      </td>
                      <td className={`w-[22%] lg:pl-8 md:w-[19%] align-top ${
                          locationState ? 'pl-6' : 'pl-1'}`}
                      >
                        <div className="relative w-full break-all ">
                          {hoverForAdmin?.status &&
                            itemCarrier.activityId === hoverForAdmin.activityId &&
                            itemCarrier?.statusResponse ? (
                              <div className="h-[86px]">
                                <span
                                  style={{ ...styleJsonPacket }}
                                  className={`break-all lg:pl-2 pt-[19px]`}
                                >
                                  {itemCarrier?.statusResponse}
                                </span>
                                <button
                                  title="Copy this JSON Response"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    onHandleCopyJsonResponseByCarrier(itemCarrier.statusResponse)
                                  }}
                                >
                                  <img
                                    src={IconHoverCopy}
                                    alt="Icon-Hover-Copy"
                                    className="absolute top-[24px] right-0"
                                  />
                                </button>
                              </div>
                            ) : (
                              <span
                                style={{ ...styleJsonPacket }}
                                className={`break-all lg:pl-2 pt-[19px] pr-6`}
                              >
                                {itemCarrier?.statusResponse} 
                              </span>
                            )}
                          </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </InfiniteScroll>
          )
        )}
        {!listActivitiesForAdmin?.length && !getActivitiesLogLoadingForAdmin && (
          <p className="py-6 text-center text-body1 text-neutral-4">No data available.</p>
        )}
      </div>
    </div>
  )
}

export default ActivitiesManagementAdminTable
