import React, { FC } from 'react'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

type Props = {
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  bgDisabled?: string
  bgHover?: string
}

const ButtonBase: FC<Props> = ({
  children,
  bgDisabled = 'bg-violet-700',
  className = '',
  type = 'button',
  onClick,
  disabled = false,
}) => {
  const btnClasses = classNames(
    twMerge(
      `justify-between px-6 bg-violet-800 text-base text-white-50 ${
        !disabled && 'hover:bg-violet-900'
      } ${className}`,
    ),
    `items-center rounded-xl cursor-pointer font-semibold h-[52px] transition-all ease-in-out duration-150`,
    { [bgDisabled]: disabled, ['cursor-not-allowed']: disabled },
  )
  return (
    <button type={type} onClick={onClick} disabled={disabled} className={btnClasses}>
      {children}
    </button>
  )
}

export default ButtonBase
