import React, { FC } from 'react'
import IconNotFound from '../../assets/images/icon-not-found.svg'

type Props = {
  children?: React.ReactNode
}

const NoResultFound: FC<Props> = ({ children }) => {
  return (
    <div className="text-center">
      <img src={IconNotFound} alt="IconNotFound" className="m-auto my-12" />
      <p className="text-body1 font-semibold mb-3">Sorry, No result found!</p>
      {children}
    </div>
  )
}

export default NoResultFound
