import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import ButtonBase from '../../../components/partials/button'
import BaseAutocompletedInput from '../../admin-portal/my-agencies-list/component/autocompletedInputBase'

import IconSearch from '../../../assets/images/icon-search.svg'

export const SearchAMS = () => {
  const searchBoxRef = useRef<HTMLDivElement>(null)
  const [isFocus, setIsFocus] = useState<boolean>(false)
  const [isDisableSearchDetailAMS, setIsDisableSearchDetailAMS] = useState<boolean>(true)
  const [isValueInputAMSSelected, setIsValueInputAMSSelected] = useState<{
    agencyManagementProviderId: string
    agencyManagementProviderName: string
    partyId?: string
    kind?: string
  }>()

  const navigate = useNavigate()
  const handleNavigateToAgencyAMSDetail = (
    agencyManagementProviderName: string,
    partyId: string,
    kind: string,
  ) => {
    navigate('/admin-portal/detail', {
      state: {
        carrierName: agencyManagementProviderName,
        partyId: partyId,
        kind: kind,
      },
    })
  }

  const handleDisableBtnSubmit = () => {
    setIsDisableSearchDetailAMS(true)
  }

  useEffect(() => {
    if (isValueInputAMSSelected?.agencyManagementProviderId === '') {
      setIsDisableSearchDetailAMS(true)
    } else {
      setIsDisableSearchDetailAMS(false)
    }
  }, [isValueInputAMSSelected])

  useEffect(() => {
    const onHandleClickOutSide = (e: MouseEvent) => {
      if (searchBoxRef && !searchBoxRef.current?.contains(e.target as HTMLDivElement)) {
        setIsFocus(false)
      }
    }
    document.addEventListener('click', onHandleClickOutSide)
    return () => {
      document.removeEventListener('click', onHandleClickOutSide)
    }
  }, [isFocus])

  return (
    <div className="flex justify-start w-[100%] pt-5 pb-10">
      <div
        className={classNames('flex w-full mr-3 h-[56px] rounded-xl border-[2px]', {
          'border-neutral-3': isFocus,
          'border-neutral-5': !isFocus,
        })}
        ref={searchBoxRef}
      >
        <div className="pt-[14px] pl-3">
          <img src={IconSearch} alt="" width={24} height={24} />
        </div>
        <BaseAutocompletedInput
          classInput="border-0 w-full"
          isDropdown={true}
          setIsFocus={setIsFocus}
          setIsValueInputAMSSelected={setIsValueInputAMSSelected}
          isSuggestAMSData={true}
          handleDisableBtnSubmit={handleDisableBtnSubmit}
          placeholderText="Search AMS"
        />
      </div>
      <div className="">
        <ButtonBase
          type="submit"
          className="w-[169px] h-[56px] text-center bg-primary-1 min-w-min"
          bgDisabled="bg-primary-shade3"
          onClick={() =>
            handleNavigateToAgencyAMSDetail(
              isValueInputAMSSelected?.agencyManagementProviderName as string,
              isValueInputAMSSelected?.partyId as string,
              isValueInputAMSSelected?.kind as string,
            )
          }
          disabled={isDisableSearchDetailAMS}
        >
          Go to this AMS
        </ButtonBase>
      </div>
    </div>
  )
}

export default SearchAMS
