import { RoleType } from './interface'

export const DEFAULT_LENGTH_JSON_RENDER = 200
export const ROLE: RoleType = {
  Admin: 'Admin',
  Developer: 'Developer',
  AccountManager: 'Account Manager',
  DeveloperAccountManager: 'Developer - Account Manager'
}

export const KIND = {
  CARRIERS: 'carriers',
  AGENCIES: 'agencies',
  ALL: 'all',
  ADMIN: 'admin',
}

export const ERROR_RES = {
  ENTITY_ALREADY: 'Entity already exists.',
}

export const styleJsonPacket = {
  display: '-webkit-box',
  WebkitLineClamp: '2',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
} as object
