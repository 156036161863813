export const tableHeaderActivitiesMngByAMS = {
  styles: 'w-[20%] lg:pt-0',
  name: 'Agency management provider',
  label: 'agency_management_provider',
  isFilter: false,
  isFilterDate: false,
  isOneLineRes: true,
}

export const tableHeaderActivitiesMng = [
  {
    styles: 'w-[15%] lg:pt-0',
    name: 'Carrier',
    label: 'carrier',
    isFilter: true,
    isFilterDate: false,
    isOneLineRes: true,
  },
  {
    styles: 'w-[15%]',
    name: 'Activities type',
    label: 'activities_type',
    isFilter: true,
    isFilterDate: false,
    isOneLineRes: false,
  },
  {
    styles: 'lg:pt-0 w-[13%]',
    name: 'Date/time',
    label: 'date_time',
    isFilter: true,
    isFilterDate: true,
    isOneLineRes: true,
  },
  {
    styles: 'w-[22%]',
    name: 'Request ID-JSON Packet',
    label: 'request_id',
    isFilter: true,
    isFilterDate: false,
    isOneLineRes: false,
  },
  {
    styles: 'w-[13%] lg:pt-0',
    name: 'User',
    label: 'user',
    isFilter: false,
    isFilterDate: false,
    isOneLineRes: true,
  },
  {
    styles: 'w-[22%]',
    name: 'Response',
    label: 'response',
    isFilter: false,
    isFilterDate: false,
    isOneLineRes: false,
  },
]

export const ActivitiesLogModel = {}

export interface ActivitiesLogModel {
  activityId: string
  activityType: string
  createdAt: string
  jsonPacket: string
  kind: string
  organizationName: string
  partyId: string
  userName: string
  statusResponse: string
}

export interface CarrierGroupModel {
  key: string
  partyId: string
}

export interface ActivitiesGroupModel {
  key?: string
  partyId?: string
}

export interface JsonPacketGroupModel {
  key: string
  partyId?: string
}

export const SystemMonitoringHeaderModel = {
  TYPE_CARRIER_LABEL: 'carrier',
  TYPE_AMS_LABEL: 'agency_management_provider',
  TYPE_ACTIVITIES_LABEL: 'activities_type',
  TYPE_DATE_TIME_LABEL: 'date_time',
  TYPE_JSON_PACKET_LABEL: 'request_id',
  TYPE_USER_LABEL: 'user',
  TYPE_RESPONSE_LABEL: 'response',
  TYPE_FROM_DATE: 'from',
  TYPE_TO_DATE: 'to',
}

export const SystemMonitoringFilterModel = {
  DEFAULT_OFFSET: 20,
  TYPE_ACTIVITIES: 'ActivityType',
  TYPE_REQUEST_JSON_PACKET: 'Json',
  TYPE_CARRIER: 'Carrier',
  TYPE_AMS: 'Agencies',
}

export const RoleModel = {
  ADMIN: 'ADMIN',
  DEVELOPER: 'DEVELOPER',
}
