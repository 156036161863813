import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@apollo/client'
import BaseDialog from '../../../components/dialog'
import iconTimes from '../../../assets/images/icon-times.svg'
import ButtonBase from '../../../components/partials/button'
import { customStyles } from '../../../constants/customStyles'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { notify } from '../../../components/toastify'
import { FormInput } from '../../../components/partials/formInput'
import { APPROVE_USER_REQUEST, GET_PARTY_NAME } from '../../../queries/adminPortal'
import { alphabetPattern, gSuitePattern } from '../../../utils/regex'
import DropdownBase from '../../../components/partials/dropDown'
import { useSession } from '../../../helpers/checkSession'
import { CODE_TIMEOUT } from '../../../constants/statusCode'
import { ERROR_RES, KIND } from '../../../constants/common'

export type RegistrationFormFields = {
  firstName: string
  lastName: string
  gsuiteUsername: string
  carrier: string
  organizationName: string
}

type Option = {
  id: number | string
  name: string
}

type Props = {
  modalIsOpen?: boolean
  handleCloseDialog?: (val: boolean) => void
  onHandleReFetchList?: () => void
  userInfo?: object | any
  carrier: Option | any
  organizationType?: any
}

const ApproveDialog: React.FunctionComponent<Props> = ({
  modalIsOpen,
  handleCloseDialog = () => null,
  onHandleReFetchList = () => null,
  userInfo,
  organizationType,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<RegistrationFormFields>()
  const { timeOutSession, handleErrorSession } = useSession()

  const { data, error } = useQuery(GET_PARTY_NAME, {
    fetchPolicy: 'no-cache',
    variables: {
      kind: 'carriers',
    },
  })
  const [party, setParty] = useState<Option | any>(userInfo?.organization)
  const [isRequired, setIsRequired] = useState(false)
  const [carrierList, setCarrierList] = useState<Option[]>(data?.getPartyName)

  let checkCarrier: boolean

  const [approveUserRequest, { error: approveUserRequestError }] = useMutation(APPROVE_USER_REQUEST)

  const onSubmit = handleSubmit(async (data) => {
    const variables = {
      data: {
        partyId: party?.id,
        userId: userInfo?.userId,
        firstName: data?.firstName.trim(),
        lastName: data?.lastName.trim(),
        email: userInfo?.email,
        gsuiteUsername: `${data?.gsuiteUsername.trim()}@getpropellerbonds.com`,
        businessEmail: userInfo?.businessSponsorEmail,
        kind: organizationType,
        organizationName: userInfo?.organization?.name,
      },
    }
    if (!checkCarrier) {
      try {
        const dataRes = await approveUserRequest({ variables: variables })
        if (dataRes) {
          notify(MESSAGES.SUCCESS.S_APPROVED_USER, STATUS.SUCCESS)
          reset()
          handleCloseDialog(false)
          onHandleReFetchList()
        }
      } catch (error) {
        // handleErrorSession(error, MESSAGES.ERROR.E_CREATE_ACCOUNT)
      }
    }
  })

  const handleTrimValues = () => {
    if (!party) {
      setIsRequired(true)
    }
    setValue('firstName', getValues('firstName')?.trim())
    setValue('lastName', getValues('lastName')?.trim())
    setValue('gsuiteUsername', getValues('gsuiteUsername')?.trim())
    setParty(party?.id)
  }

  const handleSubmitForm = async () => {
    await handleTrimValues()
    await onSubmit()
  }

  const handleCancel = () => {
    handleCloseDialog(false)
    reset()
    setParty(null)
  }

  const handleChange = (val: Option) => {
    setParty(val)
    setIsRequired(false)
  }

  useEffect(() => {
    setValue('firstName', userInfo?.firstName)
    setValue('lastName', userInfo?.lastName)
    setValue('gsuiteUsername', userInfo?.gsuiteUsername)
    setValue('organizationName', userInfo?.organizationName?.name)
    setParty(userInfo?.organization)
    setIsRequired(false)
  }, [userInfo])

  useEffect(() => {
    setCarrierList(data?.getPartyName)
    if (error?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT) {
      timeOutSession()
    }
  }, [data, error])

  useEffect(() => {
    if (approveUserRequestError?.message === ERROR_RES.ENTITY_ALREADY) {
      notify(MESSAGES.ERROR.E_USERNAME_EXISTED, STATUS.ERROR)
    } else if (approveUserRequestError?.message !== undefined) {
      handleErrorSession(error, MESSAGES.ERROR.E_TRY_AGAIN)
    }
  }, [approveUserRequestError])

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={customStyles}>
      <div className="w-[770px]">
        <div className="flex justify-between">
          <div className="absolute right-5 top-4">
            <img src={iconTimes} alt="" className="cursor-pointer" onClick={handleCancel} />
          </div>
        </div>
        <p className="text-headline5 mb-1 mt-2 ml-[6px]">Create a new user</p>
        <hr className="mt-3 mb-5 ml-2 w-[753px] border-1 border-neutral-5" />
        <form onSubmit={onSubmit}>
          <div className="grid grid-cols-2 gap-2">
            <div className="mt-2 ml-2">
              <p className="mb-2 text-hairline2">First name</p>
              <FormInput<RegistrationFormFields>
                id="firstName"
                type="text"
                name="firstName"
                label="First Name"
                placeholder="Enter user's first name"
                className="mb-2"
                inputClassName="w-[365px] mt-1"
                register={register}
                rules={{
                  required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                  pattern: alphabetPattern,
                  maxLength: 200,
                }}
                errors={errors}
              />
            </div>
            <div className="mt-2 ml-2">
              <p className="mb-2 text-hairline2">Last name</p>
              <FormInput<RegistrationFormFields>
                id="lastName"
                type="text"
                name="lastName"
                label="Last Name"
                placeholder="Enter user's last name"
                className="mb-2"
                inputClassName="w-[365px] mt-1"
                register={register}
                rules={{
                  required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                  pattern: alphabetPattern,
                  maxLength: 200,
                }}
                errors={errors}
              />
            </div>
            {organizationType === KIND.CARRIERS ? (
              <div className="mt-2 ml-2">
                <p className="mb-2 text-hairline2">Carrier</p>
                <div className="w-[365px]">
                  <DropdownBase
                    options={carrierList}
                    handleOptions={handleChange}
                    name="carrier"
                    initialValue={userInfo?.organization?.name}
                    placeHolder="Choose carrier"
                    isError={isRequired}
                  />
                </div>
                {isRequired && (
                  <p className="block mt-1 text-sm text-left text-red-900">
                    {MESSAGES.ERROR.E_REQUIRED_FIELD}
                  </p>
                )}
              </div>
            ) : (
              <>
                {organizationType === KIND.AGENCIES ? (
                  <div className="mt-2 ml-2">
                    <p className="mb-2 text-hairline2">Agency Management Provider</p>
                    <div className="w-[365px]">
                      <FormInput<RegistrationFormFields>
                        id="organizationName"
                        type="text"
                        name="organizationName"
                        label="organizationName"
                        defaultValue={userInfo?.organization?.name}
                        placeholder="Please fill in AMS name"
                        inputClassName="w-full mt-1"
                        register={register}
                        rules={{
                          required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                          pattern: alphabetPattern,
                          maxLength: 200,
                        }}
                        errors={errors}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="mt-2 ml-2">
                    <p className="mb-2 text-hairline2">Propeller</p>
                    <div className="w-[365px]">
                      <FormInput<RegistrationFormFields>
                        type="text"
                        name="carrier"
                        value={userInfo?.organization?.name}
                        inputClassName="w-full mt-1"
                        disable
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            <div />
            <div className="mt-4 ml-2">
              <p className="mb-2 text-hairline2">Gsuite username</p>
              <FormInput<RegistrationFormFields>
                id="gsuiteUsername"
                type="text"
                name="gsuiteUsername"
                label="Email Address"
                placeholder="Enter user’s Gsuite username"
                className="mb-2"
                inputClassName="w-[365px] mt-1"
                register={register}
                rules={{
                  required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                  pattern: gSuitePattern,
                  maxLength: 200,
                }}
                errors={errors}
              />
            </div>
            <p className="mt-[57px] ml-[-10px] text-body1">@getpropellerbonds.com</p>
          </div>
          <hr className="my-6 ml-2 w-[753px]" />
        </form>
        <div className="flex justify-between">
          <ButtonBase
            type="submit"
            className="w-[370px] mb-3 mt-2 ml-2 bg-primary-1 min-w-min text-center"
            onClick={handleSubmitForm}
          >
            <p className="text-hairline1">Create</p>
          </ButtonBase>
          <ButtonBase
            bgHover="bg-violet-600"
            className="w-[370px] mb-3 mt-2 mr-2 border-2 border-primary-1 text-primary-1 bg-white-50 hover:bg-violet-500"
            onClick={handleCancel}
          >
            <p className="text-hairline1">Cancel</p>
          </ButtonBase>
        </div>
      </div>
    </BaseDialog>
  )
}

export default ApproveDialog
