import { combineReducers } from 'redux'
import userReducers from './userReducers'
import developerPortalReducer from './devPortalReducer'
import adminPortalReducer from './adminPortalReducer'

const rootReducers = combineReducers({
  userReducers,
  developerPortalReducer,
  adminPortalReducer,
})

export default rootReducers
