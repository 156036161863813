import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { RootState } from '../store'
import { detectRole } from './roles'

/**
 * withPermission HOC
 * @method  withPermission
 * @param   {JSX.Element} [Wrapper]        - component
 * @param   {string}      [permission]     - component permission
 * @param   {boolean}     [shouldNavigate] - False: only hide this component, True: navigate to Home
 * @returns {JSX.Element}
 */

interface HOCProps {
  permission?: string
  shouldNavigate?: boolean
}
export const HOCWithPermission = (Wrapper: any) => {
  const userInfoState = useSelector((state: RootState) => state.userReducers?.userInfo)

  return ({ permission = '', shouldNavigate = false }: HOCProps) => {
    if (userInfoState?.role) {
      if (detectRole(permission, userInfoState?.role?.name)) {
        return <Wrapper />
      }
      return shouldNavigate ? <Navigate to="/" /> : null
    }
    return <Wrapper />
  }
}
