import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import { defaultCustomStyles } from '../../../constants/customStyles'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { notify } from '../../../components/toastify'
import { useSession } from '../../../helpers/checkSession'
import { CREATE_AGENCY_BY_ADMIN } from '../../../queries/adminPortal'

import BaseDialog from '../../../components/dialog'
import ButtonBase from '../../../components/partials/button'
import iconTimes from '../../../assets/images/icon-times.svg'
import BaseAutocompletedInput from '../my-agencies-list/component/autocompletedInputBase'

export type RegistrationFormFields = {
  agencyId: string
  agencyKey: string
  agencyName: string
}

type Props = {
  handleCloseDialog?: (val: boolean) => void
  modalIsOpen?: boolean
  agencyInfoByAdmin?: object | any
  handleRefetchData?: () => void
  partyId?: string | any
  kind?: string
}

const CreateAMSByAdmin: React.FunctionComponent<Props> = ({
  handleCloseDialog = () => null,
  modalIsOpen,
  agencyInfoByAdmin,
  handleRefetchData = () => null,
  partyId,
  kind,
}) => {
  const { reset, handleSubmit, getValues, setValue } = useForm<RegistrationFormFields>()
  const { handleErrorSession } = useSession()
  const [createAgencyByAdmin] = useMutation(CREATE_AGENCY_BY_ADMIN)
  const searchBoxRef = useRef<HTMLDivElement>(null)
  const [isFocus, setIsFocus] = useState<boolean>(false)
  const [isDisableAddAMS, setIsDisableAddAMS] = useState<boolean>(true)
  const [isValueInputAgencySelected, setIsValueInputAgencySelected] = useState<{
    agencyId: string
    agencyName: string
    agencyKey: string
  }>()

  const handleSubmitFormCreateAgencyByAdmin = handleSubmit(async () => {
    const variables = {
      data: {
        agencyId: isValueInputAgencySelected?.agencyId,
        kind: kind,
        partyId: partyId,
      },
    }
    try {
      const dataRes = await createAgencyByAdmin({ variables: variables })
      if (dataRes) {
        handleRefetchData()
        notify(
          `The ${isValueInputAgencySelected?.agencyName} agency has been added successfully from AMS!`,
          STATUS.SUCCESS,
        )
        handleCloseCreateAgencyByAdminDialog()
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_TRY_AGAIN)
    }
  })

  const handleTrimValuesCreateAgencyByAdmin = () => {
    setValue('agencyName', getValues('agencyName')?.trim())
  }

  const handleCreateAgencyByAdmin = async () => {
    await handleTrimValuesCreateAgencyByAdmin()
    await handleSubmitFormCreateAgencyByAdmin()
  }

  const handleCloseCreateAgencyByAdminDialog = () => {
    reset()
    setValue('agencyId', '')
    setValue('agencyName', '')
    handleCloseDialog(false)
  }

  const handleDisableBtnSubmit = () => {
    setIsDisableAddAMS(true)
  }

  useEffect(() => {
    const onHandleClickOutSide = (e: MouseEvent) => {
      if (searchBoxRef && !searchBoxRef.current?.contains(e.target as HTMLDivElement)) {
        setIsFocus(false)
      }
    }
    document.addEventListener('click', onHandleClickOutSide)
    return () => {
      document.removeEventListener('click', onHandleClickOutSide)
    }
  }, [isFocus])

  useEffect(() => {
    if (isValueInputAgencySelected?.agencyId === '') {
      setIsDisableAddAMS(true)
    } else {
      setIsDisableAddAMS(false)
    }
  }, [isValueInputAgencySelected])

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={defaultCustomStyles}>
      <div>
        <form onSubmit={handleCreateAgencyByAdmin}>
          <div className="flex justify-between">
            <div className="absolute right-4 top-4">
              <img
                src={iconTimes}
                alt=""
                className="cursor-pointer"
                onClick={() => handleCloseCreateAgencyByAdminDialog()}
              />
            </div>
          </div>
          <div className="mb-3 font-bold text-center text-headline5 text-neutral-1">Add Agency</div>
          <div className="flex mt-6 mb-3 text-hairline1 text-neutral-2">Agency name</div>
          <div
            className={classNames('flex w-full h-[56px] rounded-xl border-[2px]', {
              'border-neutral-3': isFocus,
              'border-neutral-5': !isFocus,
            })}
            ref={searchBoxRef}
          >
            <BaseAutocompletedInput
              classInput="border-0 w-full"
              isDropdown={true}
              setIsFocus={setIsFocus}
              dropdownValue={agencyInfoByAdmin}
              setIsValueInputSelected={setIsValueInputAgencySelected}
              handleDisableBtnSubmit={handleDisableBtnSubmit}
              placeholderText="Enter your Agency name"
            />
          </div>
        </form>
        <div className="flex pt-6">
          <div className="w-2/4 pr-[6px]">
            <ButtonBase
              type="submit"
              className="w-full border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4"
              onClick={() => handleCloseCreateAgencyByAdminDialog()}
              bgDisabled="bg-primary-shade3"
            >
              Cancel
            </ButtonBase>
          </div>
          <div className="w-2/4 pl-[6px]">
            <ButtonBase
              type="submit"
              className="w-full text-center bg-primary-1 min-w-min"
              bgDisabled="bg-primary-shade3"
              onClick={handleCreateAgencyByAdmin}
              disabled={isDisableAddAMS}
            >
              Add
            </ButtonBase>
          </div>
        </div>
      </div>
    </BaseDialog>
  )
}

export default CreateAMSByAdmin
