import storage from 'redux-persist/lib/storage'
import {
  ActivitiesGroupModel,
  JsonPacketGroupModel,
} from '../../pages/developer-portal/table/activities-log/component/activitiesLogModel'
import { persistReducer } from 'redux-persist'
import {
  ACTIVITIES_TYPE_GROUP,
  CLEAR_DATETIME_FILTER,
  REQUEST_FROM_DATE,
  REQUEST_JSON_PACKET,
  REQUEST_TO_DATE,
} from '../actions/actionTypes'

type activitiesState = {
  activitiesGroupType: ActivitiesGroupModel[]
  requestJsonPacket: JsonPacketGroupModel[]
  fromDate: object | any
  toDate: object | any
}

const initialState: activitiesState = {
  activitiesGroupType: [],
  requestJsonPacket: [],
  fromDate: {
    date: null,
    time: {
      hour: '00',
      minute: '00',
      second: '00',
      millisecond: '',
    },
  },
  toDate: {
    date: null,
    time: {
      hour: '00',
      minute: '00',
      second: '00',
      millisecond: '',
    },
  },
}

/**
 *
 * @param state
 * @param param1
 * @returns
 */
const developerPortalReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case ACTIVITIES_TYPE_GROUP:
      return {
        ...state,
        activitiesGroupType: payload,
      }
    case REQUEST_JSON_PACKET:
      return {
        ...state,
        requestJsonPacket: payload,
      }
    case REQUEST_FROM_DATE:
      return {
        ...state,
        fromDate: payload,
      }
    case REQUEST_TO_DATE:
      return {
        ...state,
        toDate: payload,
      }
    case CLEAR_DATETIME_FILTER:
      return {
        ...state,
        fromDate: null,
        toDate: null,
      }
    default:
      return state
  }
}

const persistConfig = {
  key: 'activitiesLogForDev',
  storage: storage,
}

export default persistReducer(persistConfig, developerPortalReducer)
