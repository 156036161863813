import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'

import ButtonBase from '../../../components/partials/button'
import BaseDialog from '../../../components/dialog'
import iconTimes from '../../../assets/images/icon-times.svg'
import { FormInput } from '../../../components/partials/formInput'
import { urlPattern } from '../../../utils/regex'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { notify } from '../../../components/toastify'
import { useSession } from '../../../helpers/checkSession'
import { customStyles } from '../../../constants/customStyles'
import { CREATE_CLIENT_CREDENTIAL_DEV } from '../../../queries/developerPortal'
import { CREATE_CLIENT_CREDENTIAL_ADMIN } from '../../../queries/adminPortal'
import { LocationState } from '../../../constants/interface'

export type CreateClientCredentialFormFields = {
  audience: string
  clientId: string
  secretKey: string
  Auth0Url: string
}

interface PropsAction {
  modalIsOpen?: boolean
  isAdmin?: boolean
  locationState?: LocationState
  webhookPasswordVal?: string
  hiddenBlock?: string
  handleEdit?: () => void
  handleCloseDialog?: (val: boolean) => void
  handleRefetchWhenAddOrDeleteAuth0?: () => void
  setModalOpenSwitchBasicAuthOrAuth0?: (val: boolean) => void
  setDataClientCredential?: (data: CreateClientCredentialFormFields) => void
}

const CreateClientCredential: React.FunctionComponent<PropsAction> = ({
  modalIsOpen,
  isAdmin,
  locationState,
  webhookPasswordVal,
  handleCloseDialog = () => null,
  handleRefetchWhenAddOrDeleteAuth0 = () => null,
  setModalOpenSwitchBasicAuthOrAuth0 = () => null,
  setDataClientCredential = () => null,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<CreateClientCredentialFormFields>()
  const { handleErrorSession } = useSession()
  const [createClientCredential] = useMutation(
    isAdmin ? CREATE_CLIENT_CREDENTIAL_ADMIN : CREATE_CLIENT_CREDENTIAL_DEV,
  )

  const onSubmit = handleSubmit(async (data) => {
    setDataClientCredential(data)
    if (data?.clientId && data?.secretKey && data?.audience && data?.Auth0Url) {
      if (webhookPasswordVal) {
        setModalOpenSwitchBasicAuthOrAuth0(true)
      } else {
        setModalOpenSwitchBasicAuthOrAuth0(false)
      }
      handleCloseDialog(false)
    }
  })

  const onCreateClientCredential = handleSubmit(async (data) => {
    const variablesDev = {
      data: {
        clientId: data?.clientId.trim(),
        secretKey: data?.secretKey.trim(),
        audience: data?.audience.trim(),
        Auth0Url: data?.Auth0Url.trim(),
      },
    }
    const variablesAdmin = {
      data: {
        clientId: data?.clientId.trim(),
        secretKey: data?.secretKey.trim(),
        audience: data?.audience.trim(),
        Auth0Url: data?.Auth0Url.trim(),
        kind: locationState?.kind,
        partyId: locationState?.partyId,
      },
    }
    try {
      const dataRes = await createClientCredential({
        variables: isAdmin ? variablesAdmin : variablesDev,
      })
      if (dataRes) {
        notify(MESSAGES.SUCCESS.S_CREATED_CLIENT_CREDENTIAL, STATUS.SUCCESS)
        handleCloseDialog(false)
        reset()
        handleRefetchWhenAddOrDeleteAuth0()
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_CREATED_CLIENT_CREDENTIAL)
    }
  })

  const handleTrimValues = () => {
    setValue('clientId', getValues('clientId').trim())
    setValue('secretKey', getValues('secretKey').trim())
    setValue('audience', getValues('audience').trim())
    setValue('Auth0Url', getValues('Auth0Url').trim())
  }

  const handleSave = async () => {
    await handleTrimValues()
    if (webhookPasswordVal) {
      await onSubmit()
    } else {
      await onCreateClientCredential()
    }
  }

  const handleCloseCreateClientDialog = () => {
    handleCloseDialog(false)
    reset()
  }

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={customStyles}>
      <div className="w-[572px] h-[520px] p-2.5 pt-0">
        <div className="flex justify-between">
          <div />
          <img
            src={iconTimes}
            alt=""
            className="cursor-pointer mr-[-12px]"
            onClick={handleCloseCreateClientDialog}
          />
        </div>
        <p className="text-center text-headline5 mb-3 mt-[-16px]">Create Client Credential</p>
        <div className="overflow-y-scroll cus-scrollbar mr-[-23px] h-[400px]">
          <form onSubmit={handleSave}>
            <div className="grid grid-cols-1 gap-2">
              <div className="mt-2">
                <p className="text-hairline1 mb-2 mt-1">Client ID</p>
                <FormInput<CreateClientCredentialFormFields>
                  id="clientId"
                  type="text"
                  name="clientId"
                  label="Client ID"
                  placeholder="Enter Client ID"
                  className="mb-1"
                  inputClassName="w-[552px]"
                  register={register}
                  rules={{
                    required: MESSAGES.ERROR.E_COMPULSORY,
                  }}
                  errors={errors}
                />
              </div>
              <div className="mt-3">
                <p className="text-hairline1 mb-2">Client Secret</p>
                <FormInput<CreateClientCredentialFormFields>
                  id="clientSecret"
                  type="password"
                  name="secretKey"
                  label="Client Secret"
                  placeholder="Enter Client Secret"
                  className="mb-1"
                  inputClassName="w-[552px]"
                  register={register}
                  rules={{
                    required: MESSAGES.ERROR.E_COMPULSORY,
                  }}
                  errors={errors}
                />
              </div>
              <div className="mt-3">
                <p className="text-hairline1 mb-2">URL Auth Token</p>
                <FormInput<CreateClientCredentialFormFields>
                  id="urlAuthToken"
                  type="text"
                  name="Auth0Url"
                  label="URL Auth Token"
                  placeholder="Enter URL Auth Token"
                  className="mb-2"
                  inputClassName="w-[552px]"
                  register={register}
                  rules={{
                    required: MESSAGES.ERROR.E_COMPULSORY,
                    pattern: urlPattern,
                  }}
                  errors={errors}
                />
              </div>
              <div className="mt-2">
                <p className="text-hairline1 mb-2">Audience</p>
                <FormInput<CreateClientCredentialFormFields>
                  id="audience"
                  type="text"
                  name="audience"
                  label="Audience"
                  placeholder="Enter Audience"
                  className="mb-2"
                  inputClassName="w-[552px]"
                  register={register}
                  rules={{
                    required: MESSAGES.ERROR.E_COMPULSORY,
                    pattern: urlPattern,
                  }}
                  errors={errors}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="flex w-[636px] pb-0">
          <ButtonBase
            type="submit"
            className="w-[270px] mt-3 mr-3 justify-around border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4"
            onClick={handleCloseCreateClientDialog}
            bgDisabled="bg-primary-shade3"
          >
            Cancel
          </ButtonBase>
          <ButtonBase
            type="submit"
            className="w-[270px] mt-3 bg-primary-1 min-w-min text-center"
            onClick={handleSave}
            bgDisabled="bg-primary-shade3"
          >
            Create
          </ButtonBase>
        </div>
      </div>
    </BaseDialog>
  )
}

export default CreateClientCredential
