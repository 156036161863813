import React, { useEffect, useRef, useState } from 'react'
import IconPen from '../../../../assets/images/icon-pen-bold.svg'
import IconMultipleUsers from '../../../../assets/images/icon-multiple-users.svg'
import CollapseItemForAgency from '../../../../components/partials/collapseItemForAgency'
import { useQuery } from '@apollo/client'
import { GET_AGENCIES_BY_AMS } from '../../../../queries/developerPortal'
import Loading from '../../../../components/loading'
import AddOrEditAgencyKeyDevelop from '../../dialog/addOrEditAgencyKey'
import { useWindowSize } from '../../../../helpers/useWindowSize'
import { screens } from '../../../../constants/screens'

export const AgenciesList = () => {
  const menuRef = useRef<HTMLDivElement>(null)
  const [showAction, setShowAction] = useState<any>({
    apiKeyId: null,
    status: false,
    lastItem: null,
  })
  const [listCollapsesOpen, setListCollapsesOpen] = useState<number[]>([])
  const [listSelectedCollapses, setListSelectedCollapses] = useState<number[]>([])
  const [isPopupAddOrEditAgencyKey, setIsPopupAddOrEditAgencyKey] = useState<boolean>(false)
  const [agencyDetail, setAgencyDetail] = useState<object | null>(null)
  const windowSize = useWindowSize()
  const responsive = Number(windowSize.width) <= screens.md

  //Get Agency List
  const {
    data: listAgencyByAMS,
    refetch: getAgencyListRefetch,
    loading: getAgenciesListLoading,
  } = useQuery(GET_AGENCIES_BY_AMS, {
    variables: {
      skip: 0,
      take: 50,
    },
  })
  const [isListAgency, setIsListAgency] = useState<any>([])

  useEffect(() => {
    const handleClickOutSide = (e: any) => {
      if (menuRef && !menuRef.current?.contains(e.target)) {
        setShowAction({ lastItem: showAction.apiKeyId, apiKeyId: null, status: false })
      }
    }
    document.addEventListener('mousedown', handleClickOutSide)
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide)
    }
  }, [showAction])

  const handleRefetchData = () => {
    setAgencyDetail(null)
    getAgencyListRefetch()
  }

  const clearAgencyDetail = () => {
    setAgencyDetail(null)
  }

  const handleOpenCollapse = (value: number) => {
    if (listCollapsesOpen.includes(value)) {
      setListCollapsesOpen(listCollapsesOpen.filter((val) => val != value))
      setListSelectedCollapses(listSelectedCollapses.filter((val) => val != value))
    } else {
      setListCollapsesOpen(listCollapsesOpen.concat(value))
      setListSelectedCollapses(listSelectedCollapses.concat(value))
      setAgencyDetail(null)
    }
  }

  const handleClosePopupAddOrEditAgencyKey = () => {
    setIsPopupAddOrEditAgencyKey(false)
  }

  useEffect(() => {
    setIsListAgency(listAgencyByAMS?.getAgenciesByAMS)
  }, [listAgencyByAMS])

  if (getAgenciesListLoading) return <Loading />

  return (
    <div>
      {isListAgency ? (
        <div>
          {isListAgency?.map((item: any, index: number) => (
            <div key={index}>
              <CollapseItemForAgency
                image={<img src={IconMultipleUsers} alt="" className="" />}
                title={item?.agency?.agencyName}
                information={
                  <div className={`${!responsive && 'flex'}`}>
                    <div className="flex pt-1 text-neutral-4">
                      <li className="pl-4 text-neutral-5" />
                      <div className="pr-2 font-medium text-body2 mt-[2px]">Agency Key:</div>
                      <div className="font-semibold text-body1 text-neutral-3">
                        <p className="overflow-hidden text-ellipsis whitespace-nowrap md:w-[140px] lg:max-w-[180px] ">
                          {item?.agency?.agencyKey}
                        </p>
                      </div>
                    </div>
                    <div className="flex pt-1 text-neutral-4">
                      <li className="pl-4 text-neutral-5" />
                      <div className="pr-2 font-medium text-body2 mt-[2px]">AMS Agency Key:</div>
                      {item?.agencyAmsKey === null ? (
                        <div className="mt-[2px] italic font-medium text-neutral-3 text-body2">
                          Not created
                        </div>
                      ) : (
                        <div className="max-w-[280px] lg:max-w-[140px] md:w-[100px] overflow-hidden font-semibold text-body1 text-neutral-3 text-ellipsis whitespace-nowrap">
                          {item?.agencyAmsKey}
                        </div>
                      )}
                    </div>
                  </div>
                }
                addAgencyKey={
                  <div
                    className="pt-1 pl-2 pr-3 border-2 border-none bg-neutral-7-header-footer hover:bg-neutral-7-header-footer"
                    onClick={(e) => {
                      setAgencyDetail(item)
                      setIsPopupAddOrEditAgencyKey(true)
                      e.stopPropagation()
                    }}
                  >
                    <img src={IconPen} alt="" />
                  </div>
                }
                id={index}
                handleOpenCollapse={() => handleOpenCollapse(index)}
                listCollapse={listCollapsesOpen}
                isSelectedCollapse={listSelectedCollapses.includes(index)}
                agencyId={item?.agency?.agencyId}
                agencyName={item?.agency?.agencyName}
              ></CollapseItemForAgency>
            </div>
          ))}
        </div>
      ) : (
        <div className="pt-5 pb-3 text-center text-body1 text-neutral-4">No data available.</div>
      )}
      <AddOrEditAgencyKeyDevelop
        modalIsOpen={isPopupAddOrEditAgencyKey}
        agencyInfo={agencyDetail}
        handleCloseDialog={handleClosePopupAddOrEditAgencyKey}
        handleRefetchData={handleRefetchData}
        clearAgencyDetail={clearAgencyDetail}
      ></AddOrEditAgencyKeyDevelop>
    </div>
  )
}

export default AgenciesList
