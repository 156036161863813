import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import BaseDialog from '../../../components/dialog'
import iconTimes from '../../../assets/images/icon-times.svg'
import ButtonBase from '../../../components/partials/button'
import { defaultCustomStyles } from '../../../constants/customStyles'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { nameKeyPattern } from '../../../utils/regex'
import { notify } from '../../../components/toastify'
import { UPDATE_AGENCY_KEY } from '../../../queries/developerPortal'
import { FormInputController } from '../../../components/partials/formInputController'

export type RegistrationFormFields = {
  agencyKey: string
}

type Props = {
  handleCloseDialog?: (val: boolean) => void
  modalIsOpen?: boolean
  handleEditAgencyKey?: () => void
  agencyInfo?: object | any
  handleRefetchData?: () => void
  clearAgencyDetail?: () => void
}

const AddOrEditAgencyKeyDevelop: React.FunctionComponent<Props> = ({
  handleCloseDialog = () => null,
  modalIsOpen,
  agencyInfo,
  handleRefetchData = () => null,
  clearAgencyDetail = () => null,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<RegistrationFormFields>()
  const [editAgencyKey, { error: errorAgencyByAMS }] = useMutation(UPDATE_AGENCY_KEY)
  const [changeAddOrEditAgencyKey, setChangeAddOrEditAgencyKey] = useState<boolean>(false)

  useEffect(() => {
    if (agencyInfo?.agencyAmsKey === null) {
      setChangeAddOrEditAgencyKey(true)
    } else {
      setChangeAddOrEditAgencyKey(false)
    }
  }, [agencyInfo])

  const handleSubmitFormAddOrEditAgency = handleSubmit(async (data) => {
    const variables = {
      data: {
        agencyAmsId: agencyInfo?.agencyAmsId,
        agencyAmsKey: data?.agencyKey,
      },
    }
    try {
      const dataRes = await editAgencyKey({ variables: variables })
      if (dataRes) {
        handleRefetchData()
        {
          changeAddOrEditAgencyKey
            ? notify(
                `The ${agencyInfo?.agency?.agencyName} agency key has been added successfully!`,
                STATUS.SUCCESS,
              )
            : notify(
                `The ${agencyInfo?.agency?.agencyName} agency key has been updated successfully!`,
                STATUS.SUCCESS,
              )
        }
        handleCloseDialog(false)
      }
    } catch (error) {
      //   notify(MESSAGES.ERROR.E_UPDATE_AGENCY_KEY, STATUS.ERROR)
    }
  })

  const handleTrimValuesEditAgencyKey = () => {
    setValue('agencyKey', getValues('agencyKey')?.trim())
  }

  const handleAddOrEditAgencyKey = async () => {
    await handleTrimValuesEditAgencyKey()
    await handleSubmitFormAddOrEditAgency()
  }

  const handleCloseEditAgencyKeyDialog = () => {
    clearAgencyDetail()
    reset()
    handleCloseDialog(false)
  }

  useEffect(() => {
    if (errorAgencyByAMS?.message === MESSAGES.ERROR.E_DUPLICATE_AGENCY_KEY) {
      notify(MESSAGES.ERROR.E_DUPLICATE_AGENCY_KEY, STATUS.ERROR)
    } else if (errorAgencyByAMS?.message !== undefined) {
      notify(MESSAGES.ERROR.E_UPDATE_AGENCY_KEY, STATUS.ERROR)
    }
  }, [errorAgencyByAMS])

  useEffect(() => {
    setValue('agencyKey', agencyInfo?.agencyAmsKey)
  }, [agencyInfo])

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={defaultCustomStyles}>
      <div>
        <form onSubmit={handleAddOrEditAgencyKey}>
          <div className="flex justify-between">
            <div className="absolute right-4 top-4">
              <img
                src={iconTimes}
                alt=""
                className="cursor-pointer"
                onClick={() => handleCloseEditAgencyKeyDialog()}
              />
            </div>
          </div>
          <p className="mb-3 text-center text-headline5 text-neutral-1">
            {changeAddOrEditAgencyKey ? 'Add AMS Agency Key' : 'Edit AMS Agency Key'}
          </p>
          <div className="flex mt-6 mb-3">
            <p className="pr-3 font-semibold text-body2 text-neutral-3">Agency:</p>
            <p className="-mt-[2px] font-semibold text-body1">{agencyInfo?.agency?.agencyName}</p>
          </div>
          <div className="flex mt-3 mb-3">
            <p className="text-hairline1 text-neutral-2">AMS Agency Key</p>
          </div>
          <FormInputController<RegistrationFormFields>
            id="agencyKey"
            type="text"
            name="agencyKey"
            label="agencyKey"
            placeholder="Enter your AMS Agency Key"
            defaultValue={agencyInfo?.agencyAmsKey}
            inputClassName="w-full mt-1"
            register={register}
            rules={{
              required: MESSAGES.ERROR.E_REQUIRED_FIELD,
              pattern: nameKeyPattern,
              maxLength: 200,
            }}
            errors={errors}
          />
        </form>
        <div className="flex pt-1">
          <div className="w-2/4 pr-3 mt-4">
            <ButtonBase
              type="submit"
              className="w-full border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4"
              onClick={() => handleCloseEditAgencyKeyDialog()}
              bgDisabled="bg-primary-shade3"
            >
              Cancel
            </ButtonBase>
          </div>
          <div className="w-2/4 pr-3 mt-4">
            <ButtonBase
              type="submit"
              className="w-full ml-3 text-center bg-primary-1 min-w-min"
              bgDisabled="bg-primary-shade3"
              onClick={handleAddOrEditAgencyKey}
            >
              {changeAddOrEditAgencyKey ? 'Add' : 'Change'}
            </ButtonBase>
          </div>
        </div>
      </div>
    </BaseDialog>
  )
}

export default AddOrEditAgencyKeyDevelop
