import React from 'react'
import { useMutation } from '@apollo/client'
import { defaultCustomStyles } from '../../../constants/customStyles'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { notify } from '../../../components/toastify'
import { useSession } from '../../../helpers/checkSession'
import { DELETE_AGENCY_BY_ADMIN } from '../../../queries/adminPortal'

import BaseDialog from '../../../components/dialog'
import ButtonBase from '../../../components/partials/button'
import iconTimes from '../../../assets/images/icon-times.svg'

type Props = {
  handleCloseDialog?: (val: boolean) => void
  modalIsOpen?: boolean
  agencyInfoByAdmin?: object | any
  handleRefetchData?: () => void
  partyId?: string | any
  kind?: string
}

const DeleteAMSByAdmin: React.FunctionComponent<Props> = ({
  handleCloseDialog = () => null,
  modalIsOpen,
  agencyInfoByAdmin,
  handleRefetchData = () => null,
  partyId,
  kind,
}) => {
  const { handleErrorSession } = useSession()
  const [deleteAgencyByAdmin] = useMutation(DELETE_AGENCY_BY_ADMIN)

  const handleSubmitDeleteAgencyByAdmin = async () => {
    const variables = {
      data: {
        agencyAmsId: agencyInfoByAdmin?.agencyAmsId,
        kind: kind,
        partyId: partyId,
      },
    }
    try {
      const dataRes = await deleteAgencyByAdmin({ variables: variables })
      if (dataRes) {
        handleRefetchData()
        notify(
          `The ${agencyInfoByAdmin?.agency?.agencyName} agency has been deleted successfully from AMS!`,
          STATUS.SUCCESS,
        )
        handleCloseDialog(false)
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_TRY_AGAIN)
    }
  }

  const handleDeleteAgencyByAdmin = async () => {
    await handleSubmitDeleteAgencyByAdmin()
  }

  const handleCloseDeleteAgencyByAdminDialog = () => {
    handleCloseDialog(false)
  }

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={defaultCustomStyles}>
      <div>
        <div className="flex justify-between">
          <div className="absolute right-4 top-4">
            <img
              src={iconTimes}
              alt=""
              className="cursor-pointer"
              onClick={() => handleCloseDeleteAgencyByAdminDialog()}
            />
          </div>
        </div>
        <div className="mb-3 font-bold text-center text-headline5 text-indicator-red">
          Delete Agency
        </div>
        <div className="mt-6 font-semibold text-center text-body1 text-indicator-red">
          Are you sure you want to delete Agency?
        </div>
        <div className="flex pt-6">
          <div className="w-2/4 pr-[6px]">
            <ButtonBase
              type="submit"
              className="w-full text-center bg-primary-1 min-w-min"
              bgDisabled="bg-primary-shade3"
              onClick={() => handleCloseDeleteAgencyByAdminDialog()}
            >
              Cancel
            </ButtonBase>
          </div>
          <div className="w-2/4 pl-[6px]">
            <ButtonBase
              type="submit"
              className="w-full border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4"
              onClick={handleDeleteAgencyByAdmin}
              bgDisabled="bg-primary-shade3"
            >
              Delete
            </ButtonBase>
          </div>
        </div>
      </div>
    </BaseDialog>
  )
}

export default DeleteAMSByAdmin
