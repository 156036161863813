export const tableHeaderUserList = [
  {
    id: 1,
    minW: 'min-w-[32px]',
    label: 'No.',
  },
  {
    id: 2,
    minW: 'w-[168px] min-w-[92px]',
    label: 'Organization name',
    isFilter: true,
  },
  {
    id: 3,
    minW: 'w-[180px] min-w-[82px]',
    label: 'Full name',
  },
  {
    id: 4,
    minW: 'w-[164px] min-w-[92px]',
    label: 'Username',
  },
  {
    id: 5,
    minW: 'w-[136px] min-w-[68px]',
    label: 'Phone number',
  },
  {
    id: 6,
    minW: 'w-[240px] min-w-[100px]',
    label: 'Business email',
  },
  {
    id: 7,
    minW: 'w-[96px] min-w-[82px]',
    label: 'Status',
  },
  {
    id: 8,
    minW: 'min-w-[24px]',
    label: '',
  },
]
