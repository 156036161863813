import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'
import classNames from 'classnames'
import { GET_AGENCIES_BY_ADMIN, SEARCH_AMS } from '../../../../queries/adminPortal'
import Loading from '../../../../components/loading'

import InputBase from '../../../../components/partials/inputBase'
import IconClear from '../../../../assets/images/ic-clear-input.svg'

interface Props {
  classInput?: string
  setIsFocus?: (focus: boolean) => void
  handleDisableBtnSubmit?: () => void
  setIsValueInputEmp?: (focus: boolean) => void
  setIsValueInputSelected?: (focus: {
    agencyId: string
    agencyName: string
    agencyKey: string
  }) => void
  setIsValueInputAMSSelected?: (focus: {
    agencyManagementProviderId: string
    agencyManagementProviderName: string
    partyId?: string
    kind?: string
  }) => void
  placeholderText?: string
  dropdownValue?: any
  isDropdown?: boolean
  checkValueInput?: (val: string) => void
  handleClearValue?: () => void
  handleClearValueSelected?: () => void
  isHandleSearch?: boolean
  isSuggestAMSData?: boolean
}

const BaseAutocompletedInput: React.FC<Props> = ({
  classInput = '',
  setIsFocus = () => false,
  setIsValueInputEmp = () => false,
  setIsValueInputSelected = () => {},
  setIsValueInputAMSSelected = () => {},
  isDropdown = false,
  dropdownValue,
  isSuggestAMSData,
  placeholderText,
  checkValueInput = () => null,
  handleClearValue = () => null,
  handleClearValueSelected = () => null,
  handleDisableBtnSubmit = () => null,
}) => {
  const [valueInputAgency, setValueInputAgency] = useState<any>('')
  const [isOpenDropDown, setIsOpenDropDown] = useState(false)
  const [isFocusInputAgency, setIsFocusInputAgency] = useState<boolean>(false)
  const [isOpenSuggestAgency, setIsOpenSuggestAgency] = useState<boolean>(false)
  const [suggestDataAgency, setSuggestDataAgency] = useState<any>(null)
  const suggestDataRef = useRef<HTMLDivElement>(null)
  const inputDataRef = useRef<HTMLInputElement>(null)

  const [
    handleGetSuggestValue,
    { data: getSuggestAgencyAMSByAdminData, loading: getSuggestAgencyAMSByAdminLoading },
  ] = useLazyQuery(GET_AGENCIES_BY_ADMIN, {
    fetchPolicy: 'no-cache',
  })

  const [handleGetSuggestAMS, { data: getSuggestAMSData, loading: getSuggestAMSLoading }] =
    useLazyQuery(SEARCH_AMS, {
      fetchPolicy: 'no-cache',
    })

  const handleShowValueSuggest = (value: any) => {
    handleGetSuggestValue({
      variables: {
        agencyName: value,
        skip: 0,
        take: 50,
      },
    })
  }

  const handleShowValueSuggestAMS = (value: any) => {
    handleGetSuggestAMS({
      variables: {
        agencyManagementProviderName: value,
      },
    })
  }

  const handleChangeInput = (value: string) => {
    handleDisableBtnSubmit()
    setValueInputAgency(value)
    if (value === '') {
      resetValueInput()
    }
    if (!value) {
      handleClearValue()
      handleClearValueSelected()
    }
    if (isDropdown) {
      if (isSuggestAMSData) {
        debounceSuggestionAMS(value)
      } else {
        debounceSuggestion(value)
      }
    }
    setIsValueInputEmp(true)
    checkValueInput(value)
  }

  const handleUpdateValueInput = (item: any) => {
    if (isSuggestAMSData) {
      setValueInputAgency(item?.agencyManagementProviderName)
    } else {
      setValueInputAgency(item?.agencyName)
    }

    if (isSuggestAMSData) {
      setIsValueInputAMSSelected({
        agencyManagementProviderId: item?.agencyManagementProviderId,
        agencyManagementProviderName: item?.agencyManagementProviderName,
        partyId: item?.partyId,
        kind: item?.kind,
      })
    } else {
      setIsValueInputSelected({
        agencyId: item?.agencyId,
        agencyName: item?.agencyName,
        agencyKey: item?.agencyKey,
      })
    }
    setIsOpenSuggestAgency(false)
    setIsFocusInputAgency(false)
    setIsOpenDropDown(false)
  }

  const debounceSuggestion = useCallback(
    debounce((nextValue) => handleShowValueSuggest(nextValue)),
    [],
  )

  const debounceSuggestionAMS = useCallback(
    debounce((nextValue) => handleShowValueSuggestAMS(nextValue)),
    [],
  )

  const handleFocusInput = () => {
    setIsFocus(true)
    setIsFocusInputAgency(true)
  }
  const handleBlurInput = () => {
    setIsFocus(false)
  }

  const resetValueInput = () => {
    setValueInputAgency('')
    if (isSuggestAMSData) {
      setIsValueInputAMSSelected({
        agencyManagementProviderId: '',
        agencyManagementProviderName: '',
      })
    } else {
      setIsValueInputSelected({ agencyId: '', agencyName: '', agencyKey: '' })
    }
    setIsFocusInputAgency(false)
  }

  useEffect(() => {
    if (!valueInputAgency.length && isFocusInputAgency) {
      setIsOpenSuggestAgency(false)
    }
    if (valueInputAgency.length && isFocusInputAgency) {
      setIsOpenSuggestAgency(true)
    }
  }, [valueInputAgency, isFocusInputAgency])

  useEffect(() => {
    resetValueInput()
  }, [dropdownValue])

  useEffect(() => {
    setSuggestDataAgency(getSuggestAgencyAMSByAdminData?.getAgenciesByAdmin)
  }, [getSuggestAgencyAMSByAdminData])

  useEffect(() => {
    setSuggestDataAgency(getSuggestAMSData?.searchAMS)
  }, [getSuggestAMSData])

  useEffect(() => {
    const onHandleClickOutSide = (e: any) => {
      if (
        suggestDataRef &&
        !suggestDataRef.current?.contains(e.target) &&
        inputDataRef &&
        !inputDataRef.current?.contains(e.target)
      ) {
        setIsOpenSuggestAgency(false)
        setIsOpenDropDown(false)
      }
    }

    document.addEventListener('click', onHandleClickOutSide)
    return () => {
      document.removeEventListener('click', onHandleClickOutSide)
    }
  }, [isOpenSuggestAgency])

  const openInput = (event: any) => {
    event.stopPropagation()
    setIsOpenDropDown(true)
    setIsFocus(true)
  }

  return (
    <div className="relative flex items-center w-full">
      <div ref={inputDataRef} className="flex w-full h-full" onClick={openInput}>
        {!isOpenDropDown && !valueInputAgency && (
          <div className="flex items-baseline h-full py-3 ml-3 text-neutral-5 text-body1-med whitespace-nowrap">
            {placeholderText}
          </div>
        )}
        {isOpenDropDown === false && valueInputAgency && (
          <div className="absolute leading-[30px] pl-4 pr-10 py-3 max-w-full h-full whitespace-nowrap overflow-hidden text-ellipsis">
            <span className="mr-3 font-bold text-neutral-1">{valueInputAgency}</span>
          </div>
        )}
        {isOpenDropDown === true && (
          <InputBase
            autoFocus={true}
            handleChange={handleChangeInput}
            value={valueInputAgency}
            className={classInput}
            onFocus={handleFocusInput}
            onBlur={handleBlurInput}
            placeholder={placeholderText}
          />
        )}
      </div>
      {valueInputAgency && (
        <button
          onClick={resetValueInput}
          className="absolute right-0 h-full p-3 pl-2 -translate-y-1/2 rounded-full top-1/2 bg-neutral-8"
        >
          <img src={IconClear} alt="icon-clear" width={24} height={24} />
        </button>
      )}
      <div
        className={classNames(
          { hidden: !isOpenSuggestAgency },
          'p-2 pr-1 text-body1 text-neutral-3 bg-neutral-8 border-neutral-7 rounded-xl shadow-dropdown absolute bottom-0 translate-y-[102%] w-full z-10',
        )}
        ref={suggestDataRef}
      >
        <ul className="max-h-[372px] overflow-auto small-scrollbar pr-1">
          {(getSuggestAgencyAMSByAdminLoading || getSuggestAMSLoading) && (
            <Loading className="relative p-[5px]" height={30} width={30} />
          )}
          {!suggestDataAgency?.length &&
            !getSuggestAgencyAMSByAdminLoading &&
            !getSuggestAMSLoading && <p className="p-2">No result found</p>}
          {suggestDataAgency?.length > 0 &&
            !getSuggestAgencyAMSByAdminLoading &&
            !getSuggestAMSLoading &&
            suggestDataAgency?.map((item: any, index: number) => (
              <li
                key={index}
                onClick={() => handleUpdateValueInput(item)}
                className="p-2 mb-1 rounded-lg last:mb-0 hover:bg-neutral-7"
              >
                <span>{item.agencyName || item.agencyManagementProviderName}</span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default BaseAutocompletedInput
