import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'

import { MESSAGES } from '../../../constants/messages'
import { onHandleCopyItem } from '../../../helpers/functionUtils'
import { tableHeader } from './data'
import { optionHover, stylePrincipalKey } from './models'
import IconHoverCopyDetail from '../../../assets/images/icon-hover-copy.svg'
import { RootState } from '../../../store'
import Loading from '../../../components/loading'
import { useSession } from '../../../helpers/checkSession'
import { GET_PRINCIPAL_ACCOUNT_DETAIL } from '../../../queries/adminPortal'
import { getPrincipalAccountDetail } from '../../../redux/actions/adminPortal'

interface Props {
  principalAccountData?: any
  principalAccountId?: string
  principalAccountLength?: number
}

const PrincipalAccountDetailTable: React.FC<Props> = ({
  principalAccountData,
  principalAccountId,
  principalAccountLength,
}) => {
  const { handleErrorSession } = useSession()
  const [skip, setSkip] = useState(0)

  const [requestGetPrincipalAccountDetailData] = useMutation(GET_PRINCIPAL_ACCOUNT_DETAIL, {
    fetchPolicy: 'no-cache',
  })
  const [hoverRow, setHoverRow] = useState<optionHover>({
    principalId: null,
    status: false,
  })
  const [isFetchMore, setIsFetchMore] = useState(false)
  const [principalData, setPrincipalData] = useState(principalAccountData)
  const principalAccountDetailData = useSelector(
    (state: RootState) => state?.adminPortalReducer?.principalAccountDetailData,
  )
  const dispatch = useDispatch()

  const handleLoadMore = async (skipItem: number) => {
    try {
      const res = await requestGetPrincipalAccountDetailData({
        variables: {
          data: {
            skip: skip + skipItem,
            take: 50,
            principalAccountId: principalAccountId,
          },
        },
      })
      if (res) {
        const newData = res?.data?.getPrincipalsInPrincipalAccountDetail
        setPrincipalData([...principalData, ...newData])
        dispatch(getPrincipalAccountDetail([...principalData, ...newData]))
      }
    } catch (error) {
      handleErrorSession(error, 'MESSAGES.ERROR.E_SHOW_ACTIVITIES_LOG')
    }
    await setSkip(skip + skipItem)
  }

  const onHandleCopyPrincipalKey = async (event: any, principalKey: string) => {
    onHandleCopyItem(
      principalKey,
      MESSAGES.INFO.I_COPIED_PRINCIPAL_KEY,
      MESSAGES.ERROR.E_COPY_FAILED,
    )
    event.preventDefault()
  }

  useEffect(() => {
    if ((principalData?.length as number) < (principalAccountLength as number)) {
      return setIsFetchMore(true)
    }
    return setIsFetchMore(false)
  }, [principalData])

  useEffect(() => {
    setPrincipalData(principalAccountData)
  }, [principalAccountData])

  return (
    <div className="mt-5">
      <div className="max-w-[1280px] min-w-[688px] top-[88px] border-2 border-neutral-6 bg-neutral-8 rounded-2xl z-10">
        <ul className="flex bg-primary-shade4 px-6 py-8 justify-between rounded-t-[14px]">
          {tableHeader?.map((item, idx) => (
            <li className={item.minW} key={idx}>
              <span className={`font-montserrat font-bold text-base text-neutral-2 `}>
                {item.label}
              </span>
            </li>
          ))}
        </ul>
        <div className="cus-scrollbar px-2 py-5 overflow-auto">
          <InfiniteScroll
            dataLength={
              principalAccountDetailData?.length ? principalAccountDetailData?.length : []
            }
            next={() => handleLoadMore(50)}
            hasMore={isFetchMore}
            height={'max-content'}
            style={{ maxHeight: '550px' }}
            loader={null}
            endMessage={''}
            className="cus-scrollbar"
          >
            {principalData?.map((item: any, idx: number) => (
              <ul
                className="flex justify-between px-4 py-5 h-auto font-montserrat font-medium text-base text-neutral-3 hover:bg-neutral-7 hover:text-neutral-1 mb-3 last:mb-0"
                key={idx}
                onMouseEnter={() => {
                  setHoverRow({
                    principalId: idx,
                    status: true,
                  })
                }}
                onMouseLeave={() => {
                  setHoverRow({
                    principalId: null,
                    status: false,
                  })
                }}
              >
                <li className="w-[28px] min-w-[28px]">{idx + 1}</li>
                <li className="w-[160px] min-w-[100px]">
                  <p className="w-full">{item?.principalName}</p>
                </li>
                <div className="relative w-[256px] min-w-[160px] break-all px-2">
                  <li style={{ ...stylePrincipalKey }}>{item?.principalKey}</li>
                  {hoverRow?.status && idx === hoverRow.principalId && 'item?.principalKey' && (
                    <button
                      className="absolute top-[-8px] right-0"
                      title="Copy this Principal key"
                      onClick={(event) => onHandleCopyPrincipalKey(event, item?.principalKey) ?? ''}
                    >
                      <img src={IconHoverCopyDetail} alt="Icon-Hover-Copy" />
                    </button>
                  )}
                </div>
                <li className="w-[156px] min-w-[100px]">
                  <p className="w-full">{item?.agencyName}</p>
                </li>
                <li className="w-[320px] min-w-[140px] px-2">
                  <p className="w-full">{item?.principalAddressLine1}</p>
                </li>
                <li className="w-[130px] min-w-[60px]">
                  <p className="break-all w-full">{item?.recordsCount}</p>
                </li>
              </ul>
            ))}
            {isFetchMore && <Loading className="relative" height={30} width={30} />}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  )
}

export default PrincipalAccountDetailTable
