import UsersManagement from '.'
import { checkProdSite } from '../../helpers/checkProdSite'
import { PageRoute } from '../../routes/routerInterface'

const usersManagementRoutes: PageRoute[] = [
  {
    path: '/users-management',
    element: UsersManagement,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
  },
]

export default usersManagementRoutes
