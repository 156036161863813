import { useMutation } from '@apollo/client'
import React, { useEffect, useRef, useState } from 'react'
import Moment from 'react-moment'
import { useLocation } from 'react-router-dom'
import RowActionBase from '../../../../components/partials/rowAction'
import StatusBase from '../../../../components/partials/statusAction'
import { notify } from '../../../../components/toastify'
import { CHANGE_STATUS_API_KEY, DELETE_KEY } from '../../../../queries/developerPortal'
import ChangeStatusDialog from '../../dialog/changeStatusKey'
import DeleteApiKeyDialog from '../../dialog/deleteKey'
import { MESSAGES, STATUS } from '../../../../constants/messages'
import IconDot from '../../../../assets/images/icon-dot.svg'
import IconHoverCopy from '../../../../assets/images/icon-hover-copy.svg'
import { Action, Hover, ListKeys, PropsTable } from './apiKeyModel'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { handleRole, onHandleCopyItem } from '../../../../helpers/functionUtils'
import ReactLoading from 'react-loading'
import {
  CHANGE_STATUS_API_KEY_BY_ADMIN,
  DELETE_KEY_BY_ADMIN,
} from '../../../../queries/adminPortal'
import { LocationState } from '../../../../constants/interface'
import { useSession } from '../../../../helpers/checkSession'
import ChangeKeyNameDialog from '../../dialog/changeKeyName'

const Tables: React.FunctionComponent<PropsTable> = ({
  handleUpdateInTable = async () => null,
  handleRefetchKeys = () => null,
  listKeys,
  isLoadingTable,
}) => {
  const { sessionChecking, handleErrorSession } = useSession()
  const userInfoState = useSelector((state: RootState) => state.userReducers?.userInfo)
  const currentRole = handleRole(userInfoState?.role?.name)
  const { state } = useLocation()
  const locationState = state as LocationState

  const [showAction, setShowAction] = useState<Action>({
    apiKeyId: null,
    status: false,
    lastItem: null,
  })
  const [hover, setHover] = useState<Hover>({
    apiKeyId: null,
    status: false,
  })
  const [isOpenModalChangeStatus, setIsOpenShowModalChangeStatus] = useState<boolean>(false)
  const [isOpenModalDeleteApiKey, setIsOpenModalDeleteApiKey] = useState<boolean>(false)
  const [isOpenModalChangeKeyName, setIsOpenModalChangeKeyName] = useState<boolean>(false)
  const [currentValueApiKey, setCurrentValueApiKey] = useState<ListKeys>({
    apiKeyId: null,
    createdAt: null,
    name: null,
    accessKey: null,
    status: null,
  })
  const belongDeveloper = currentRole.isRoleDev || currentRole.isRoleDeveloperAccountManager

  const [ChangeStatusApiKey, { loading: changeStatusApiKeyLoading }] = useMutation(
    belongDeveloper ? CHANGE_STATUS_API_KEY : CHANGE_STATUS_API_KEY_BY_ADMIN,
  )
  const [DeleteApiKey, { loading: deleteApiKeyLoading }] = useMutation(
    belongDeveloper ? DELETE_KEY : DELETE_KEY_BY_ADMIN,
  )

  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutSide = (e: any) => {
      if (menuRef && !menuRef.current?.contains(e.target)) {
        setShowAction({ lastItem: showAction.apiKeyId, apiKeyId: null, status: false })
      }
    }
    document.addEventListener('mousedown', handleClickOutSide)
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide)
    }
  }, [showAction])

  const onHandleClickAction = (item: any) => {
    if (showAction.lastItem == item.apiKeyId) {
      return
    }
    setShowAction({
      apiKeyId: item.apiKeyId,
      status: true,
      lastItem: item.apiKeyId,
    })
    setCurrentValueApiKey({
      ...item,
    })
  }

  const onHandleCopyAccessKey = async (publicKey: string) => {
    await onHandleCopyItem(publicKey, MESSAGES.INFO.I_COPIED_KEY, MESSAGES.ERROR.E_COPY_FAILED)
  }

  const handleCloseChangeStatusDialog = () => {
    setIsOpenShowModalChangeStatus(false)
  }

  const onHandleToggleChangeStatus = () => {
    setIsOpenShowModalChangeStatus(true)
  }

  // action open & close change key name dialog
  const handleOpenChangeKeyNameDialog = () => {
    setIsOpenModalChangeKeyName(true)
  }

  const handleCloseChangeKeyNameDialog = () => {
    setIsOpenModalChangeKeyName(false)
  }

  const onHandleChangeStatusApiKey = async () => {
    try {
      const response = belongDeveloper
        ? await ChangeStatusApiKey({
            variables: {
              apiKeyId: currentValueApiKey.apiKeyId,
              status: !currentValueApiKey.status,
            },
          })
        : await ChangeStatusApiKey({
            variables: {
              data: {
                apiKeyId: currentValueApiKey.apiKeyId,
                kind: locationState.kind,
                partyId: locationState.partyId,
                status: !currentValueApiKey.status,
              },
            },
          })
      if (!changeStatusApiKeyLoading && response) {
        setIsOpenShowModalChangeStatus(false)
        handleUpdateInTable()
        if (currentValueApiKey.status) {
          notify(MESSAGES.SUCCESS.S_CHANGE_STATUS_API_KEY_INACTIVATE, STATUS.SUCCESS)
        } else {
          notify(MESSAGES.SUCCESS.S_CHANGE_STATUS_API_KEY_ACTIVATE, STATUS.SUCCESS)
        }
      }
    } catch (error) {
      if (currentValueApiKey.status) {
        handleErrorSession(error, MESSAGES.ERROR.E_CHANGE_STATUS_API_KEY_INACTIVATE)
      } else {
        handleErrorSession(error, MESSAGES.ERROR.E_CHANGE_STATUS_API_KEY_ACTIVATE)
      }
      setIsOpenShowModalChangeStatus(false)
    }
  }

  const onHandleToggleDeleteApiKey = async () => {
    setIsOpenModalDeleteApiKey(true)
  }

  const handleCloseDeleteApiKeyDialog = () => {
    setIsOpenModalDeleteApiKey(false)
  }

  const onHandleDeleteApiKey = async () => {
    if ((await sessionChecking()) === false) {
      try {
        const response = belongDeveloper
          ? await DeleteApiKey({ variables: { apiKeyId: currentValueApiKey.apiKeyId } })
          : await DeleteApiKey({
              variables: {
                data: {
                  apiKeyId: currentValueApiKey.apiKeyId,
                  kind: locationState.kind,
                  partyId: locationState.partyId,
                },
              },
            })
        if (!deleteApiKeyLoading && response) {
          setIsOpenModalDeleteApiKey(false)
          handleUpdateInTable()
          notify(MESSAGES.SUCCESS.S_DELETE_API_KEY, STATUS.SUCCESS)
        }
      } catch (error) {
        handleErrorSession(error, MESSAGES.ERROR.E_DELETE_AP_KEY)
        setIsOpenModalDeleteApiKey(false)
      }
    }
  }

  return (
    <div className="w-full top-[88px] border-2 border-neutral-6 bg-neutral-8 rounded-2xl">
      <ul className="flex bg-primary-shade4 px-6 py-5 justify-start rounded-t-[14px]">
        <li className="w-[82px] min-w-[64px]">
          <span className="text-base font-semibold font-montserrat text-neutral-2">Date</span>
        </li>
        <li className="w-[120px] min-w-[100px] ml-10">
          <span className="text-base font-semibold font-montserrat text-neutral-2">Key name</span>
        </li>
        <li className="w-[820px] min-w-[100px] ml-10">
          <span className="text-base font-semibold font-montserrat text-neutral-2">Access Key</span>
        </li>
        <li className="w-[82px] min-w-[64px] md:mr-4">
          <span className="text-base font-semibold font-montserrat text-neutral-2">Status</span>
        </li>
        <li className="min-w-[24px]"></li>
      </ul>
      {isLoadingTable ? (
        <div className="flex justify-center p-5">
          <ReactLoading type="spin" color="#A989D8" height={30} width={30} />
        </div>
      ) : listKeys !== null && listKeys.length > 0 ? (
        <React.Fragment>
          <div className="mt-5 ">
            {listKeys?.map((item) => (
              <ul
                className="flex justify-start h-auto px-6 py-5 mb-3 font-montserrat text-body2 text-neutral-3 hover:bg-neutral-7 hover:text-neutral-1 hover:h-auto"
                key={item.apiKeyId}
                onMouseEnter={() => {
                  setHover({
                    apiKeyId: item.apiKeyId,
                    status: true,
                  })
                }}
                onMouseLeave={() => {
                  setHover({
                    apiKeyId: null,
                    status: false,
                  })
                }}
              >
                <li className="w-[82px] min-w-[64px] min-h-5">
                  <Moment format="MM/DD/YYYY">{item.createdAt ?? ''}</Moment>
                </li>
                <li className="w-[160px] min-w-[140px]">
                  <p className="ml-10 break-all ">{item.name}</p>
                </li>
                <div className="relative break-all w-[820px] min-w-[100px] mr-10 h-8">
                  {hover?.status && item.apiKeyId === hover.apiKeyId ? (
                    <div className="w-full break-all">
                      <li className="overflow-hidden text-ellipsis whitespace-nowrap min-w-[100px] ml-10">
                        {item.accessKey}
                      </li>
                      <button
                        title="Copy this Access Key"
                        onClick={() => onHandleCopyAccessKey(item.accessKey ?? '')}
                      >
                        <img
                          src={IconHoverCopy}
                          alt="Icon-Hover-Copy"
                          className="absolute top-[-8px] right-0"
                        />
                      </button>
                    </div>
                  ) : (
                    <li className="overflow-hidden text-ellipsis whitespace-nowrap ml-10 min-w-[140px]">
                      {item.accessKey}
                    </li>
                  )}
                </div>
                <li className="w-[82px]">
                  <StatusBase status={item.status as boolean} />
                </li>
                <button className="min-w-[24px]  mb-auto" onClick={() => onHandleClickAction(item)}>
                  <div className="relative ">
                    <img src={IconDot} alt="Icon-Dot" className="" />
                    {showAction?.apiKeyId === item.apiKeyId && showAction.status && (
                      <div
                        className={`absolute right-[2px] ${
                          isOpenModalChangeStatus ? 'z-0' : 'z-10'
                        }`}
                        ref={menuRef}
                      >
                        <RowActionBase
                          status={item.status as boolean}
                          handleToggle={onHandleToggleChangeStatus}
                          handleDeleteApiKey={onHandleToggleDeleteApiKey}
                          handleChangeKeyName={handleOpenChangeKeyNameDialog}
                        />
                      </div>
                    )}
                  </div>
                </button>
              </ul>
            ))}
          </div>
          <ChangeStatusDialog
            modalIsOpen={isOpenModalChangeStatus}
            handleCloseDialog={handleCloseChangeStatusDialog}
            handleChangeStatusApiKey={onHandleChangeStatusApiKey}
            statusValue={currentValueApiKey.status}
          />
          <DeleteApiKeyDialog
            modalIsOpen={isOpenModalDeleteApiKey}
            handleCloseDialog={handleCloseDeleteApiKeyDialog}
            handleDeleteApiKey={onHandleDeleteApiKey}
          />
          <ChangeKeyNameDialog
            modalIsOpen={isOpenModalChangeKeyName}
            handleCloseDialog={handleCloseChangeKeyNameDialog}
            currentValue={currentValueApiKey?.name}
            handleRefetchKeys={handleRefetchKeys}
            listKeys={listKeys}
            apiKeyId={currentValueApiKey?.apiKeyId}
          />
        </React.Fragment>
      ) : (
        <p className="pt-5 pb-3 text-center text-body1 text-neutral-4">No data available.</p>
      )}
    </div>
  )
}

export default Tables
