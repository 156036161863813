import DeveloperPortal from './'
import { PageRoute } from '../../routes/routerInterface'
import { checkProdSite } from '../../helpers/checkProdSite'

const developerPortalRoutes: PageRoute[] = [
  {
    path: '/developer-portal',
    element: DeveloperPortal,
    isProtected: true,
    permission: checkProdSite(true, 'Developer'),
  },
]

export default developerPortalRoutes
