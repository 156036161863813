import TechSolution from '.'
import { checkProdSite } from '../../helpers/checkProdSite'
import { PageRoute } from '../../routes/routerInterface'

const techSolutionRoutes: PageRoute[] = [
  {
    path: '/tech-solution',
    element: TechSolution,
    isProtected: false,
    permission: checkProdSite(false, ''),
  },
]

export default techSolutionRoutes
