import React, { useEffect } from 'react'
import { useMutation } from '@apollo/client'

import ButtonBase from '../../../components/partials/button'
import BaseDialog from '../../../components/dialog'
import iconTimes from '../../../assets/images/icon-times.svg'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { notify } from '../../../components/toastify'
import { useSession } from '../../../helpers/checkSession'
import { customStyles } from '../../../constants/customStyles'
import { DELETE_CLIENT_CREDENTIAL_DEV } from '../../../queries/developerPortal'
import { LocationState } from '../../../constants/interface'
import { DELETE_CLIENT_CREDENTIAL_ADMIN } from '../../../queries/adminPortal'

interface PropsAction {
  modalOpenDelete?: boolean
  hiddenBlock?: string
  handleEdit?: () => void
  handleCloseDeleteDialog?: (val: boolean) => void
  isAdmin?: boolean
  locationState?: LocationState
  handleRefetchWhenAddOrDeleteAuth0?: () => void
}

const DeleteClientCredential: React.FunctionComponent<PropsAction> = ({
  modalOpenDelete,
  handleCloseDeleteDialog = () => null,
  handleRefetchWhenAddOrDeleteAuth0 = () => null,
  isAdmin,
  locationState,
}) => {
  const { handleErrorSession } = useSession()
  const [deleteClientCredential, { data: deleteClientCredentialData }] = useMutation(
    isAdmin ? DELETE_CLIENT_CREDENTIAL_ADMIN : DELETE_CLIENT_CREDENTIAL_DEV,
  )

  const handleDeleteAuth0 = async () => {
    const variablesAdmin = {
      data: {
        kind: locationState?.kind,
        partyId: locationState?.partyId,
      },
    }
    try {
      const dataRes = (await isAdmin)
        ? deleteClientCredential({ variables: variablesAdmin })
        : (deleteClientCredential() as object)
      if (dataRes) {
        notify(MESSAGES.SUCCESS.S_DELETE_CLIENT_CREDENTIAL, STATUS.SUCCESS)
        handleCloseDeleteDialog(false)
        handleRefetchWhenAddOrDeleteAuth0()
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_DELETE_CLIENT_CREDENTIAL)
    }
  }

  useEffect(() => {
    if (!deleteClientCredentialData?.deleteClientCredentialForAdmin?.clientId) {
      handleRefetchWhenAddOrDeleteAuth0()
    }
  }, [deleteClientCredentialData])

  const handleCloseDeleteClientDialog = () => {
    handleCloseDeleteDialog(false)
  }

  return (
    <BaseDialog modalIsOpen={modalOpenDelete} customStyles={customStyles}>
      <div className="w-[392px] h-[204px] p-2.5 pt-0">
        <div className="flex justify-between">
          <div />
          <img
            src={iconTimes}
            alt=""
            className="cursor-pointer mr-[-12px]"
            onClick={handleCloseDeleteClientDialog}
          />
        </div>
        <p className="text-center text-headline5 mb-2 mt-[-16px] text-indicator-red">
          Delete Client Credential
        </p>
        <p className="text-center text-body1 mb-2 pt-6 text-indicator-red">
          Are you sure you want to permanently delete Client credential?
        </p>
        <div className="overflow-y-scroll cus-scrollbar mr-[-23px] "></div>
        <div className="flex w-[420px] pb-0">
          <ButtonBase
            type="submit"
            className="w-[180px] mt-4 bg-primary-1 min-w-min text-center"
            onClick={handleCloseDeleteClientDialog}
            bgDisabled="bg-primary-shade3"
          >
            Cancel
          </ButtonBase>
          <ButtonBase
            type="submit"
            className="w-[180px] mt-4 ml-3 justify-around border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4"
            onClick={handleDeleteAuth0}
            bgDisabled="bg-primary-shade3"
          >
            Delete
          </ButtonBase>
        </div>
      </div>
    </BaseDialog>
  )
}

export default DeleteClientCredential
