import React, { useState } from 'react'
import CollapseItem from '../../components/partials/collapseItem'
import pushWebhook from '../../assets/images/push-webhook.svg'
import urlWebhook from '../../assets/images/url-webhook.svg'
import lastReadRecord from '../../assets/images/last-read-record.svg'
import sandBox12 from '../../assets/images/sandbox12.svg'
import bearerHeader from '../../assets/images/bearer-header.svg'
import UpToTop from '../../components/partials/upToTop'

const TechSolution = () => {
  const [listCollapse, setListCollapse] = useState<number[]>([1])
  const [isShowUpToTop, setIsShowUpToTop] = useState<boolean>(false)

  const handleOpenCollapse = (value: number) => {
    if (listCollapse.includes(value)) {
      setListCollapse(listCollapse.filter((val) => val != value))
    } else {
      setListCollapse(listCollapse.concat(value))
    }
  }

  window.addEventListener('scroll', () => {
    if (window.scrollY > 250) {
      setIsShowUpToTop(true)
    } else {
      setIsShowUpToTop(false)
    }
  })

  return (
    <div className="px-20 md:px-10 mt-8 text-body1-med">
      <CollapseItem
        title="Why graphQL"
        id={1}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapse}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med py-6">
          <p className="mb-5">
            Propeller is using Graphql control to deliver our API’s capability. There are a few key
            reasons for using Graphql:
          </p>
          <div className="flex ml-3">
            <div className="w-[3px] h-[3px] border border-neutral-3 bg-neutral-3 rounded-full mt-[13px] mr-4" />
            <span>
              GraphQL reduces the number of calls and allows you to control what data you can get
              without intervention. You can call data you need in a call rather than a sequence of
              calls down a tree to find what you need.
            </span>
          </div>
          <div className="flex ml-3">
            <div className="w-[3px] h-[3px] border border-neutral-3 bg-neutral-3 rounded-full mt-[13px] mr-4" />
            <span>
              We can update our URL and Schema over time without having to force versioning upgrades
              on our clients.
            </span>
          </div>
          <div className="flex ml-3">
            <div className="w-[3px] h-[3px] border border-neutral-3 bg-neutral-3 rounded-full mt-[13px] mr-4" />
            <span>Easy learning curve.</span>
          </div>
          <div className="flex ml-3">
            <div className="w-[3px] h-[3px] border border-neutral-3 bg-neutral-3 rounded-full mt-[13px] mr-4" />
            <span>
              <a
                className="text-primary-shade2 underline"
                href="https://graphql.com"
                target="_blank"
                rel="noreferrer"
              >
                Graphql.com
              </a>{' '}
              has links to many resources
            </span>
          </div>
          <div className="flex ml-3">
            <div className="w-[3px] h-[3px] border border-neutral-3 bg-neutral-3 rounded-full mt-[13px] mr-4" />
            <span>
              Apollo Has a great free comprehensive introductory course{' '}
              <a
                className="text-primary-shade2 underline"
                href="https://www.apollographql.com/tutorials/"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
            </span>
          </div>
          <p className="mt-6">
            And of course you can contact us at{' '}
            <a
              className="text-primary-shade2 underline"
              href="mailto:apis@propellerbonds.com"
              target="_blank"
              rel="noreferrer"
            >
              apis@propellerbonds.com
            </a>{' '}
            if you need any help.
          </p>
        </div>
      </CollapseItem>
      <CollapseItem
        title="Webhook approach"
        id={2}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapse}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med pb-4">
          <p className="pt-6">
            Our approach around how we use web hooks makes our infrastructure truly asynchronous as
            we will tell you when data is available to retrieve.
          </p>
          <p className="pt-6">
            We disconnect our systems to an extent that downtime on either side isn’t a problem. We
            give you disaster recovery for free with this approach.
          </p>
          <p className="pt-6">
            We currently have manual processes reconciling banking information, so the system allows
            us to provide an API but over time improve toward automated straight through processing
            as we get more access to banking API’s to assist with automation.
          </p>
          <p className="pt-6 pb-3">
            Propeller has chosen a web hook based trigger to inform your carrier site that there is
            data available to consume.
          </p>
        </div>
      </CollapseItem>
      <CollapseItem
        title="03 Step approach"
        id={3}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapse}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med pb-4">
          <p className="py-6">For our communications approach we have chosen a 3 stage approach.</p>
          <ul className="list-outline list-disc ml-6">
            <li>
              Propeller calls your webhook endpoint with a POST request and tells your system that
              there is data to consume.{' '}
            </li>
            <li>
              You use the information provided in the webhook to call our service and retrieve your
              records
            </li>
            <li>
              You confirm you retrieved data from our service by telling us that you got the last
              record. We call this record “Last read record confirmed” (we use this last record
              notification to confirm back the next record range in our next web hook call) Our
              webhook continues to fire on a schedule described below whilst “Latest Record” !=
              “Last read record confirmed”
            </li>
          </ul>
          <p className="mt-6 mb-3">
            The solution will attempt to connect to poll your site and tell your system that data is
            ready for consumption – we will continue to poll your site until we get update from you
            of the “Last read record confirmed” Our polling cycle is 15 minute polls for the first
            hour then hourly after that for the next 24 then daily for the next 10 days.
          </p>
        </div>
      </CollapseItem>
      <CollapseItem
        title="More Tech insight"
        id={4}
        handleOpenCollapse={handleOpenCollapse}
        listCollapse={listCollapse}
      >
        <div className="bg-neutral-7 px-[52px] text-body1-med pb-4">
          <p className="pt-6">
            The approach is as follows - setup your webhook and use our system to send test webhooks
            to your server.{' '}
          </p>
          <p className="pt-6">
            Under Developer Portal &gt; Activities Management enter your webhook details.
          </p>
          <p className="my-6">Webhook configuration</p>
          <img src={pushWebhook} alt="" />
          <img src={urlWebhook} alt="" className="mt-6" />
          <p className="my-6">
            Set it by pressing the “Update URL” button. The “Push” button is use to manually push
            the hook to test if your system works. It will push a url with the contents of of the
            Read Records Numbers from the screen below:{' '}
          </p>
          <img src={lastReadRecord} alt="" />
          <p className="my-6">
            Then you use the records we send in the webhook to your server to query our Development
            portal with your requests.
          </p>
          <p className="my-6">
            These are really simple and can be generated from our API Sandbox and delivered to you
            in curl statements which you can convert into your favorite language.
          </p>
          <p className="my-6">
            Once you have your login you will get access to or API Sandbox explorer which can model
            you queries and mutations which is integrated with our Development portal.
          </p>
          <p className="my-6">
            These are really simple and can be generated from our API Sandbox and delivered to you
            in curl statements which you can convert into your favorite language.
          </p>
          <img src={sandBox12} alt="" />
          <img src={bearerHeader} alt="" className="mt-8 mb-4" />
        </div>
      </CollapseItem>
      <div className="mt-10" />
      {isShowUpToTop && <UpToTop />}
    </div>
  )
}

export default TechSolution
