import React, { useEffect } from 'react'
import { useMutation } from '@apollo/client'
import BaseDialog from '../../../components/dialog'
import iconTimes from '../../../assets/images/icon-times.svg'
import ButtonBase from '../../../components/partials/button'
import { customStyles } from '../../../constants/customStyles'
import { ADD_AMS_TO_SYSTEM } from '../../../queries/adminPortal'
import { notify } from '../../../components/toastify'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { FormInput } from '../../../components/partials/formInput'
import { alphabetPattern } from '../../../utils/regex'
import { useForm } from 'react-hook-form'

export type RegistrationFormFields = {
  agencyManagementProviderName: string
}

type Props = {
  modalIsOpen?: boolean
  handleCloseAddAMSDialog?: (val: boolean) => void
  onHandleReFetchList?: () => void
  userInfo?: object | any
}

const AddAMSToTheSystemDialog: React.FunctionComponent<Props> = ({
  modalIsOpen,
  handleCloseAddAMSDialog = () => null,
  onHandleReFetchList = () => null,
  userInfo,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<RegistrationFormFields>()
  const [addAMSToTheSystem, { error: errorAddAMSToTheSystem }] = useMutation(ADD_AMS_TO_SYSTEM)

  const handleAddAMS = handleSubmit(async () => {
    const dataRes = await addAMSToTheSystem({
      variables: {
        agencyManagementProviderName: getValues('agencyManagementProviderName')?.trim(),
      },
    })
    if (dataRes) {
      notify(MESSAGES.SUCCESS.S_ADD_AMS_TO_THE_SYSTEM, STATUS.SUCCESS)
      onHandleReFetchList()
      handleCloseAddAMSDialog(false)
      reset()
    }
  })

  const handleTrimValues = () => {
    setValue('agencyManagementProviderName', getValues('agencyManagementProviderName')?.trim())
  }

  const handleAddAMSToTheSystem = async () => {
    await handleTrimValues()
    await handleAddAMS()
  }

  const handleCancel = () => {
    handleCloseAddAMSDialog(false)
    reset()
  }

  useEffect(() => {
    setValue('agencyManagementProviderName', userInfo?.organization?.name)
  }, [userInfo])

  useEffect(() => {
    if (errorAddAMSToTheSystem?.message === MESSAGES.ERROR.E_ADD_AMS_TO_THE_SYSTEM) {
      notify(MESSAGES.ERROR.E_ADD_AMS_TO_THE_SYSTEM, STATUS.ERROR)
    } else if (errorAddAMSToTheSystem?.message !== undefined) {
      notify(MESSAGES.ERROR.E_TRY_AGAIN, STATUS.ERROR)
    }
  }, [errorAddAMSToTheSystem])

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={customStyles}>
      <div className="px-1">
        <div className="flex justify-between">
          <div className="absolute right-4 top-4">
            <img src={iconTimes} alt="" className="cursor-pointer" onClick={handleCancel} />
          </div>
        </div>
        <div className="w-[412px]">
          <p className="mt-2 mb-6 text-center text-headline5">Add</p>
          <div className="flex justify-center">
            <p className="font-semibold text-center text-body1 font-montserrat">
              Are you sure to add this new AMS to the system?
            </p>
          </div>
          <form onSubmit={handleAddAMS}>
            <div className="pt-3">
              <div className="w-[412px]">
                <FormInput<RegistrationFormFields>
                  id="agencyManagementProviderName"
                  type="text"
                  name="agencyManagementProviderName"
                  label="agencyManagementProviderName"
                  defaultValue={userInfo?.organization?.name}
                  placeholder="Please fill in AMS name"
                  inputClassName="w-full mt-1"
                  register={register}
                  rules={{
                    required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                    pattern: alphabetPattern,
                    maxLength: 200,
                  }}
                  errors={errors}
                />
              </div>
            </div>
          </form>
          <div className="flex justify-between mt-4 mb-1">
            <ButtonBase
              type="reset"
              className="w-[200px] mt-2 border-2 border-primary-1 text-primary-1 bg-white-50 hover:bg-violet-500"
              onClick={handleCancel}
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              type="button"
              className="w-[200px] mt-2 bg-primary-1 min-w-min text-center"
              bgDisabled="bg-primary-shade3"
              onClick={handleAddAMSToTheSystem}
            >
              Add
            </ButtonBase>
          </div>
        </div>
      </div>
    </BaseDialog>
  )
}

export default AddAMSToTheSystemDialog
