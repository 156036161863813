import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import Loading from '../../components/loading'
import ButtonBase from '../../components/partials/button'
import { notify } from '../../components/toastify'
import { specialChars, asciiChars } from '../../utils/regex'
import GenerateKeyDialog from '../developer-portal/dialog/generateKey'
import Tables from '../developer-portal/table/api-key-management/apiKeyManagement'
import { GET_API_KEY, GET_DUMMY_DATASET, GENERATE } from '../../queries/developerPortal'
import IconDownload from '../../assets/images/download.svg'
import IconReset from '../../assets/images/reset.svg'
import IconUpload from '../../assets/images/upload.svg'
import { MESSAGES, STATUS } from '../../constants/messages'
import { ListKeys } from '../developer-portal/table/api-key-management/apiKeyModel'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { exportCSVFile } from '../../utils/csvHandler'
import { handleRole } from '../../helpers/functionUtils'
import { GENERATE_KEY_BY_ADMIN, GET_API_KEY_BY_ADMIN } from '../../queries/adminPortal'
import { LocationState } from '../../constants/interface'
import { useSession } from '../../helpers/checkSession'
import { CODE_TIMEOUT } from '../../constants/statusCode'
import { KIND } from '../../constants/common'

const DevelopmentManagement = () => {
  const { timeOutSession, handleErrorSession } = useSession()
  const [listKeys, setListKeys] = useState<ListKeys[]>([])
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false)
  const userInfoState = useSelector((state: RootState) => state.userReducers?.userInfo)
  const currentRole = handleRole(userInfoState?.role?.name)
  const kind = userInfoState?.kind
  const { state } = useLocation()
  const locationState = state as LocationState
  const belongDeveloper = currentRole.isRoleDev || currentRole.isRoleDeveloperAccountManager

  const variablesGetAPIKey: any = belongDeveloper
    ? {
        fetchPolicy: 'no-cache',
      }
    : {
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            partyId: locationState?.partyId,
            kind: locationState?.kind,
          },
        },
      }

  const {
    loading: getApiKeyLoading,
    error: getApiKeyError,
    data: getApiKeyData,
    refetch,
  } = useQuery(belongDeveloper ? GET_API_KEY : GET_API_KEY_BY_ADMIN, variablesGetAPIKey)

  const {
    data: dummyDataset,
    loading: dummyDatasetLoading,
    error: dummyDatasetError,
  } = useQuery(GET_DUMMY_DATASET, {
    fetchPolicy: 'no-cache',
    skip: true
  })

  const [generateKey] = useMutation(
    belongDeveloper
      ? userInfoState.kind === KIND.CARRIERS
        ? GENERATE.CARRIER
        : GENERATE.AGENCY
      : GENERATE_KEY_BY_ADMIN,
  )

  const [isOpenGenerateKey, setIsOpenGenerateKey] = useState(false)
  const [keyValue, setKeyValue] = useState('')
  const [isDisableGenerateKey, setIsDisableGenerateKey] = useState(true)
  const [errorInvalidKey, setErrorInvalidKey] = useState('')

  useEffect(() => {
    const onHandleShowListKeys = async () => {
      if (!getApiKeyLoading && !getApiKeyError) {
        const responseData = currentRole.isRoleAdmin
          ? await getApiKeyData.apiKeysByAdmin
          : await getApiKeyData.apiKeys
        if (Array.isArray(responseData)) {
          setListKeys(responseData)
        }
      }
    }
    onHandleShowListKeys()
  }, [getApiKeyData?.apiKeys, getApiKeyData?.apiKeysByAdmin])
  // #start region: handle function of generate key
  const handleCloseGenerateKeyDialog = () => {
    setIsOpenGenerateKey(false)
    setIsDisableGenerateKey(false)
    setErrorInvalidKey('')
    setKeyValue('')
  }

  const handleChangeKey = (val: string) => {
    setKeyValue(val)
    // handle validation the key name
    const listKeyNames = getApiKeyData?.apiKeys?.map((x: any) => x?.name)
    const isDuplicate = listKeyNames?.includes(val)
    const isOnlySpace = val.replaceAll(' ', '')
    let isSpecialChars
    let isAsciiChars

    if (specialChars.test(val)) {
      isSpecialChars = true
    } else {
      isSpecialChars = false
    }

    if (asciiChars.test(val)) {
      isAsciiChars = true
    } else {
      isAsciiChars = false
    }

    if (val.length > 50) {
      setIsDisableGenerateKey(true)
      setErrorInvalidKey(MESSAGES.ERROR.E_SHORTEN_KEY_NAME)
    } else if (isDuplicate) {
      setIsDisableGenerateKey(true)
      setErrorInvalidKey(MESSAGES.ERROR.E_KEY_NAME_EXISTS)
    } else if (isOnlySpace === '') {
      if (val.length > 0) {
        setIsDisableGenerateKey(true)
        setErrorInvalidKey(MESSAGES.ERROR.E_KEY_NAME_HAS_SPACE)
      } else {
        setIsDisableGenerateKey(false)
        setErrorInvalidKey('')
      }
    } else if (isSpecialChars || isAsciiChars) {
      setIsDisableGenerateKey(true)
      setErrorInvalidKey(MESSAGES.ERROR.E_SPECIAL_CHARS)
    } else {
      setIsDisableGenerateKey(false)
      setErrorInvalidKey('')
    }
  }

  const onHandleUpdateInTable = () => {
    setIsLoadingTable(true)
    refetch()
  }

  const handleGenerateKey = async () => {
    if (keyValue === '') {
      setErrorInvalidKey(MESSAGES.ERROR.E_FILL_KEY_NAME)
    } else {
      try {
        const dataRes = belongDeveloper
          ? await generateKey({ variables: { name: keyValue.trim() } })
          : await generateKey({
              variables: {
                data: {
                  kind: locationState.kind,
                  name: keyValue.trim(),
                  partyId: locationState.partyId,
                },
              },
            })
        if (dataRes) {
          notify(MESSAGES.SUCCESS.S_KEY_GENERATED, STATUS.SUCCESS)
          onHandleUpdateInTable()
        }
      } catch (error) {
        handleErrorSession(error, MESSAGES.ERROR.E_KEY_NAME_EXISTS)
      }
      setKeyValue('')
      setIsOpenGenerateKey(false)
      setErrorInvalidKey('')
      setIsDisableGenerateKey(false)
    }
  }
  // #end region: handle function of generate key

  const handleDownload = useCallback(() => {
    if (dummyDataset?.getAllBondData?.data?.length > 0) {
      const { data, fileName, fileFormat } = dummyDataset.getAllBondData
      const header = Object.keys(data[0])
      const body = data.map((row: any) => {
        return Object.values(row)
      })
      const exportData = [header].concat(body)
      exportCSVFile(exportData, fileName, fileFormat)
    }
  }, [dummyDataset])

  // handle refetch when change key name
  const handleRefetchKeys = () => {
    refetch()
  }

  useEffect(() => {
    if (getApiKeyData || getApiKeyError) {
      setIsLoadingTable(false)
    }
  }, [getApiKeyData, getApiKeyError])

  // handle session timeout
  useEffect(() => {
    if (
      getApiKeyError?.graphQLErrors[0]?.extensions?.code ||
      dummyDatasetError?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT
    ) {
      timeOutSession()
    }
  }, [getApiKeyError, dummyDatasetError])

  // handle loading after action
  if (getApiKeyLoading) return <Loading />

  return (
    <div className="px-20 py-10">
      <div className="flex items-center justify-between pb-5">
        <p className="text-headline5A text-neutral-1 md:ml-[-40px]">Access Keys management</p>
        <ButtonBase
          className="w-[200px] md:mr-[-40px]"
          bgHover="bg-violet-900"
          onClick={() => setIsOpenGenerateKey(true)}
          disabled={listKeys.length >= 10 ? true : false}
          bgDisabled="bg-violet-600"
        >
          Generate a key
        </ButtonBase>
      </div>
      <div className="md:mr-[-40px] md:ml-[-40px]">
        <Tables
          handleUpdateInTable={onHandleUpdateInTable}
          listKeys={listKeys}
          isLoadingTable={isLoadingTable}
          handleRefetchKeys={handleRefetchKeys}
        />
        {(locationState?.kind === KIND.CARRIERS || kind === KIND.CARRIERS) && (
          <hr className="my-10 border-t-neutral-6" />
        )}
      </div>
      {(locationState?.kind === KIND.CARRIERS || kind === KIND.CARRIERS) && (
        <div className="flex justify-between items-center md:mr-[-40px] md:ml-[-40px]">
          <p className="text-headline5A text-neutral-1">Dummy Dataset</p>
          <div>
            <ButtonBase
              onClick={handleDownload}
              className={`justify-around ml-3 p-0 w-[143px] text-center border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4 ${
                dummyDatasetLoading ? 'cursor-not-allowed' : ''
              }`}
            >
              <div className="flex justify-between">
                <img src={IconDownload} alt="" className="mx-2" />
                <p className="mr-3">Download</p>
              </div>
            </ButtonBase>
            <ButtonBase
              bgHover="bg-violet-600"
              className="justify-around ml-3 w-[143px] border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4
              "
            >
              <div className="flex justify-between">
                <img src={IconUpload} alt="" className="mr-2" />
                <p className="mr-2">Upload</p>
              </div>
            </ButtonBase>
            <ButtonBase
              bgHover="bg-violet-600"
              className="justify-around ml-3 w-[143px] border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4
              "
            >
              <div className="flex justify-between">
                <img src={IconReset} alt="" className="mr-2" />
                <p className="mr-2">Reset</p>
              </div>
            </ButtonBase>
          </div>
        </div>
      )}
      <GenerateKeyDialog
        modalIsOpen={isOpenGenerateKey}
        handleCloseDialog={handleCloseGenerateKeyDialog}
        handleGenerateKey={handleGenerateKey}
        keyValue={keyValue}
        handleChangeKey={handleChangeKey}
        isDisableGenerateKey={isDisableGenerateKey}
        errorInvalidKey={errorInvalidKey}
      />
    </div>
  )
}

export default DevelopmentManagement
