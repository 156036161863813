import DeveloperDocs from './'
import { PageRoute } from '../../routes/routerInterface'
import { checkProdSite } from '../../helpers/checkProdSite'

const developerDocsRoutes: PageRoute[] = [
  {
    path: '/developer-docs',
    element: DeveloperDocs,
    isProtected: true,
    permission: checkProdSite(false, ''),
  },
]

export default developerDocsRoutes
