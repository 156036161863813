import React, { FC } from 'react'
import iconDownArrow from '../../assets/images/icon-down-arrow.svg'
import iconLeftArrow from '../../assets/images/icon-left-arrow.svg'

type Props = {
  id?: number
  handleOpenCollapse?: (value: number) => void
  children?: React.ReactNode
  title?: string
  listCollapse?: number[]
}

export const CollapseItem: FC<Props> = ({
  id,
  handleOpenCollapse = () => null,
  children,
  title,
  listCollapse = [],
}) => {
  return (
    <div>
      <div className="my-8">
        <div
          className="bg-neutral-7 flex text-headline5 text-neutral-2 font-bold p-2 py-3 cursor-pointer"
          onClick={() => handleOpenCollapse(id as number)}
        >
          <img
            src={listCollapse.includes(id as number) ? iconDownArrow : iconLeftArrow}
            alt=""
            className="mx-2"
          />
          <p className="ml-1">{title}</p>
        </div>
        <hr />
        {listCollapse.includes(id as number) && children}
      </div>
    </div>
  )
}

export default CollapseItem
