import React, { useEffect, useState } from 'react'
import BaseDialog from '../../../components/dialog'
import iconTimes from '../../../assets/images/icon-times.svg'
import ButtonBase from '../../../components/partials/button'
import { defaultCustomStyles } from '../../../constants/customStyles'
import { FormInput } from '../../../components/partials/formInput'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { nameKeyPattern } from '../../../utils/regex'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { UPDATE_AGENT_KEY } from '../../../queries/developerPortal'
import { notify } from '../../../components/toastify'

export type RegistrationFormFields = {
  agentKey: string
}

type Props = {
  handleCloseDialog?: (val: boolean) => void
  modalIsOpen?: boolean
  handleAddOrEditAgentKey?: () => void
  agentInfo?: object | any
  agencyName?: string
  handleRefetchData?: () => void
  clearAgentDetail?: () => void
}

const AddOrEditAgentKey: React.FunctionComponent<Props> = ({
  handleCloseDialog = () => null,
  modalIsOpen,
  agentInfo,
  agencyName,
  handleRefetchData = () => null,
  clearAgentDetail = () => null,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<RegistrationFormFields>()

  const [addOrEditAgencyKey, { error: errorAgentByAMS }] = useMutation(UPDATE_AGENT_KEY)
  const [changeAddOrEditAgentKey, setChangeAddOrEditAgentKey] = useState<boolean>(false)

  useEffect(() => {
    if (agentInfo?.agencyAgentKey === null) {
      setChangeAddOrEditAgentKey(true)
    } else {
      setChangeAddOrEditAgentKey(false)
    }
  }, [agentInfo])

  const handleSubmitFormAddOrEditAgent = handleSubmit(async () => {
    const variables = {
      data: {
        agencyAgentId: agentInfo?.agencyAgentId,
        agencyAgentKey: getValues('agentKey').trim(),
      },
    }
    try {
      const dataRes = await addOrEditAgencyKey({ variables: variables })
      if (dataRes) {
        handleRefetchData()
        {
          changeAddOrEditAgentKey
            ? notify(
                `The ${agentInfo?.agent?.agentName} agent key has been added successfully!`,
                STATUS.SUCCESS,
              )
            : notify(
                `The ${agentInfo?.agent?.agentName} agent key has been updated successfully!`,
                STATUS.SUCCESS,
              )
        }
        handleCloseAddOrEditAgentKeyDialog()
      }
    } catch (error) {
      // notify(MESSAGES.ERROR.E_DUPLICATE_AGENT_KEY, STATUS.ERROR)
    }
  })

  const handleTrimValuesAddOrEditAgencyKey = () => {
    setValue('agentKey', getValues('agentKey')?.trim())
  }

  const handleAddOrEditAgentKey = async () => {
    await handleTrimValuesAddOrEditAgencyKey()
    await handleSubmitFormAddOrEditAgent()
  }

  const handleCloseAddOrEditAgentKeyDialog = () => {
    clearAgentDetail()
    reset()
    handleCloseDialog(false)
  }

  useEffect(() => {
    setValue('agentKey', agentInfo?.agencyAgentKey)
  }, [agentInfo])

  useEffect(() => {
    if (errorAgentByAMS?.message === MESSAGES.ERROR.E_DUPLICATE_AGENT_KEY) {
      notify(MESSAGES.ERROR.E_DUPLICATE_AGENT_KEY, STATUS.ERROR)
    } else if (errorAgentByAMS?.message !== undefined) {
      notify(MESSAGES.ERROR.E_UPDATE_AGENT_KEY, STATUS.ERROR)
    }
  }, [errorAgentByAMS])

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={defaultCustomStyles}>
      <>
        <form onSubmit={handleAddOrEditAgentKey}>
          <div className="flex justify-between">
            <div className="absolute right-4 top-4">
              <img
                src={iconTimes}
                alt=""
                className="cursor-pointer"
                onClick={() => handleCloseAddOrEditAgentKeyDialog()}
              />
            </div>
          </div>
          <p className="mb-3 text-center text-headline5 text-neutral-1">
            {changeAddOrEditAgentKey ? 'Add AMS Agent Key' : 'Edit AMS Agent Key'}
          </p>
          <div className="flex mt-6 mb-2">
            <p className="pr-3 font-semibold text-body2 text-neutral-3">Agency:</p>
            <p className="-mt-[2px] font-semibold text-body1">{agencyName}</p>
          </div>
          <div className="flex mb-3">
            <p className="pr-[22px] font-semibold text-body2 text-neutral-3">Agent:</p>
            <p className="-mt-[2px] font-semibold text-body1">{agentInfo?.agent?.agentName}</p>
          </div>
          <div className="flex mt-3 mb-3">
            <p className="text-hairline1 text-neutral-2">AMS Agent Key</p>
          </div>
          <FormInput<RegistrationFormFields>
            id="agentKey"
            type="text"
            name="agentKey"
            label="agencyKey"
            placeholder="Enter your AMS Agent Key"
            inputClassName="w-full mt-1"
            defaultValue={agentInfo?.agencyAgentKey}
            register={register}
            rules={{
              required: MESSAGES.ERROR.E_REQUIRED_FIELD,
              pattern: nameKeyPattern,
              maxLength: 200,
            }}
            errors={errors}
          />
        </form>
        <div className="flex pt-1">
          <div className="w-2/4 pr-3 mt-4">
            <ButtonBase
              type="submit"
              className="w-full border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4"
              onClick={() => handleCloseAddOrEditAgentKeyDialog()}
              bgDisabled="bg-primary-shade3"
            >
              Cancel
            </ButtonBase>
          </div>
          <div className="w-2/4 pr-3 mt-4">
            <ButtonBase
              type="submit"
              className="w-full ml-3 text-center bg-primary-1 min-w-min"
              bgDisabled="bg-primary-shade3"
              onClick={handleAddOrEditAgentKey}
            >
              {changeAddOrEditAgentKey ? 'Add' : 'Change'}
            </ButtonBase>
          </div>
        </div>
      </>
    </BaseDialog>
  )
}

export default AddOrEditAgentKey
