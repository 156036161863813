import React from 'react'

export enum TableName {
  USER_LIST = 'user_list',
}
interface Props {
  status: boolean
  table?: TableName
}

const StatusBase: React.FC<Props> = ({ status = false, table }) => {
  const showText = () => {
    if (!status && table === TableName.USER_LIST) {
      return 'Blocked'
    }
    if (!status && !table) {
      return 'Inactive'
    }
    return 'Active'
  }
  return (
    <div>
      <div className="w-[120px] h-6 flex items-center">
        <span
          className={`w-3 h-3 top-[6px] rounded-xl ${
            status ? 'bg-indicator-green' : 'bg-secondary-bold2'
          } `}
        ></span>
        <span className="ml-3 font-montserrat text-body2 text-neutral-3 hover:text-neutral-1">
          {showText()}
        </span>
      </div>
    </div>
  )
}

export default StatusBase
