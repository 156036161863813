import React from 'react'
import { useMutation } from '@apollo/client'
import BaseDialog from '../../../components/dialog'
import iconTimes from '../../../assets/images/icon-times.svg'
import ButtonBase from '../../../components/partials/button'
import { customStyles } from '../../../constants/customStyles'
import { REJECT_USER_REQUEST } from '../../../queries/adminPortal'
import { notify } from '../../../components/toastify'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { useSession } from '../../../helpers/checkSession'

type Props = {
  modalIsOpen?: boolean
  handleCloseDialog?: (val: boolean) => void
  onHandleReFetchList?: () => void
  userInfo?: object | any
}

const RejectUserQuest: React.FunctionComponent<Props> = ({
  modalIsOpen,
  handleCloseDialog = () => null,
  onHandleReFetchList = () => null,
  userInfo,
}) => {
  const { handleErrorSession } = useSession()
  const [rejectUserRequest] = useMutation(REJECT_USER_REQUEST)

  const handleRejectUserRequest = async () => {
    try {
      const dataRes = await rejectUserRequest({
        variables: {
          data: { userId: userInfo?.userId },
        },
      })
      if (dataRes) {
        notify(MESSAGES.SUCCESS.S_REJECTED_USER, STATUS.SUCCESS)
        onHandleReFetchList()
        handleCloseDialog(false)
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_TRY_AGAIN)
      handleCloseDialog(false)
    }
  }

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={customStyles}>
      <div className="px-1">
        <div className="flex justify-between">
          <div className="absolute right-4 top-4">
            <img
              src={iconTimes}
              alt=""
              className="cursor-pointer"
              onClick={() => handleCloseDialog(false)}
            />
          </div>
        </div>
        <div className="w-[380px]">
          <p className="text-headline5 text-center mt-2 mb-6">Reject user</p>
          <div className="flex justify-center">
            <p className="font-montserrat text-center font-medium">
              Are you sure you want to reject this request?
            </p>
          </div>
          <div className="flex justify-between mb-1 mt-4">
            <ButtonBase
              type="button"
              className="w-[180px] mt-2 bg-primary-1 min-w-min text-center"
              onClick={() => handleCloseDialog(false)}
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              type="reset"
              className="w-[180px] mt-2 border-2 border-primary-1 text-primary-1 bg-white-50 hover:bg-violet-500"
              onClick={handleRejectUserRequest}
            >
              Reject
            </ButtonBase>
          </div>
        </div>
      </div>
    </BaseDialog>
  )
}

export default RejectUserQuest
