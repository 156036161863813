import React, { useCallback, useEffect, useRef, useState } from 'react'
import Avatar from '../avatar'
import IconDot from '../../assets/images/icon-dot.svg'
import RowEditUser from './../partials/rowEditUser'

const stylesMyAccount = 'w-[628px] lg:w-[420px] md:!w-[688px]'

interface CardProps {
  avatar?: string
  firstName: string
  lastName: string
  title: string
  hidden?: string
  hiddenBlock?: string
  propellerMail?: string
  businessMail?: string
  phoneNumber?: string
  isEditCarrierInfo?: boolean
  userInfo?: object | any
  isBusinessInfo?: boolean
  handleRefetchData?: () => void
  isShowEditAction?: boolean
  isJustEdit?: boolean
  handleShowOverflow?: () => void
  isMyAccount?: boolean
}

const Card: React.FC<CardProps> = ({
  avatar,
  firstName,
  lastName,
  title,
  hidden,
  hiddenBlock,
  propellerMail,
  businessMail,
  phoneNumber,
  isEditCarrierInfo,
  userInfo,
  isBusinessInfo,
  handleRefetchData = () => null,
  isShowEditAction,
  isJustEdit,
  handleShowOverflow = () => null,
  isMyAccount,
}) => {
  const btnRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const [isPopup, setIsPopup] = useState<boolean>(false)

  const handleOutsideClick = useCallback((e: any) => {
    if (
      menuRef &&
      !menuRef.current?.contains(e.target) &&
      btnRef &&
      !btnRef.current?.contains(e.target)
    ) {
      setIsPopup(false)
    }
  }, [])

  const handleClick = () => {
    setIsPopup(!isPopup)
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)

    return () => {
      // Remove click outside
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isPopup])

  return (
    <div className="flex justify-between flex-wrap gap-6 pt-5">
      <div
        className={`w-[688px] min-w-[420px] p-5 rounded-[16px] bg-neutral-7-header-footer border-2 border-neutral-7 ${
          isMyAccount && stylesMyAccount
        }`}
      >
        <div className="flex">
          <Avatar
            avatar={avatar}
            className={`max-w-[48px] max-h-[48px] ${userInfo?.isBlocked && 'opacity-50 z-10'}`}
            character={firstName?.charAt(0)}
          />
          <div className="pl-3 w-[570px] min-w-[275px]">
            <div className="flex">
              <p
                className={`text-body1 font-semibold text-neutral-1 ${
                  userInfo?.isBlocked && 'opacity-50 z-10'
                }`}
              >{`${firstName} ${lastName}`}</p>
              {userInfo?.isBlocked && (
                <div className="bg-secondary-light2 w-[55px] h-[24px] text-body3-smbold text-secondary-bold2 pt-[3px] text-center border border-secondary-bold2 rounded ml-2 cursor-default">
                  Block
                </div>
              )}
            </div>
            <span className={`text-body2 ${userInfo?.isBlocked && 'opacity-50 z-10'}`}>
              {title}
            </span>
          </div>
          {isShowEditAction && (
            <div className="relative">
              <div
                className={`pl-7 cursor-pointer ${userInfo?.isBlocked && 'opacity-50 z-10'}`}
                onClick={handleClick}
                ref={btnRef}
              >
                <img src={IconDot} alt="Icon-Dot" className="min-h-[20px] min-w-[20px]" />
              </div>
              {isPopup && (
                <div ref={menuRef} className="absolute right-0 rounded-xl w-60 z-20">
                  <RowEditUser
                    userInfo={userInfo}
                    isBusinessInfo={isBusinessInfo}
                    isEditCarrierInfo={isEditCarrierInfo}
                    hiddenBlock={hiddenBlock}
                    isJustEdit={isJustEdit}
                    callback={() => {
                      document.removeEventListener('click', handleOutsideClick)
                    }}
                    handleClose={() => {
                      setIsPopup(!isPopup)
                    }}
                    handleRefetchData={handleRefetchData}
                    handleShowOverflow={handleShowOverflow}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={`${hidden} ${userInfo?.isBlocked && 'opacity-50 z-10'}`}>
          <hr className="w-full h-[1px] bg-neutral-6 my-5" />
          <div className="flex justify-start">
            <div className="mr-6">
              {propellerMail && <p className="text-body2 text-neutral-3 mb-4">Propeller gmail</p>}
              <p className="text-body2 text-neutral-3 mb-4">Business email</p>
              <p className="text-body2 text-neutral-3">Phone number</p>
            </div>
            <div className="ml-6 -mt-[3px]">
              {propellerMail && (
                <p className="text-body1 min-w-[256px] break-all font-semibold text-neutral-2 mb-3">
                  {propellerMail}
                </p>
              )}
              <p className="text-body1 min-w-[256px] break-all font-semibold text-neutral-2 mb-3">
                {businessMail}
              </p>
              <p className="text-body1 min-w-[256px] break-all font-semibold text-neutral-2">
                {phoneNumber}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
