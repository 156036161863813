import React from 'react'

import ButtonBase from '../../../../../components/partials/button'
import InputBase from '../../../../../components/partials/inputBase'
import { DEFAULT_LENGTH_JSON_RENDER } from '../../../../../constants/common'
import {
  ActivitiesGroupModel,
  CarrierGroupModel,
  JsonPacketGroupModel,
  SystemMonitoringHeaderModel,
} from '../admin/activitiesMngModels'

export type handleGetListFilterForAdminType = {
  group: CarrierGroupModel[] | ActivitiesGroupModel[] | JsonPacketGroupModel[]
  identify: string
}

type Props = {
  isOpenFilter?: boolean
  id?: string
  idSelected?: string
  objectGroupType?: handleGetListFilterForAdminType
  handleClickCancel?: () => void
  onRef?: React.Ref<HTMLDivElement> | null
  inputHandler?: (callBackValue: string, identify: string) => void
  handCheckedValue?: (value: string, identify: string) => void
  handleFilter?: () => void
  listCheckedInGroupType?: object | any
}

const styleJsonPacket = {
  display: '-webkit-box',
  WebkitLineClamp: '3',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
} as object

/**
 *
 * @param identify
 * @returns
 */
const classNameWrapperHandler = (identify: string) => {
  switch (identify) {
    case SystemMonitoringHeaderModel.TYPE_ACTIVITIES_LABEL:
      return 'max-h-[625px] left-[-130px] lg:left-[-115px]'
    case SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL:
      return 'max-h-[625px] left-[-70px]'
    case SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL:
      return 'max-h-[965px] left-[-246px]'
  }
}

const Filter: React.FC<Props> = ({
  isOpenFilter,
  id,
  idSelected,
  objectGroupType = { identify: '', group: [] },
  handleClickCancel = async () => null,
  onRef,
  inputHandler = () => null,
  handCheckedValue = () => null,
  handleFilter = () => null,
  listCheckedInGroupType,
}) => {
  return (
    <div id={id} className="relative">
      {isOpenFilter && id === idSelected && (
        <div
          ref={onRef}
          className={`absolute z-10 ${classNameWrapperHandler(
            objectGroupType?.identify,
          )} w-[400px] bg-white-50 rounded-2xl p-6 shadow-xl mt-3 top-5`}
        >
          <div>
            <InputBase
              placeholder="Search"
              className="min-w-fit w-[352px]"
              handleChange={(callBackValue) =>
                inputHandler(callBackValue, objectGroupType.identify)
              }
            />
            <div className={`overflow-y-auto max-h-[376px] mt-8 small-scrollbar`}>
              {objectGroupType?.group?.map((item: any, index: number) => (
                <div key={index} className="flex mt-2">
                  <input
                    id={item.key}
                    type="checkbox"
                    checked={listCheckedInGroupType?.listChecked?.includes(
                      objectGroupType?.identify === SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL
                        ? item?.partyId
                        : item.key,
                    )}
                    name={item.key}
                    className="w-5 h-5 mt-2 cursor-pointer accent-violet-700"
                    onChange={() =>
                      handCheckedValue(
                        objectGroupType?.identify === SystemMonitoringHeaderModel.TYPE_CARRIER_LABEL
                          ? item?.partyId
                          : item.key,
                        objectGroupType?.identify,
                      )
                    }
                  />
                  <label
                    htmlFor={item.key}
                    style={
                      objectGroupType?.identify ===
                      SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL
                        ? styleJsonPacket
                        : {}
                    }
                    className="mt-1.5 ml-3 w-[300px] break-all cursor-pointer"
                  >
                    {SystemMonitoringHeaderModel.TYPE_JSON_PACKET_LABEL
                      ? item.key.slice(0, DEFAULT_LENGTH_JSON_RENDER)
                      : item.key}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <hr className="mt-8 mb-6" />
          <div className="z-20 flex justify-between">
            <ButtonBase
              type="reset"
              className="w-[162px] h-[40px] bg-white border-2 border-violet-700 text-violet-800 hover:bg-violet-500"
              onClick={handleClickCancel}
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              type="submit"
              className="w-[162px] h-[40px] bg-violet-800 min-w-min"
              onClick={handleFilter}
            >
              Apply
            </ButtonBase>
          </div>
        </div>
      )}
    </div>
  )
}

export default Filter
