import React from 'react'
import classNames from 'classnames'

import iconApprove from '../../assets/images/icon-approve.svg'
import iconReject from '../../assets/images/icon-reject.svg'
import iconMerge from '../../assets/images/icon-merge.svg'
import iconPlus from '../../assets/images/icon-add.svg'
import { KIND } from '../../constants/common'

interface PropsAction {
  handleAdd: () => void
  handleMerge: () => void
  handleApprove: () => void
  handleReject: () => void
  organizationType: string
}

const RowActionNewUserRequest: React.FunctionComponent<PropsAction> = ({
  handleAdd = () => null,
  handleMerge = () => null,
  handleApprove = () => null,
  handleReject = () => null,
  organizationType,
}) => {
  const eDivClasses = classNames(
    `flex items-center p-2 hover:bg-neutral-7 hover:rounded-lg pointer-events-auto`,
  )

  return (
    <div className="relative h-auto p-3 border shadow-2xl w-60 border-neutral-7 bg-neutral-8 rounded-xl">
      {organizationType === KIND.AGENCIES && (
        <>
          <div className={`${eDivClasses} justify-start mb-1 flex`} onClick={handleAdd}>
            <div>
              <img src={iconPlus} alt="IconTrashAdd" />
            </div>
            <p className="ml-3">Add</p>
          </div>
          <hr className="my-1 border-t-neutral-7" />
          <div className={`${eDivClasses} justify-start mb-1 flex`} onClick={handleMerge}>
            <div>
              <img src={iconMerge} alt="IconTrashMerge" />
            </div>
            <p className="ml-3">Merge</p>
          </div>
          <hr className="my-1 border-t-neutral-7" />
        </>
      )}
      <div className={`${eDivClasses} justify-start mb-1 flex`} onClick={handleApprove}>
        <div>
          <img src={iconApprove} alt="IconTrashActive" />
        </div>
        <p className="ml-3">Approve</p>
      </div>
      <hr className="my-1 border-t-neutral-7" />
      <div className={`${eDivClasses} justify-start mb-1 flex`} onClick={handleReject}>
        <div>
          <img src={iconReject} alt="IconTrashInActive" />
        </div>
        <p className="ml-3">Reject</p>
      </div>
    </div>
  )
}

export default RowActionNewUserRequest
