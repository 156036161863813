import React from 'react'
import { Navigate } from 'react-router-dom'

const isAuthenticated = () => {
  const token = localStorage.getItem('accessTokenGg')
  return token ? true : false
}

export const HOCPrivateRoute = (Wrapped: any) => {
  return () => (isAuthenticated() ? <Wrapped /> : <Navigate to="/" />)
}
