import React, { useState } from 'react'
import Tabs from '../../components/partials/tabs'
import ApiDocs from './apisDocs'
import ApiExplorer from './apisExplorer'

type TabsType = {
  label: string
  index: number
  Component: React.FC<{ index: number }>
}[]

// Tabs Array
const tabs: TabsType = [
  {
    label: 'API Docs',
    index: 1,
    Component: ApiDocs,
  },
  {
    label: 'API Sandbox',
    index: 2,
    Component: ApiExplorer,
  },
]

const DeveloperDocs = () => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index)
  return (
    <Tabs
      selectedTab={selectedTab}
      pageName="Developer docs"
      onClick={setSelectedTab}
      tabs={tabs}
    />
  )
}

export default DeveloperDocs
