import React from 'react'

import BaseDialog from '../../../components/dialog'
import ButtonBase from '../../../components/partials/button'
import IconClose from '../../../assets/images/icon-times.svg'

type Props = {
  modalIsOpen?: boolean
  title?: string
  content?: string
  txtBtn?: string
  customStyles?: any
  handleCloseDialog?: () => void
  onHandleReFetchList?: () => void
  handleAction?: () => void
}

const defaultCustomStyles = {
  content: {
    width: '420px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '24px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    boxShadow: '0px 16px 32px rgba(0, 0, 0, 0.25)',
    background: '#fdfdfd',
    border: '1px solid #F2F2F2',
  },
}

const UserListDialog: React.FunctionComponent<Props> = ({
  modalIsOpen,
  handleCloseDialog,
  content,
  title,
  txtBtn,
  customStyles = null,
  handleAction,
}) => {
  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={customStyles || defaultCustomStyles}>
      <div>
        <span className="absolute right-4 top-4">
          <img src={IconClose} alt="" className="cursor-pointer" onClick={handleCloseDialog} />
        </span>
        <div className="mt-2 text-center">
          <p className="text-headline5 text-neutral-1">{title}</p>
          <p className="py-6 text-neutral-3 text-body1">{content}</p>
          <div className="flex justify-between gap-3">
            <ButtonBase
              type="button"
              className="w-full bg-primary-1 min-w-min"
              onClick={handleCloseDialog}
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              type="reset"
              className="w-full border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4 capitalize"
              onClick={handleAction}
            >
              {txtBtn}
            </ButtonBase>
          </div>
        </div>
      </div>
    </BaseDialog>
  )
}

export default UserListDialog
