import React, { useState } from 'react'
import NewUserRequestTable from '../admin-portal/table/newUserRequests'
import UserListTable from './components/UserListTable'

const UsersManagement = () => {
  const [isRefetchUserList, setIsRefetchUserList] = useState(false)

  return (
    <div className="px-20 py-10 md:px-10">
      <NewUserRequestTable setIsRefetchUserList={setIsRefetchUserList} />
      <hr className="my-10 border-t-neutral-6" />
      <UserListTable
        isRefetchUserList={isRefetchUserList}
        setIsRefetchUserList={setIsRefetchUserList}
      />
    </div>
  )
}

export default UsersManagement
