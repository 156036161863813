import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useForm, useFieldArray } from 'react-hook-form'
import ButtonBase from '../../../components/partials/button'
import { notify } from '../../../components/toastify'
import {
  DELETE_WEBHOOK_HEADER_BY_ADMIN,
  DELETE_WEBHOOK_HEADER_BY_CARRIER,
  SET_WEBHOOK_HEADER_BY_ADMIN,
  SET_WEBHOOK_HEADER_BY_CARRIER,
} from '../../../queries/adminPortal'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { useSession } from '../../../helpers/checkSession'
import iconPlus from '../../../assets/images/icon-plus.svg'
import iconTrash from '../../../assets/images/icon-trash-gray.svg'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store'

interface headerConfigModel {
  isChecked: boolean
  key: string
  value: string
  webhookHeaderId: string
}

interface Props {
  headerConfigData?: headerConfigModel[]
  refetchGetWebhookInfo?: () => void
  partyId?: string
}

const HeaderConfig: React.FC<Props> = ({
  headerConfigData,
  refetchGetWebhookInfo = () => null,
  partyId,
}) => {
  const userInfoState = useSelector((state: RootState) => state.userReducers?.userInfo)
  const isAdmin = userInfoState?.role?.name === 'Admin'
  const { handleErrorSession } = useSession()
  const [setWebhookHeaderByAdmin] = useMutation(SET_WEBHOOK_HEADER_BY_ADMIN)
  const [deleteWebhookHeaderByAdmin] = useMutation(DELETE_WEBHOOK_HEADER_BY_ADMIN)
  const [setWebhookHeaderByCarrier] = useMutation(SET_WEBHOOK_HEADER_BY_CARRIER)
  const [deleteWebhookHeaderByCarrier] = useMutation(DELETE_WEBHOOK_HEADER_BY_CARRIER)
  const [countConfig, setCountConfig] = useState<number>(headerConfigData?.length as number)

  const { register, control, handleSubmit, reset } = useForm({
    defaultValues: {
      headerConfig: headerConfigData as any,
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'headerConfig',
  })

  const onSubmit = handleSubmit(async (data) => {
    const variablesAdmin = {
      data: {
        partyId: partyId,
        kind: 'carriers',
        webhookHeader: data?.headerConfig,
      },
    }
    const variables = {
      data: data?.headerConfig,
    }
    try {
      const dataRes = isAdmin
        ? await setWebhookHeaderByAdmin({ variables: variablesAdmin })
        : await setWebhookHeaderByCarrier({ variables: variables })
      if (dataRes) {
        notify(
          countConfig > 1
            ? MESSAGES.SUCCESS.S_SET_TWO_HEADER_CONFIG
            : MESSAGES.SUCCESS.S_SET_ONE_HEADER_CONFIG,
          STATUS.SUCCESS,
        )
        await refetchGetWebhookInfo()
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_SOMETHING_WENT_WRONG_SYSTEM)
    }
  })

  const onAddHeader = () => {
    append({ key: '', value: '', isChecked: false, webhookHeaderId: '' })
    setCountConfig(countConfig + 1)
  }

  const onRemoveHeader = async (index: number, data: any) => {
    if (data?.webhookHeaderId) {
      const variables = {
        webhookHeaderId: data?.webhookHeaderId,
      }
      try {
        const dataRes = isAdmin
          ? await deleteWebhookHeaderByAdmin({ variables: variables })
          : await deleteWebhookHeaderByCarrier({ variables: variables })
        if (dataRes) {
          remove(index)
          setCountConfig(countConfig - 1)
          await refetchGetWebhookInfo()
        }
      } catch (error) {
        handleErrorSession(error, MESSAGES.ERROR.E_SOMETHING_WENT_WRONG_SYSTEM)
      }
    } else {
      remove(index)
      setCountConfig(countConfig - 1)
    }
  }

  useEffect(() => {
    reset({
      headerConfig: headerConfigData,
    })
    setCountConfig(headerConfigData?.length as number)
  }, [headerConfigData])

  return (
    <div>
      <div className="flex">
        <p className="min-w-[206px] pt-4 font-semibold text-body1 text-neutral-3 align-top">
          Optional header
        </p>
        <div>
          {fields?.map((item, index) => {
            return (
              <div key={item.id} className="flex mb-5">
                <input
                  className="w-5 h-5 mt-4 mr-5 cursor-pointer md:w-[45px] accent-violet-700"
                  type="checkbox"
                  {...register(`headerConfig.${index}.isChecked`)}
                />
                <input
                  {...register(`headerConfig.${index}.key`, { required: false })}
                  className="w-[320px] md:max-w-[184px] lg:w-[240px] text-body1 text-neutral-3 mr-5 focus:border-current focus:ring-0 relative inline-flex appearance-none border-2 font-bold placeholder-neutral-5 placeholder:font-medium focus:placeholder-neutral-5 transition-colors ease-in-out rounded-xl h-[52px] px-4 leading-tight focus:outline-none focus:ring-neutral-5 focus:shadow-outline"
                />
                <input
                  {...register(`headerConfig.${index}.value`, { required: false })}
                  className="w-[663px] md:max-w-[184px] lg:w-[328px] text-body1 text-neutral-3 mr-5 focus:border-current focus:ring-0 relative inline-flex appearance-none border-2 font-bold placeholder-neutral-5 placeholder:font-medium focus:placeholder-neutral-5 transition-colors ease-in-out rounded-xl h-[52px] px-4 leading-tight focus:outline-none focus:ring-neutral-5 focus:shadow-outline"
                />
                <img
                  src={iconTrash}
                  alt="iconTrash"
                  className="pr-3 cursor-pointer"
                  onClick={() => onRemoveHeader(index, item)}
                />
              </div>
            )
          })}
        </div>
      </div>
      <div className="flex justify-end">
        <ButtonBase
          type="reset"
          className={`flex mx-5 justify-start bg-neutral-8 w-[181px] p-0 border-2 border-primary-1 text-primary-1 hover:bg-primary-shade4 ${
            countConfig >= 2 && 'cursor-not-allowed'
          }`}
          onClick={() => countConfig < 2 && onAddHeader()}
        >
          <img src={iconPlus} alt="iconPlus" className="pl-6 pr-3" />
          Add header
        </ButtonBase>
        <ButtonBase
          type="reset"
          className="w-[148px] bg-neutral-8 p-0 border-2 border-primary-1 text-primary-1 hover:bg-primary-shade4"
          onClick={onSubmit}
        >
          Save header
        </ButtonBase>
      </div>
      <div className="flex my-6">
        <div className="w-[246px]" />
        <div className="w-[1024px] lg:w-[605px] md:max-w-[430px] border-b-[1px] border-neutral-6" />
      </div>
    </div>
  )
}

export default HeaderConfig
