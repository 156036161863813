import React from 'react'
import classNames from 'classnames'

interface AvatarProps {
  className?: string
  avatar?: string
  character: string
}

const characterClass = classNames(`w-[48px] h-[48px] rounded-[60px]`)

const Avatar: React.FC<AvatarProps> = ({ className, avatar, character }) => {
  return avatar ? (
    <img className={`${className ? className : ''} rounded-full`} src={avatar} alt="Avatar" />
  ) : (
    <div className={`${characterClass} bg-primary-shade5 min-w-[48px] h-[48px]`}>
      <p className="flex justify-center align-center text-signature text-indicator-purple">
        {character}
      </p>
    </div>
  )
}

export default Avatar
