import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'
import classNames from 'classnames'

import InputBase from '../../../components/partials/inputBase'
import IconClear from '../../../assets/images/ic-clear-input.svg'
import Loading from '../../../components/loading'
import { GET_SUGGEST_PRINCIPAL, GET_SUGGEST_P_ACC } from '../../../queries/adminPortal'
import { OptionType } from './dropdow'
import { InputValueType, options } from './formAutocompleInput'
import IconLocation from '../../../assets/images/ic_location.svg'
import { useDispatch } from 'react-redux'
import { clearSearchPrincipal } from '../../../redux/actions/adminPortal'

interface Props {
  classInput?: string
  setIsFocus?: (focus: boolean) => void
  setIsDisable?: (focus: boolean) => void
  setIsValueInputEmp?: (focus: boolean) => void
  setIsValueInputPrincipalSelected?: (focus: { idPrincipal: string; namePrincipal: string }) => void
  setIsValueInputPAccountSelectedFrom?: (focus: {
    idPrincipalAccountFrom: string
    namePrincipalAccountFrom: string
  }) => void
  setIsValueInputPAccountSelectedTo?: (focus: {
    idPrincipalAccountTo: string
    namePrincipalAccountTo: string
  }) => void
  setInputValue?: (value: string | InputValueType) => void
  placehoderText?: string
  dropdownValue?: OptionType
  isDropdown?: boolean
  checkValueInput?: (val: string) => void
  handleClearPAccTo?: () => void
  handleClearPrincipalSelected?: () => void
  isHandleSearch?: boolean
}

const AutocompletedInput: React.FC<Props> = ({
  classInput = '',
  setIsFocus = () => false,
  setIsDisable = () => false,
  setIsValueInputEmp = () => false,
  setIsValueInputPrincipalSelected = () => {},
  setIsValueInputPAccountSelectedFrom = () => {},
  setIsValueInputPAccountSelectedTo = () => {},
  isDropdown = false,
  setInputValue = () => '',
  placehoderText = 'Search Principal Account / Principal',
  dropdownValue,
  checkValueInput = () => null,
  handleClearPAccTo = () => null,
  handleClearPrincipalSelected = () => null,
  isHandleSearch,
}) => {
  const [valueInput, setValueInput] = useState<any>('')
  const [address, setAddress] = useState<any>('')
  const [isOpen, setIsOpen] = useState(false)
  const [isFocusInput, setIsFocusInput] = useState<boolean>(false)
  const suggestRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isOpenSuggest, setIsOpenSuggest] = useState<boolean>(false)
  const [suggestData, setSuggestData] = useState<any>(null)
  const dispatch = useDispatch()

  const [
    handleGetSuggestPAccount,
    { data: getSuggestPAccountData, loading: getSuggestPAccountLoading },
  ] = useLazyQuery(GET_SUGGEST_P_ACC, {
    fetchPolicy: 'no-cache',
  })
  const [
    handleGetSuggestPrincipal,
    { data: getSuggestPrincipalData, loading: getSuggestPrincipalLoading },
  ] = useLazyQuery(GET_SUGGEST_PRINCIPAL, {
    fetchPolicy: 'no-cache',
  })

  const handleShowValueSuggest = (value: any) => {
    return handleGetSuggestPAccount({
      variables: {
        data: {
          principalAccountName: value,
        },
      },
    })
  }

  const handleShowValueSuggestPrincipal = (value: any) => {
    return handleGetSuggestPrincipal({
      variables: {
        data: {
          principalName: value,
        },
      },
    })
  }

  const handleChangeInput = (value: string) => {
    setValueInput(value)
    setAddress('')
    setInputValue(value)
    if (dropdownValue?.value === options[1].value) {
      if (value) {
        setIsValueInputPrincipalSelected({
          idPrincipal: '',
          namePrincipal: '',
        })
      }
    }
    if (!value) {
      handleClearPAccTo()
      handleClearPrincipalSelected()
    }
    if (isDropdown) {
      debounceSuggestion(value)
    }
    if (dropdownValue?.value === options[0].value && !isDropdown) {
      debounceSuggestion(value)
    }
    if (dropdownValue?.value === options[1].value && !isDropdown) {
      debounceSuggestionPrincipal(value)
    }
    setIsValueInputEmp(true)
    setIsDisable(false)
    checkValueInput(value)
  }

  const handleUpdateValueInput = (item: any) => {
    setValueInput(item.principalAccountName || item.principalName)
    setAddress(item.principalAccountAddressLine1 || item.principalAddressLine1)
    if (dropdownValue?.value === options[1].value) {
      setIsValueInputPrincipalSelected({
        idPrincipal: item.principalId,
        namePrincipal: item.principalName,
      })
    } else if (dropdownValue?.value === options[0].value) {
      setIsValueInputPAccountSelectedFrom({
        idPrincipalAccountFrom: item.principalAccountId,
        namePrincipalAccountFrom: item.principalAccountName,
      })
    }
    setIsValueInputPAccountSelectedTo({
      idPrincipalAccountTo: item.principalAccountId,
      namePrincipalAccountTo: item.principalAccountName,
    })
    setInputValue({
      name: item.principalAccountName || item.principalName,
      address: item.principalAccountAddressLine1 || item.principalAddressLine1,
    })
    setIsOpenSuggest(false)
    setIsFocusInput(false)
    setIsOpen(false)
  }

  const debounceSuggestion = useCallback(
    debounce((nextValue) => handleShowValueSuggest(nextValue)),
    [],
  )

  const debounceSuggestionPrincipal = useCallback(
    debounce((nextValue) => handleShowValueSuggestPrincipal(nextValue)),
    [],
  )

  const handleFocusInput = () => {
    setIsFocus(true)
    setIsFocusInput(true)
  }
  const handleBlurInput = () => {
    setIsFocus(false)
  }

  const resetValueInput = () => {
    setValueInput('')
    setIsValueInputPrincipalSelected({ idPrincipal: '', namePrincipal: '' })
    setIsValueInputPAccountSelectedFrom({
      idPrincipalAccountFrom: '',
      namePrincipalAccountFrom: '',
    })
    setIsValueInputPAccountSelectedTo({ idPrincipalAccountTo: '', namePrincipalAccountTo: '' })
    setInputValue('')
    setIsFocusInput(false)
    setIsDisable(false)
    isHandleSearch && dispatch(clearSearchPrincipal())
  }

  useEffect(() => {
    if (!valueInput.length && isFocusInput) {
      setIsOpenSuggest(false)
    }
    if (valueInput.length && isFocusInput) {
      setIsOpenSuggest(true)
    }
  }, [valueInput, isFocusInput])

  useEffect(() => {
    resetValueInput()
  }, [dropdownValue])

  useEffect(() => {
    setSuggestData(getSuggestPAccountData?.getSuggestionPrincipalAccount)
  }, [getSuggestPAccountData])

  useEffect(() => {
    setSuggestData(getSuggestPrincipalData?.getSuggestionPrincipal)
  }, [getSuggestPrincipalData])

  useEffect(() => {
    const onHandleClickOutSide = (e: any) => {
      if (
        suggestRef &&
        !suggestRef.current?.contains(e.target) &&
        inputRef &&
        !inputRef.current?.contains(e.target)
      ) {
        setIsOpenSuggest(false)
        setIsOpen(false)
      }
    }

    document.addEventListener('click', onHandleClickOutSide)
    return () => {
      document.removeEventListener('click', onHandleClickOutSide)
    }
  }, [isOpenSuggest])

  const openInput = (event: any) => {
    event.stopPropagation()
    setIsOpen(true)
    setIsFocus(true)
  }

  return (
    <div className="relative w-full flex items-center">
      <div ref={inputRef} className="flex w-full h-full" onClick={openInput}>
        {!isOpen && !valueInput && (
          <div className="flex items-baseline ml-4 py-3 text-neutral-5 text-body1-med h-full whitespace-nowrap">
            {placehoderText}
          </div>
        )}
        {isOpen === false && valueInput && (
          <div className="absolute leading-[30px] pl-4 pr-10 py-3 max-w-full h-full whitespace-nowrap overflow-hidden text-ellipsis">
            <span className="mr-3 text-neutral-1 font-bold">{valueInput}</span>
            {address && (
              <>
                <span className="mr-4 before:absolute before:min-h-[4px] before:top-[50%] before:translate-y-[-50%] before:min-w-[4px] before:bg-neutral-5 before:rounded-full"></span>
                <span className="text-neutral-5 text-body2">{address}</span>
              </>
            )}
          </div>
        )}
        {isOpen === true && (
          <InputBase
            autoFocus={true}
            handleChange={handleChangeInput}
            value={valueInput}
            className={classInput}
            onFocus={handleFocusInput}
            onBlur={handleBlurInput}
            placeholder={placehoderText}
          />
        )}
      </div>
      {valueInput && (
        <button
          onClick={resetValueInput}
          className="absolute top-1/2 -translate-y-1/2 bg-neutral-8 right-0 p-3 pl-2 h-full rounded-full"
        >
          <img src={IconClear} alt="icon-clear" width={24} height={24} />
        </button>
      )}
      <div
        className={classNames(
          { hidden: !isOpenSuggest },
          'p-2 pr-1 text-body1 text-neutral-3 bg-neutral-8 border-neutral-7 rounded-xl shadow-dropdown absolute bottom-0 translate-y-[102%] w-full z-10',
        )}
        ref={suggestRef}
      >
        <ul className="max-h-[372px] overflow-auto small-scrollbar pr-1">
          {(getSuggestPAccountLoading || getSuggestPrincipalLoading) && (
            <Loading className="relative p-[5px]" height={30} width={30} />
          )}
          {!suggestData?.length && !getSuggestPAccountLoading && !getSuggestPrincipalLoading && (
            <p className="p-2">No result found</p>
          )}
          {suggestData?.length > 0 &&
            !getSuggestPAccountLoading &&
            !getSuggestPrincipalLoading &&
            suggestData?.map((item: any, index: number) => (
              <li
                key={index}
                onClick={() => handleUpdateValueInput(item)}
                className="mb-1 last:mb-0 p-2 rounded-lg hover:bg-neutral-7"
              >
                <span>{item.principalAccountName || item.principalName}</span>
                <p className="text-body2 text-neutral-4 flex mt-2">
                  <img src={IconLocation} alt="Location" className="mr-2" />
                  {item.principalAccountAddressLine1 ||
                    item.principalAddressLine1 ||
                    'No available data'}
                </p>
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default AutocompletedInput
