import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import IconDropdow from '../../../assets/images/icon-chevron-down.svg'
export type OptionType = {
  label: string
  value: string | number
}
interface Props {
  label?: string
  value?: string
  options?: OptionType[]
  onChange?: void
  classbtn?: string
  setIsFocus?: (focus: boolean) => void
  setDropdownValue?: (value: OptionType) => void
}

const Dropdown: React.FC<Props> = ({
  options = [],
  classbtn = '',
  setIsFocus = () => false,
  setDropdownValue = () => options[0],
}) => {
  const btnRef = useRef<HTMLButtonElement>(null)
  const dropdownRef = useRef<HTMLUListElement>(null)
  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false)
  const [dropdownData, setDropdownData] = useState<OptionType>(options[0])

  const handleOpenDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown)
    setIsFocus(!isOpenDropdown)
  }

  const handleUpdateDropdow = (item: OptionType) => {
    setDropdownData(item)
    setIsOpenDropdown(false)
    setIsFocus(false)
  }

  useEffect(() => {
    const onHandleClickOutSide = (e: any) => {
      if (
        btnRef &&
        !btnRef.current?.contains(e.target) &&
        dropdownRef &&
        !dropdownRef.current?.contains(e.target)
      ) {
        setIsOpenDropdown(false)
      }
    }

    document.addEventListener('click', onHandleClickOutSide)
    return () => {
      document.removeEventListener('click', onHandleClickOutSide)
    }
  }, [isOpenDropdown])

  useEffect(() => {
    setDropdownValue(dropdownData)
  }, [dropdownData])

  return (
    <div className="relative w-fit">
      <button
        className={classNames(
          twMerge(
            `flex justify-between py-3 pl-4 pr-3 h-full w-[210px] items-center border-neutral-5 rounded-xl ${classbtn}`,
          ),
        )}
        onClick={handleOpenDropdown}
        ref={btnRef}
      >
        <span className="mr-2 text-body1 text-neutral-3 font-semibold">{dropdownData.label}</span>
        <img src={IconDropdow} alt="icon-dropdown" />
      </button>
      <ul
        className={classNames(
          { hidden: !isOpenDropdown },
          'text-body1 text-neutral-3 p-2 mt-[2px] bg-neutral-8 border-neutral-7 rounded-xl shadow-dropdown absolute w-full z-10',
        )}
        ref={dropdownRef}
      >
        {options?.map((item, index) => (
          <li
            key={index}
            className="mb-1 last:mb-0 p-2 rounded-lg hover:bg-neutral-7"
            onClick={() => handleUpdateDropdow(item)}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  )
}
export default Dropdown
