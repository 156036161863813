import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'

import { FormInput } from '../../../components/partials/formInput'
import { alphabetPattern } from '../../../utils/regex'
import { MESSAGES, STATUS } from '../../../constants/messages'
import ButtonBase from '../../../components/partials/button'
import BaseDialog from '../../../components/dialog'
import iconTimes from '../../../assets/images/icon-times.svg'
import { customStyles } from '../../../constants/customStyles'
import { useSession } from '../../../helpers/checkSession'
import { EDIT_MY_ACCOUNT } from '../../../queries/myAccount'
import { notify } from '../../../components/toastify'
import { RootState } from '../../../store'
import { setUserInfo } from '../../../redux/actions/users'

export type EditFormFields = {
  firstName: string
  lastName: string
}

interface PropsAction {
  modalIsOpen?: boolean
  hiddenBlock?: string
  handleEdit?: () => void
  handleCloseDialog?: (val: boolean) => void
  userInfo?: object | any
}

const EditUserDialog: React.FunctionComponent<PropsAction> = ({
  modalIsOpen,
  handleCloseDialog = () => null,
  userInfo,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<EditFormFields>()

  const dispatch = useDispatch()
  const userInfoState = useSelector((state: RootState) => state.userReducers?.userInfo)

  const { handleErrorSession } = useSession()

  const [editMyAccount] = useMutation(EDIT_MY_ACCOUNT)

  const onSubmit = handleSubmit(async (data) => {
    const variables = {
      data: {
        firstName: data?.firstName.trim(),
        lastName: data?.lastName.trim(),
      },
    }
    try {
      const dataRes = await editMyAccount({ variables: variables })
      if (dataRes) {
        notify(MESSAGES.SUCCESS.S_UPDATED_MY_ACCOUNT, STATUS.SUCCESS)
        handleCloseDialog(false)
        reset()
      }
      dispatch(setUserInfo({ ...userInfoState, ...dataRes.data.modifyAdminInfo }))
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_EDIT_MY_ACCOUNT)
    }
  })

  const handleTrimValues = () => {
    setValue('firstName', getValues('firstName').trim())
    setValue('lastName', getValues('lastName').trim())
  }

  const handleSaveChanges = async () => {
    await handleTrimValues()
    await onSubmit()
  }

  const handleCloseEditUserDialog = () => {
    handleCloseDialog(false)
    reset()
  }

  useEffect(() => {
    setValue('firstName', userInfo?.firstName)
    setValue('lastName', userInfo?.lastName)
  }, [userInfo])

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={customStyles}>
      <div className="w-[758px] p-1">
        <div className="float-right">
          <img
            src={iconTimes}
            alt=""
            className="cursor-pointer"
            onClick={handleCloseEditUserDialog}
          />
        </div>
        <p className="text-headline5 mb-2">Edit user</p>
        <hr className="border-t-neutral-5 my-4" />
        <form onSubmit={handleSaveChanges}>
          <div className="grid grid-cols-2 gap-2">
            <div className="mt-2">
              <p className="text-hairline2 mb-2">First name</p>
              <FormInput<EditFormFields>
                id="firstName"
                type="text"
                name="firstName"
                label="First Name"
                placeholder="Enter your first name"
                className="mb-2"
                inputClassName="w-[364px] mt-1"
                register={register}
                rules={{
                  required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                  pattern: alphabetPattern,
                  maxLength: 200,
                }}
                errors={errors}
              />
            </div>
            <div className="mt-2 ml-2">
              <p className="text-hairline2 mb-2">Last name</p>
              <FormInput<EditFormFields>
                id="lastName"
                type="text"
                name="lastName"
                label="Last Name"
                placeholder="Enter your last name"
                className="mb-2"
                inputClassName="w-[364px] mt-1"
                register={register}
                rules={{
                  required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                  pattern: alphabetPattern,
                  maxLength: 200,
                }}
                errors={errors}
              />
            </div>
          </div>
          <hr className="border-t-neutral-6 mt-4 mb-2" />
        </form>
        <div className="flex w-[750px]">
          <ButtonBase
            type="submit"
            className="w-[374px] my-4 bg-primary-1 min-w-min text-center"
            onClick={handleSaveChanges}
            bgDisabled="bg-primary-shade3"
          >
            Save change
          </ButtonBase>
          <ButtonBase
            type="submit"
            className="w-[374px] my-4 ml-3 justify-around border-2 border-primary-1 text-primary-1 bg-white-50 hover:bg-primary-shade4"
            onClick={handleCloseEditUserDialog}
            bgDisabled="bg-primary-shade3"
          >
            Cancel
          </ButtonBase>
        </div>
      </div>
    </BaseDialog>
  )
}

export default EditUserDialog
