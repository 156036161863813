import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Tabs, { TabsType } from '../../components/partials/tabs'
import { KIND, ROLE } from '../../constants/common'
import { RootState } from '../../store'
import ActivitiesManagement from '../activitiesManagement'
import DevelopmentManagement from '../developmentManagement'
import MyAgencies from '../myAgencies'
import PrincipalAccountManagement from '../principal-account-management'

const DeveloperPortal = () => {
  const userInfoState = useSelector((state: RootState) => state.userReducers?.userInfo)

  // Tabs Array
  const tabs: TabsType = [
    {
      label: 'Activities management',
      index: 1,
      Component: ActivitiesManagement,
      roles: [ROLE.Admin, ROLE.Developer, ROLE.DeveloperAccountManager],
      kinds: [null, KIND.CARRIERS, KIND.AGENCIES],
    },
    {
      label: 'Development management',
      index: 2,
      Component: DevelopmentManagement,
      roles: [ROLE.Admin, ROLE.Developer, ROLE.DeveloperAccountManager],
      kinds: [null, KIND.CARRIERS, KIND.AGENCIES],
    },
    {
      label: 'My agencies',
      index: 3,
      Component: MyAgencies,
      roles: [ROLE.Admin, ROLE.Developer],
      kinds: [null, KIND.AGENCIES],
    },
    {
      label: 'Principal Account management',
      index: 4,
      Component: PrincipalAccountManagement,
      roles: [ROLE.Admin, ROLE.AccountManager, ROLE.DeveloperAccountManager],
      kinds: [null, KIND.CARRIERS],
    },
  ]

  const [selectedTab, setSelectedTab] = useState<number>(1)
  const [listTabsForDev, setListTabsForDev] = useState<any[]>([])

  React.useEffect(() => {
    const list = tabs?.filter(
      (item) =>
        item.roles?.includes(userInfoState?.role?.name) &&
        item?.kinds?.includes(userInfoState?.kind),
    ) as any[]
    setListTabsForDev(list)
    setSelectedTab(list[0]?.index)
  }, [])

  return (
    <Tabs
      selectedTab={selectedTab}
      pageName="Developer portal"
      onClick={setSelectedTab}
      tabs={listTabsForDev}
    />
  )
}

export default DeveloperPortal
