import React, { FC, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { HOCWithPermission } from './PermissionsProtector'
import { HOCPrivateRoute } from './privateRoute'
import { PageRoute } from './routerInterface'
import { useSelector } from 'react-redux'
import { RootState } from '../store'
import { notify } from '../components/toastify'
import { MESSAGES, STATUS } from '../constants/messages'
import { useSession } from '../helpers/checkSession'

type RouterProps = {
  routes: PageRoute[]
}

const renderRoute = (routes: PageRoute[]) => {
  return routes.map((route: PageRoute, index: number) => {
    const PermissionRoute = HOCWithPermission(route.element)

    // Redirect router
    if (route.redirect && !route.element) {
      route.element = () => <Navigate to={route?.redirect || ''} />
    }

    // Route with permission
    if (route.permission) {
      route.element = () => <PermissionRoute permission={route.permission} shouldNavigate={true} />
    }

    const PrivateRoute = HOCPrivateRoute(route.element)
    const ContentRoute = route.element as React.ElementType

    return (
      <Route
        key={index}
        path={route.path}
        element={route.isProtected ? <PrivateRoute /> : <ContentRoute />}
      >
        {route.children && renderRoute(route.children)}
      </Route>
    )
  })
}

export const RouterOutlet: FC<RouterProps> = ({ routes }) => {
  const { timeOutSession } = useSession()
  const isTimeOut = useSelector((state: RootState) => state.userReducers.isTimeOut)

  useEffect(() => {
    if (!isTimeOut) return
    notify(MESSAGES.WARNING.W_TIMEOUT_SESSION, STATUS.WARNING)
    setTimeout(() => {
      timeOutSession()
    }, 1000)
  }, [isTimeOut])

  return <Routes>{renderRoute(routes)}</Routes>
}
