import {
  ActivitiesGroupModel,
  JsonPacketGroupModel,
} from '../../pages/developer-portal/table/activities-log/component/activitiesLogModel'
import { CarrierGroupModel } from '../../pages/developer-portal/table/activities-log/component/carrierModel'
import {
  ADMIN_ACTIVITIES_TYPE_GROUP,
  ADMIN_REQUEST_CARRIER,
  ADMIN_REQUEST_FROM_DATE,
  ADMIN_REQUEST_JSON_PACKET,
  ADMIN_REQUEST_TO_DATE,
  CLEAR_DATETIME_FILTER,
  CLEAR_SEARCH_PRINCIPAL,
  SEARCH_PRINCIPAL,
  VALUE_SEARCH_PRINCIPAL_NAME,
  CLEAR_VALUE_SEARCH_PRINCIPAL_NAME,
  VALUE_SEARCH_PRINCIPAL_ADDRESS,
  CLEAR_VALUE_SEARCH_PRINCIPAL_ADDRESS,
  OPEN_PRINCIPAL_ACCOUNT,
  CLOSE_PRINCIPAL_ACCOUNT,
  GET_PRINCIPAL_ACCOUNT_DETAIL,
  CLEAR_PRINCIPAL_ACCOUNT_DETAIL,
  GET_PRINCIPAL_ACCOUNT_INFO_DETAIL,
  CLEAR_PRINCIPAL_ACCOUNT_INFO_DETAIL,
  UPDATE_TAKE_ITEM_SEARCH_PRINCIPAL_ACCOUNT,
  CLEAR_TAKE_ITEM_SEARCH_PRINCIPAL_ACCOUNT,
  COUNT_SEARCH_PRINCIPAL_ACCOUNT,
  CLEAR_COUNT_SEARCH_PRINCIPAL_ACCOUNT,
  ADMIN_REQUEST_AMS,
  ADMIN_BY_AMS_REQUEST_FROM_DATE,
  ADMIN_BY_AMS_REQUEST_TO_DATE,
  CLEAR_DATE_TIME_FILTER_BY_AMS,
} from './actionTypes'

export const setCarrierForAdmin = (payload: CarrierGroupModel[]) => {
  return {
    type: ADMIN_REQUEST_CARRIER,
    payload: payload,
  }
}

export const setAMSForAdminByAMS = (payload: CarrierGroupModel[]) => {
  return {
    type: ADMIN_REQUEST_AMS,
    payload: payload,
  }
}

export const setActivitiesGroupTypeForAdmin = (payload: ActivitiesGroupModel[]) => {
  return {
    type: ADMIN_ACTIVITIES_TYPE_GROUP,
    payload: payload,
  }
}

export const setActivitiesGroupTypeForAdminByAMS = (payload: ActivitiesGroupModel[]) => {
  return {
    type: ADMIN_ACTIVITIES_TYPE_GROUP,
    payload: payload,
  }
}

export const setRequestJsonPacketForAdmin = (payload: JsonPacketGroupModel[]) => {
  return {
    type: ADMIN_REQUEST_JSON_PACKET,
    payload: payload,
  }
}

export const setRequestJsonPacketForAdminByAMS = (payload: JsonPacketGroupModel[]) => {
  return {
    type: ADMIN_REQUEST_JSON_PACKET,
    payload: payload,
  }
}

export const setFromDateForAdmin = (payload: object) => {
  return {
    type: ADMIN_REQUEST_FROM_DATE,
    payload: payload,
  }
}

export const setFromDateForAdminByAMS = (payload: object) => {
  return {
    type: ADMIN_BY_AMS_REQUEST_FROM_DATE,
    payload: payload,
  }
}

export const setToDateForAdmin = (payload: object) => {
  return {
    type: ADMIN_REQUEST_TO_DATE,
    payload: payload,
  }
}

export const setToDateForAdminByAMS = (payload: object) => {
  return {
    type: ADMIN_BY_AMS_REQUEST_TO_DATE,
    payload: payload,
  }
}

export const clearDateTimeFilterForAdmin = () => {
  return {
    type: CLEAR_DATETIME_FILTER,
  }
}

export const clearDateTimeFilterForAdminByAMS = () => {
  return {
    type: CLEAR_DATE_TIME_FILTER_BY_AMS,
  }
}

export const searchPrincipal = (payload: any, loadMore?: number) => {
  return {
    type: SEARCH_PRINCIPAL,
    payload,
    loadMore,
  }
}
export const clearSearchPrincipal = () => {
  return {
    type: CLEAR_SEARCH_PRINCIPAL,
  }
}

export const setValueSearchPrincipalName = (payload: any) => {
  return {
    type: VALUE_SEARCH_PRINCIPAL_NAME,
    payload,
  }
}

export const clearValueSearchPrincipalName = () => {
  return {
    type: CLEAR_VALUE_SEARCH_PRINCIPAL_NAME,
  }
}

export const setValueSearchPrincipalAddress = (payload: any) => {
  return {
    type: VALUE_SEARCH_PRINCIPAL_ADDRESS,
    payload,
  }
}

export const clearValueSearchPrincipalAddress = () => {
  return {
    type: CLEAR_VALUE_SEARCH_PRINCIPAL_ADDRESS,
  }
}

export const openPrincipalAccountDetail = (payload: any) => {
  return {
    type: OPEN_PRINCIPAL_ACCOUNT,
    payload,
  }
}

export const closePrincipalAccountDetail = () => {
  return {
    type: CLOSE_PRINCIPAL_ACCOUNT,
  }
}

export const getPrincipalAccountDetail = (payload: any) => {
  return {
    type: GET_PRINCIPAL_ACCOUNT_DETAIL,
    payload,
  }
}

export const clearPrincipalAccountDetail = () => {
  return {
    type: CLEAR_PRINCIPAL_ACCOUNT_DETAIL,
  }
}

export const getPrincipalAccountInfoDetail = (payload: any) => {
  return {
    type: GET_PRINCIPAL_ACCOUNT_INFO_DETAIL,
    payload,
  }
}

export const clearPrincipalAccountInfoDetail = () => {
  return {
    type: CLEAR_PRINCIPAL_ACCOUNT_INFO_DETAIL,
  }
}

export const updateTakeItemSearchPrincipalAccount = (payload: any) => {
  return {
    type: UPDATE_TAKE_ITEM_SEARCH_PRINCIPAL_ACCOUNT,
    payload,
  }
}

export const clearTakeItemSearchPrincipalAccount = () => {
  return {
    type: CLEAR_TAKE_ITEM_SEARCH_PRINCIPAL_ACCOUNT,
  }
}

export const countSearchPrincipalAccount = () => {
  return {
    type: COUNT_SEARCH_PRINCIPAL_ACCOUNT,
  }
}

export const clearCountSearchPrincipalAccount = () => {
  return {
    type: CLEAR_COUNT_SEARCH_PRINCIPAL_ACCOUNT,
  }
}
