import React, { useEffect, useRef, useState, memo } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import StatusBase, { TableName } from '../../../components/partials/statusAction'
import IconDot from '../../../assets/images/icon-dot.svg'
import IconFilter from '../../../assets/images/icon-filter.svg'
import iconFiltered from '../../../assets/images/icon-filtered.svg'
import InputBase from '../../../components/partials/inputBase'
import ButtonBase from '../../../components/partials/button'
import { KIND } from '../../../constants/common'
import {
  DELETE_USER,
  DELETE_USER_PRODUCTION,
  GET_TOTAL_USER,
  GET_USER_LIST,
  UPDATE_BLOCK_ACCESS_USER,
} from '../../../queries/adminPortal'
import Loading from '../../../components/loading'
import { useSession } from '../../../helpers/checkSession'
import { tableHeaderUserList } from './tableHeaderUserList'
import RowActionUserList from './RowActionUserList'
import { contentDlgInterface, TxtAction, VariablesUserList } from './interface'
import UserListDialog from './UserListDialog'
import { CODE_TIMEOUT } from '../../../constants/statusCode'
import { clientProd } from '../../../network/apolloClient'
import { notify } from '../../../components/toastify'
import { MESSAGES, STATUS } from '../../../constants/messages'
import EditAccountInfoDialog from '../../admin-portal/dialog/editAccountInfo'

interface Props {
  setIsRefetchUserList: (refetchUser: boolean) => void
  isRefetchUserList: boolean
}

const UserListTable: React.FC<Props> = ({ setIsRefetchUserList, isRefetchUserList }) => {
  const menuRef = useRef<HTMLDivElement>(null)
  const filterRef = useRef<HTMLDivElement>(null)
  const checkboxRef = useRef<HTMLDivElement>(null)
  const [modalIsOpenEditCarrierInfo, setIsModelOpenEditCarrierInfo] = useState<boolean>(false)
  const [isOpenDlg, setIsOpenDlg] = useState<boolean>(false)
  const [txtAction, setTxtAction] = useState<TxtAction>(TxtAction.BLOCK)
  const [isTableScroll, setIsTableScroll] = useState<boolean>(false)
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([])
  const [selectedCheckboxesOriginal, setSelectedCheckboxesOriginal] = useState<string[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [userInfo, setUserInfo] = useState<object>()
  const [showAction, setShowAction] = useState({
    username: null,
    status: false,
    lastItem: null,
  })

  const { handleErrorSession, timeOutSession } = useSession()

  const {
    data: getUserListData,
    loading: getUserListLoading,
    refetch: getUserListRefetch,
    error: getUserListError,
  } = useQuery(GET_USER_LIST, {
    fetchPolicy: 'no-cache',
    variables: {
      organizationIds: [],
      kind: KIND.ALL,
    },
  } as VariablesUserList)

  const { data: getFilterUserListData, refetch: getUserListFilterRefetch } = useQuery(
    GET_USER_LIST,
    {
      fetchPolicy: 'no-cache',
      variables: {
        organizationIds: [],
        kind: KIND.ALL,
      },
    },
  )

  const { data: getUserListFilterData, refetch: refetchTotalUser } = useQuery(GET_TOTAL_USER, {
    fetchPolicy: 'no-cache',
  })

  const firstTabSelected = { name: 'All', count: 0, type: 'all' }

  const [selectedTab, setSelectedTab] = useState<any>(firstTabSelected)
  const activeButtonClass = 'bg-neutral-3 text-neutral-8'
  const inActiveButtonClass = 'bg-neutral-7 text-neutral-1'

  const handleClick = (tab: any) => {
    setSelectedTab(tab)
    getUserListRefetch({
      organizationIds: [],
      kind: tab.type,
    })
    getUserListFilterRefetch({
      kind: tab.type,
    })
    setSelectedCheckboxesOriginal([])
  }

  const [updateBlockAccessUser] = useMutation(UPDATE_BLOCK_ACCESS_USER)
  const [deleteUser] = useMutation(DELETE_USER)
  const [deleteUserProduction] = useMutation(DELETE_USER_PRODUCTION, {
    client: clientProd,
  })

  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false)
  const [filteredHeader, setFilteredHeader] = useState<number>(0)

  const handleBlockUser = async () => {
    setIsOpenDlg(false)
    try {
      const resDev: any = await updateBlockAccessUser({
        variables: {
          isBlocked: contentDlg[txtAction].txtBtn === TxtAction.BLOCK ? true : false,
          userId: showAction.username,
        },
      })
      // if (resDev?.data?.modifyUserToAccess?.isProduction) {
      //   await updateBlockUserProduction({
      //     variables: {
      //       requesterId: showAction.username,
      //       isBlocked: contentDlg[txtAction].txtBtn === TxtAction.BLOCK ? true : false,
      //     },
      //   })
      // }
      if (resDev?.data?.modifyUserToAccess && contentDlg[txtAction].txtBtn === TxtAction.BLOCK) {
        notify(MESSAGES.SUCCESS.S_BLOCK_USER, STATUS.SUCCESS)
      }
      if (resDev?.data?.modifyUserToAccess && contentDlg[txtAction].txtBtn === TxtAction.UNBLOCK) {
        notify(MESSAGES.SUCCESS.S_UNBLOCK_USER, STATUS.SUCCESS)
      }
      if (resDev?.data?.modifyUserToAccess) {
        getUserListRefetch({
          organizationIds: [...selectedCheckboxes],
        })
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_BLOCK_USER)
    }
  }

  const handleDeleteUser = async () => {
    setIsOpenDlg(false)
    try {
      const resDev: any = await deleteUser({ variables: { userId: showAction.username } })
      if (resDev?.data?.deleteUser?.isProduction) {
        await deleteUserProduction({
          variables: {
            requesterId: showAction.username,
          },
        })
      }
      if (resDev?.data?.deleteUser) {
        notify(MESSAGES.SUCCESS.S_DELETE_USER, STATUS.SUCCESS)
      }
      if (resDev?.data?.deleteUser) {
        getUserListRefetch({
          organizationIds: [...selectedCheckboxes],
        })
      }
      refetchTotalUser()
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_DELETE_USER)
    }
  }

  const contentDlg: contentDlgInterface = {
    block: {
      title: 'Block user',
      content: 'Are you sure to Block this user?',
      txtBtn: TxtAction.BLOCK,
      handleAction: handleBlockUser,
    },
    unblock: {
      title: 'Unblock user',
      content: 'Are you sure to Unblock this user?',
      txtBtn: TxtAction.UNBLOCK,
      handleAction: handleBlockUser,
    },
    delete: {
      title: 'Delete user',
      content: 'Are you sure to permanently delete this user?',
      txtBtn: TxtAction.DELETE,
      handleAction: handleDeleteUser,
    },
  }

  const handleShowDialog = (txt: TxtAction) => {
    setShowAction({ ...showAction, status: false })
    setTxtAction(txt)
    setIsOpenDlg(true)
  }

  const handleShowEditInfoDialog = () => {
    setIsModelOpenEditCarrierInfo(true)
  }

  const handleCloseEditInfoDialog = () => {
    setIsModelOpenEditCarrierInfo(false)
    setUserInfo({})
  }

  const closeBlockDialog = () => {
    setIsOpenDlg(false)
  }

  const onHandleClickAction = (item: any) => {
    // if (showAction.lastItem == item.userId) {
    //   return
    // }
    setUserInfo(item)
    setShowAction({
      username: item?.userId,
      status: true,
      lastItem: item?.userId,
    })
  }

  const handleOpenFilter = (id: number) => {
    setFilteredHeader(id)
    setSelectedCheckboxes(selectedCheckboxesOriginal)
    setIsOpenFilter(true)
  }

  const handleOnchangeCheckbox = (id: string) => {
    if (selectedCheckboxes.includes(id)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((val) => val != id))
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, id])
    }
  }

  const handleClickCancel = () => {
    setSelectedCheckboxes(selectedCheckboxesOriginal)
    setIsOpenFilter(false)
    setSearchValue('')
  }

  const handleFilter = async () => {
    getUserListRefetch({
      organizationIds: [...selectedCheckboxes],
    })
    setSelectedCheckboxesOriginal(selectedCheckboxes)
    setIsOpenFilter(false)
    setSearchValue('')
  }

  const handleRefetchData = () => {
    getUserListRefetch()
  }

  useEffect(() => {
    if (isRefetchUserList) {
      getUserListRefetch({
        organizationIds: [...selectedCheckboxes],
      })
      setIsRefetchUserList(false)
      refetchTotalUser()
    }
  }, [isRefetchUserList])

  useEffect(() => {
    const handleClickOutSide = (e: any) => {
      if (menuRef && !menuRef.current?.contains(e.target)) {
        setShowAction({ ...showAction, status: false })
      }
    }
    document.addEventListener('mousedown', handleClickOutSide)
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide)
    }
  }, [showAction])

  useEffect(() => {
    const handleClickOutSideFilter = (e: any) => {
      if (
        filterRef &&
        !filterRef.current?.contains(e.target) &&
        checkboxRef &&
        !checkboxRef.current?.contains(e.target) &&
        e.target.id !== 'filter-ref'
      ) {
        setIsOpenFilter(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutSideFilter)
    return () => {
      document.removeEventListener('mousedown', handleClickOutSideFilter)
    }
  }, [isOpenFilter])

  useEffect(() => {
    const clientHeight = document.querySelector('#cus-scrollbar')?.clientHeight
    if (document.querySelector('#cus-scrollbar')?.clientHeight && clientHeight) {
      setIsTableScroll(clientHeight >= 588)
    }
  }, [document.querySelector('#cus-scrollbar')?.clientHeight, getUserListData, showAction])

  useEffect(() => {
    if (getUserListError?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT) {
      timeOutSession()
    }
  }, [getUserListError])

  useEffect(() => {
    if (getUserListData) {
      getUserListFilterRefetch({})
    }
  }, [getUserListData])

  const tempGetUserListData = getFilterUserListData?.getUserList.map(
    ({ organization }: any) => organization?.id,
  )
  const filterUserListData = getFilterUserListData?.getUserList.filter(
    ({ organization }: any, index: number) =>
      !tempGetUserListData.includes(organization?.id, index + 1),
  )

  return (
    <div>
      <div className="flex justify-between mb-5">
        <p className="pt-2 text-headline5A text-neutral-1">User list</p>
        <div className="flex justify-end">
          <div className="pr-6">
            <div className="pt-2 font-semibold text-body1 text-neutral-1">View</div>
          </div>
          {getUserListFilterData?.getTotalTypeUser
            ?.slice(0, getUserListFilterData?.getTotalTypeUser?.length - 1)
            ?.map((tab: any, index: number) => (
              <button
                className={`px-6 mr-6 last:mr-0 py-2 text-body1 font-semibold cursor-pointer rounded-full text-center whitespace-nowrap ${
                  selectedTab?.type === tab.type ? activeButtonClass : inActiveButtonClass
                }`}
                onClick={() => handleClick(tab)}
                key={index}
                type="button"
                role="tab"
                aria-selected={selectedTab === index}
                aria-controls={`tabpanel-${index}`}
                tabIndex={selectedTab === index ? 0 : -1}
                id={`btn-${index}`}
              >
                <div className="flex">
                  <p className="pr-1">{tab.name}</p>
                  <p
                    className={`${
                      selectedTab?.type === tab.type ? 'text-primary-shade3' : 'text-primary-shade2'
                    }`}
                  >
                    ({tab.count})
                  </p>
                </div>
              </button>
            ))}
        </div>
      </div>
      <div className="z-10 w-full border-2 border-neutral-6 bg-neutral-8 rounded-2xl">
        <ul
          className={`flex bg-primary-shade4 px-6 py-8 justify-between rounded-t-[14px] ${
            isTableScroll ? 'pr-12' : ''
          }`}
        >
          {tableHeaderUserList.map((item, idx) => (
            <li className={`${item.minW} relative flex`} key={idx}>
              <span
                className={`font-montserrat font-bold text-base text-neutral-2 ${
                  item.isFilter && 'w-min'
                }`}
              >
                {item.label}
              </span>
              {item.isFilter && (
                <div ref={filterRef} className="w-5 h-5 cursor-pointer mt-0.5 ml-3">
                  <img
                    className="max-w-[20px]"
                    id="filter-ref"
                    src={
                      isOpenFilter || selectedCheckboxesOriginal.length ? iconFiltered : IconFilter
                    }
                    alt="Icon-Filter"
                    onClick={() => handleOpenFilter(item.id)}
                  />
                </div>
              )}
              {isOpenFilter && item.id === filteredHeader && (
                <div
                  ref={checkboxRef}
                  className={`absolute z-10 w-[400px] bg-neutral-8 rounded-2xl p-6 shadow-3xl -mb-4 flex flex-col bottom-0 ${
                    getUserListData?.getUserList?.length >= 6
                      ? 'translate-y-full'
                      : 'translate-y-revert'
                  }`}
                >
                  <div className="mb-6">
                    <InputBase
                      placeholder="Search"
                      className="w-full"
                      value={searchValue}
                      handleChange={(e) => setSearchValue(e)}
                    />
                  </div>
                  <div className="flex flex-col text-body1 font-semibold overflow-y-auto max-h-[412px] small-scrollbar mr-[-16px]">
                    {filterUserListData
                      ?.filter((item: any) =>
                        item?.organization?.name
                          ?.toLocaleLowerCase()
                          .includes(searchValue.toLocaleLowerCase()),
                      )
                      .map((item: any) => (
                        <label
                          key={item?.organization?.id}
                          className="flex items-center mb-2 last:mb-0"
                        >
                          <input
                            className="w-6 h-6 mr-3 rounded cursor-pointer accent-primary-1"
                            type="checkbox"
                            onChange={() => handleOnchangeCheckbox(item?.organization?.id)}
                            defaultChecked={selectedCheckboxes.includes(item?.organization?.id)}
                          />
                          {item?.organization?.name}
                        </label>
                      ))}
                  </div>
                  <hr className="my-6 border-t-neutral-6" />
                  <div className="z-20 flex justify-between">
                    <ButtonBase
                      type="reset"
                      className="w-[162px] h-[40px] bg-white border-2 border-violet-700 text-violet-800 hover:bg-violet-500"
                      onClick={handleClickCancel}
                    >
                      Cancel
                    </ButtonBase>
                    <ButtonBase
                      type="submit"
                      className="w-[162px] h-[40px] bg-violet-800 min-w-min"
                      onClick={handleFilter}
                    >
                      Apply
                    </ButtonBase>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
        {getUserListLoading && <Loading className="relative py-6" height={30} width={30} />}
        {!getUserListData?.getUserList?.length && !getUserListLoading && (
          <p className="py-6 text-center text-body1 text-neutral-4">No data available.</p>
        )}
        {Boolean(getUserListData?.getUserList?.length) && !getUserListLoading && (
          <div
            id="cus-scrollbar"
            className={`cus-scrollbar overflow-y-auto mt-5 mb-3 ${
              isTableScroll ? 'mr-2 overflow-y-auto' : 'mr-0 overflow-y-visible'
            }`}
          >
            <div className={`${isTableScroll ? 'pr-2' : 'pr-0'} max-h-[588px]`}>
              {getUserListData?.getUserList?.map((item: any, idx: number) => (
                <ul
                  className="flex justify-between px-6 py-3 mb-3 text-base font-medium font-montserrat text-neutral-3 hover:bg-neutral-7 hover:text-neutral-1"
                  key={idx}
                >
                  <li className="w-[32px] min-w-[32px]">{idx + 1}</li>
                  <li className="w-[168px] min-w-[92px] break-words">{item?.organization?.name}</li>
                  <li className="w-[180px] min-w-[82px] break-words">
                    {item?.lastName} {item?.firstName}
                  </li>
                  <li className="w-[164px] min-w-[92px] break-all">{item?.gsuiteUsername}</li>
                  <li className="w-[136px] min-w-[68px] break-all">{item?.phoneNumber}</li>
                  <li className="w-[240px] min-w-[100px] break-all">{item?.email}</li>
                  <div className="w-[96px] min-w-[82px] break-all">
                    <StatusBase status={!item?.isBlocked} table={TableName.USER_LIST} />
                  </div>
                  <button
                    className="relative h-6 min-w-6"
                    onClick={() => onHandleClickAction(item)}
                  >
                    <img src={IconDot} alt="Icon-Dot" />
                    {showAction?.username === item.userId && showAction.status && (
                      <div className={`absolute right-[2px] top-8 z-10`} ref={menuRef}>
                        <RowActionUserList
                          handleShowDialog={handleShowDialog}
                          isBlock={item?.isBlocked}
                          handleShowEditInfoDialog={handleShowEditInfoDialog}
                        />
                      </div>
                    )}
                  </button>
                </ul>
              ))}
            </div>
            <UserListDialog
              modalIsOpen={isOpenDlg}
              handleCloseDialog={closeBlockDialog}
              title={contentDlg[txtAction].title}
              content={contentDlg[txtAction].content}
              txtBtn={contentDlg[txtAction].txtBtn}
              handleAction={contentDlg[txtAction].handleAction}
            />
            <EditAccountInfoDialog
              modalIsOpen={modalIsOpenEditCarrierInfo}
              userInfo={userInfo}
              isBusinessInfo={false}
              handleCloseDialog={handleCloseEditInfoDialog}
              handleRefetchData={handleRefetchData}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(UserListTable)
