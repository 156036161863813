import { MESSAGES } from '../constants/messages'
export const specialChars = /[`!@#$%^&*()+\\={};':"|,.<>/?[\]]+/

export const asciiChars =
  /[ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]/

export const emailPattern = {
  value: new RegExp('^[a-zA-Z0-9_.-]+@[a-zA-Z0-9]+\\.[a-zA-Z0-9]+$', 'ig'),
  message: MESSAGES.ERROR.E_INVALID_EMAIL,
}

export const gSuitePattern = {
  value: new RegExp('^[a-zA-Z0-9_-]+(?:\\.[a-zA-Z0-9_-]+)*$', 'ig'),
  message: MESSAGES.ERROR.E_INVALID_EMAIL,
}

export const alphabetPattern = {
  value: new RegExp(/^[a-zA-Z ]+$/i, 'ig'),
  message: MESSAGES.ERROR.E_INVALID_FIELD,
}

export const namePattern = {
  value: new RegExp(/^[a-zA-Z0-9 ]+$/i, 'ig'),
  message: MESSAGES.ERROR.E_INVALID_NAME,
}

export const nameKeyPattern = {
  value: new RegExp(/^(?!-)[a-zA-Z0-9-]+(?<!-)$/i, 'ig'),
  message: MESSAGES.ERROR.E_INVALID_KEY_NAME,
}

export const contactNamePattern = {
  value: new RegExp(/^[a-zA-Z0-9 ]+$/i, 'ig'),
  message: MESSAGES.ERROR.E_INVALID_CONTACT_NAME,
}

export const addressPattern = {
  value: new RegExp("^[#.0-9a-zA-Z+/,-\\s']+$", 'ig'),
  message: MESSAGES.ERROR.E_INVALID_ADDRESS,
}

export const cityPattern = {
  value: new RegExp(/^[a-zA-Z ]+$/i, 'ig'),
  message: MESSAGES.ERROR.E_INVALID_CITY,
}

export const statePattern = {
  value: new RegExp(/^[a-zA-Z ]+$/i, 'ig'),
  message: MESSAGES.ERROR.E_INVALID_STATE,
}

export const zipcodePattern = {
  value: new RegExp(/^\d{5}$/i),
  message: MESSAGES.ERROR.E_INVALID_ZIPCODE,
}

export const americanPhoneNumberPattern = {
  value: new RegExp(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i, 'ig'),
  message: MESSAGES.ERROR.E_INVALID_PHONE_NUMBER,
}

export const urlPattern = {
  value: new RegExp(
    /^[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+-~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/i,
    'ig',
  ),
  message: MESSAGES.ERROR.E_INVALID_URL,
}

// eslint-disable-next-line no-useless-escape
export const passwordPattern = /^(?:$|[a-zA-Z0-9!@#$%^&*\(\)_+-={\[}\]|:;"'<,>.?\/~`\\]{8,})$/

export function checkTenDigits(str: string) {
  // const regex = /^\d{10}$/ // Regular expression for 10 digits
  if (str.length >= 10) return true
  else return false
}
