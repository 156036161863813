import React from 'react'
import { HandleRequest } from '@apollo/sandbox/src/helpers/postMessageRelayHelpers'
import { ApolloExplorer } from '@apollo/explorer/react'
import { KIND, ROLE } from '../../constants/common'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { RoleType } from '../../constants/interface'

// get token from local storage
const token = window.localStorage.getItem('accessToken')

const ApiExplorer = () => {
  const {
    REACT_APP_DEVELOPMENT_INVITE_TOKEN: inviteToken,
    REACT_APP_DEVELOPMENT_ACCOUNT_ID: accountId,
    REACT_APP_DEVELOPMENT_GRAPH_REF: graphRef,
    REACT_APP_DEVELOPMENT_AMS_INVITE_TOKEN: inviteTokenAMS,
    REACT_APP_DEVELOPMENT_ACCOUNT_ID_AMS: accountIdAMS,
    REACT_APP_DEVELOPMENT_GRAPH_REF_AMS: graphRefAMS,
    REACT_APP_DEVELOPMENT_INVITE_TOKEN_ADMIN: inviteTokenAdmin,
    REACT_APP_DEVELOPMENT_ACCOUNT_ID_ADMIN: accountIdAdmin,
    REACT_APP_DEVELOPMENT_GRAPH_REF_ADMIN: graphRefAdmin,
  } = process.env
  const userInfoState = useSelector((state: RootState) => state.userReducers?.userInfo)
  const isRoleAdmin = ROLE[userInfoState?.role?.name as keyof RoleType] === ROLE.Admin
  const kind = userInfoState?.kind

  const handleRequest: HandleRequest = (endpointUrl, options) => {
    return fetch(endpointUrl, {
      ...options,
      headers: {
        ...options.headers,
        authorization_ui: `Bearer ${token}`,
      },
    })
  }

  return (
    <div className="px-20 my-8 md:px-10">
      <ApolloExplorer
        className="h-[700px]"
        graphRef={`${
          isRoleAdmin ? graphRefAdmin : kind === KIND.CARRIERS ? graphRef : graphRefAMS
        }`}
        autoInviteOptions={{
          inviteToken: isRoleAdmin
            ? `${inviteTokenAdmin}`
            : `${kind === KIND.CARRIERS ? inviteToken : inviteTokenAMS}`,
          accountId: isRoleAdmin
            ? `${accountIdAdmin}`
            : `${kind === KIND.CARRIERS ? accountId : accountIdAMS}`,
        }}
        persistExplorerState={true}
        handleRequest={handleRequest}
      />
    </div>
  )
}

export default ApiExplorer
