import React, { useEffect, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'
import BaseDialog from '../../../components/dialog'
import iconTimes from '../../../assets/images/icon-times.svg'
import ButtonBase from '../../../components/partials/button'
import { customStyles } from '../../../constants/customStyles'
import { MERGE_AMS } from '../../../queries/adminPortal'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { notify } from '../../../components/toastify'
import BaseAutocompletedInput from '../my-agencies-list/component/autocompletedInputBase'
import classNames from 'classnames'

type Props = {
  modalIsOpen?: boolean
  handleCloseMergeAMSDialog?: (val: boolean) => void
  onHandleReFetchList?: () => void
  userInfo?: object | any
}

const MergeAMSDialog: React.FunctionComponent<Props> = ({
  modalIsOpen,
  handleCloseMergeAMSDialog = () => null,
  onHandleReFetchList = () => null,
  userInfo,
}) => {
  const [mergeAMS] = useMutation(MERGE_AMS)
  const searchBoxRef = useRef<HTMLDivElement>(null)
  const [isFocus, setIsFocus] = useState<boolean>(false)
  const [isValueAMSSelected, setIsValueAMSSelected] = useState<{
    agencyManagementProviderId: string
    agencyManagementProviderName: string
  }>()
  const [isDisableMergeAMS, setIsDisableMergeAMS] = useState<boolean>(true)

  const handleMergeAMS = async () => {
    try {
      const dataRes = await mergeAMS({
        variables: {
          updateUserId: userInfo?.userId,
          data: {
            organizationName: isValueAMSSelected?.agencyManagementProviderName,
          },
        },
      })
      if (dataRes) {
        notify(
          `${userInfo?.organization?.name} AMS has been merged to ${isValueAMSSelected?.agencyManagementProviderName} AMS successfully!`,
          STATUS.SUCCESS,
        )
        handleCloseMergeAMSDialog(false)
        onHandleReFetchList()
      }
    } catch (error) {
      notify(MESSAGES.ERROR.E_TRY_AGAIN, STATUS.ERROR)
    }
  }

  const handleDisableBtnSubmit = () => {
    setIsDisableMergeAMS(true)
  }

  useEffect(() => {
    if (isValueAMSSelected?.agencyManagementProviderId === '') {
      setIsDisableMergeAMS(true)
    } else {
      setIsDisableMergeAMS(false)
    }
  }, [isValueAMSSelected])

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={customStyles}>
      <div className="px-1">
        <div className="flex justify-between">
          <div className="absolute right-4 top-4">
            <img
              src={iconTimes}
              alt=""
              className="cursor-pointer"
              onClick={() => handleCloseMergeAMSDialog(false)}
            />
          </div>
        </div>
        <div className="w-[420px]">
          <p className="mt-2 mb-6 text-center text-headline5">Merge</p>
          <div className="flex justify-center">
            <p className="font-semibold text-center text-body1 font-montserrat">
              Are you sure you want to merge{' '}
              <span className="font-extrabold">{userInfo?.organization?.name}</span> to existing
              AMS?
            </p>
          </div>
          <div className="pt-3">
            <div
              className={classNames('flex w-full mr-3 h-[56px] rounded-xl border-[2px]', {
                'border-neutral-3': isFocus,
                'border-neutral-5': !isFocus,
              })}
              ref={searchBoxRef}
            >
              <BaseAutocompletedInput
                classInput="border-0 w-full"
                isDropdown={true}
                setIsFocus={setIsFocus}
                isSuggestAMSData={true}
                setIsValueInputAMSSelected={setIsValueAMSSelected}
                handleDisableBtnSubmit={handleDisableBtnSubmit}
                placeholderText="Please enter existing AMS to merge this AMS to"
              />
            </div>
          </div>
          <div className="flex justify-between mt-4 mb-1">
            <ButtonBase
              type="reset"
              className="w-[204px] mt-2 border-2 border-primary-1 text-primary-1 bg-white-50 hover:bg-violet-500"
              onClick={() => handleCloseMergeAMSDialog(false)}
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              type="button"
              className="w-[204px] mt-2 bg-primary-1 min-w-min text-center"
              bgDisabled="bg-primary-shade3"
              onClick={handleMergeAMS}
              disabled={isDisableMergeAMS}
            >
              Merge
            </ButtonBase>
          </div>
        </div>
      </div>
    </BaseDialog>
  )
}

export default MergeAMSDialog
