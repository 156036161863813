import {
  ActivitiesGroupModel,
  JsonPacketGroupModel,
} from '../../pages/developer-portal/table/activities-log/component/activitiesLogModel'
import {
  ACTIVITIES_TYPE_GROUP,
  CLEAR_DATETIME_FILTER,
  REQUEST_FROM_DATE,
  REQUEST_JSON_PACKET,
  REQUEST_TO_DATE,
} from './actionTypes'

/**
 *
 * @param payload
 * @returns
 */
export const setActivitiesGroupType = (payload: ActivitiesGroupModel[]) => {
  return {
    type: ACTIVITIES_TYPE_GROUP,
    payload: payload,
  }
}

/**
 *
 * @param payload
 * @returns
 */
export const setRequestJsonPacket = (payload: JsonPacketGroupModel[]) => {
  return {
    type: REQUEST_JSON_PACKET,
    payload: payload,
  }
}

/**
 *
 * @param payload
 */
export const setFromDate = (payload: object) => {
  return {
    type: REQUEST_FROM_DATE,
    payload: payload,
  }
}

/**
 *
 * @param payload
 * @returns
 */
export const setToDate = (payload: object) => {
  return {
    type: REQUEST_TO_DATE,
    payload: payload,
  }
}

/**
 *
 * @returns
 */
export const clearDateTimeFilterForDeveloper = () => {
  return {
    type: CLEAR_DATETIME_FILTER,
  }
}
