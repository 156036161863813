import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import ReactTooltip from 'react-tooltip'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import BaseDialog from '../../../components/dialog'
import iconQues from '../../../assets/images/icon-question.svg'
import iconTimes from '../../../assets/images/icon-times.svg'
import ButtonBase from '../../../components/partials/button'
import { notify } from '../../../components/toastify'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { asciiChars, specialChars } from '../../../utils/regex'
import { handleRole } from '../../../helpers/functionUtils'
import { RootState } from '../../../store'
import { CHANGE_KEY_NAME } from '../../../queries/developerPortal'
import { CHANGE_KEY_NAME_BY_ADMIN } from '../../../queries/adminPortal'
import { LocationState } from '../../../constants/interface'
import { useSession } from '../../../helpers/checkSession'
import { ListKeys } from '../table/api-key-management/apiKeyModel'
import InputBase from '../../../components/partials/inputBase'
import { defaultCustomStyles } from '../../../constants/customStyles'
import classNames from 'classnames'

type Props = {
  modalIsOpen?: boolean
  handleCloseDialog?: (val: boolean) => void
  currentValue?: string | null
  handleRefetchKeys?: () => void
  listKeys?: ListKeys[]
  apiKeyId?: string | null
}

const ChangeKeyNameDialog: React.FC<Props> = ({
  modalIsOpen,
  handleCloseDialog = () => null,
  currentValue = '',
  handleRefetchKeys = () => null,
  listKeys,
  apiKeyId,
}) => {
  const { handleErrorSession } = useSession()
  const { state } = useLocation()
  const locationState = state as LocationState
  const userState = useSelector((state: RootState) => state.userReducers?.userInfo)
  const [keyName, setKeyName] = useState('')
  const [isDisableChangeKey, setIsDisableChangeKey] = useState(true)
  const [errorInvalidKey, setErrorKeyName] = useState('')
  const currentRole = handleRole(userState?.role?.name)

  const [changeKeyName] = useMutation(
    currentRole.isRoleDev ? CHANGE_KEY_NAME : CHANGE_KEY_NAME_BY_ADMIN,
  )

  // #start region: handle function of change key name
  const handleCloseChangeKeyDialog = () => {
    handleCloseDialog(false)
    setIsDisableChangeKey(true)
    setErrorKeyName('')
  }

  const handleChangeKey = (val: string) => {
    setKeyName(val)
    // handle validation the key name
    const listKeyNames = listKeys?.map((x: any) => x?.name)
    const isDuplicated = listKeyNames?.includes(val)
    const isOnlySpace = val.replaceAll(' ', '')
    let isSpecialChar
    let isAsciiChar

    if (specialChars.test(val)) {
      isSpecialChar = true
    } else {
      isSpecialChar = false
    }

    if (asciiChars.test(val)) {
      isAsciiChar = true
    } else {
      isAsciiChar = false
    }

    if (val.length > 50) {
      setIsDisableChangeKey(true)
      setErrorKeyName(MESSAGES.ERROR.E_SHORTEN_KEY_NAME)
    } else if (isDuplicated) {
      setIsDisableChangeKey(true)
      setErrorKeyName(MESSAGES.ERROR.E_KEY_NAME_EXISTS)
    } else if (isOnlySpace === '') {
      if (val.length > 0) {
        setIsDisableChangeKey(true)
        setErrorKeyName(MESSAGES.ERROR.E_KEY_NAME_HAS_SPACE)
      } else {
        setIsDisableChangeKey(false)
        setErrorKeyName('')
      }
    } else if (isSpecialChar || isAsciiChar) {
      setIsDisableChangeKey(true)
      setErrorKeyName(MESSAGES.ERROR.E_SPECIAL_CHARS)
    } else {
      setIsDisableChangeKey(false)
      setErrorKeyName('')
    }
  }

  const handleRefreshInfoInTable = () => {
    handleRefetchKeys()
  }

  const handleSubmitChangeKeyName = async () => {
    handleCloseDialog(false)
    handleRefreshInfoInTable()
    if (keyName === '') {
      setErrorKeyName(MESSAGES.ERROR.E_FILL_KEY_NAME)
    } else {
      try {
        const dataRes = currentRole.isRoleDev
          ? await changeKeyName({
              variables: {
                data: {
                  name: keyName.trim(),
                  apiKeyId: apiKeyId,
                },
              },
            })
          : await changeKeyName({
              variables: {
                data: {
                  kind: locationState.kind,
                  name: keyName.trim(),
                  partyId: locationState.partyId,
                  apiKeyId: apiKeyId,
                },
              },
            })
        if (dataRes) {
          notify(MESSAGES.SUCCESS.S_UPDATED_KEY_NAME, STATUS.SUCCESS)
          handleRefreshInfoInTable()
        }
      } catch (error) {
        handleErrorSession(error, MESSAGES.ERROR.E_CHANGE_KEY_NAME)
      }
      handleCloseDialog(false)
      setErrorKeyName('')
      setIsDisableChangeKey(true)
    }
  }
  // #end region: handle function of change key name

  useEffect(() => {
    setKeyName(currentValue as string)
  }, [modalIsOpen])

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={defaultCustomStyles}>
      <div>
        <div className="flex justify-between">
          <div className="absolute right-4 top-4">
            <img
              src={iconTimes}
              alt=""
              className="cursor-pointer"
              onClick={handleCloseChangeKeyDialog}
            />
          </div>
        </div>
        <p className="text-center text-headline5 text-neutral-1 mb-6">Change key name</p>
        <div className="flex mb-3">
          <p className="text-hairline1 text-neutral-2">Key name</p>
          <img
            data-for="main"
            data-tip="What do you want to call this key?
            Hint your server name or anything
            to recognize how you use this key"
            data-iscapture="true"
            src={iconQues}
            alt=""
            className="ml-[10px] rounded-sm"
          />
        </div>
        <InputBase
          placeholder="Enter your new key name"
          type="text"
          className={classNames([
            'w-full',
            `${errorInvalidKey && 'border-2 border-secondary-bold2 focus:border-secondary-bold2'}`,
          ])}
          value={keyName}
          handleChange={handleChangeKey}
        />
        <p className="mt-1 text-red-900 font-medium text-sm">{errorInvalidKey}</p>
        <div className="flex justify-between gap-3 mt-4">
          <ButtonBase
            type="button"
            className="w-[180px] h-[52px] border-0 bg-primary-1 text-hairline1 min-w-min text-center text-neutral-8"
            onClick={handleCloseChangeKeyDialog}
          >
            Cancel
          </ButtonBase>
          <ButtonBase
            type="reset"
            className="w-[180px] h-[52px] text-hairline1 bg-neutral-8 border-2 border-primary-1 text-primary-1 hover:bg-primary-shade4"
            bgDisabled="bg-neutral-8 border-2 border-primary-shade3 text-primary-shade3"
            onClick={handleSubmitChangeKeyName}
            disabled={isDisableChangeKey}
          >
            Change
          </ButtonBase>
        </div>
        <ReactTooltip
          id="main"
          place="top"
          type="dark"
          effect="float"
          multiline={true}
          className="w-[280px] p-4 rounded-lg"
          offset={{ top: 0, left: 0 }}
        />
      </div>
    </BaseDialog>
  )
}

export default ChangeKeyNameDialog
