import SystemConfiguration from '.'
import { checkProdSite } from '../../helpers/checkProdSite'
import { PageRoute } from '../../routes/routerInterface'

const systemConfigurationRoutes: PageRoute[] = [
  {
    path: '/system-configuration',
    element: SystemConfiguration,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
  },
]

export default systemConfigurationRoutes
