import React, { useState } from 'react'
import { tableHeader } from './data'
import {
  optionHover,
  PrincipalAccountDataInterface,
  PrincipalAccountInterface,
  stylePrincipalKey,
} from './models'
import IconHoverCopy from '../../../assets/images/icon-hover-copy.svg'
import IconBag from '../../../assets/images/icon-bag.svg'
import IconPhone from '../../../assets/images/icon-phone.svg'
import IconEmail from '../../../assets/images/icon-email.svg'
import IconArrowExpand from '../../../assets/images/icon-arrow-expand.svg'
import IconAddress from '../../../assets/images/ic_location.svg'
import { MESSAGES } from '../../../constants/messages'
import { onHandleCopyItem } from '../../../helpers/functionUtils'
import { useDispatch } from 'react-redux'
import { openPrincipalAccountDetail } from '../../../redux/actions/adminPortal'

interface Props {
  data?: PrincipalAccountInterface[]
  key?: number
  dataDetail?: PrincipalAccountDataInterface
  isLastItem?: boolean
  handleOpenAndClosePrincipalDetail?: (val: boolean) => void
  handleSetPrincipalAccountDetail?: (data: object) => void
}

const PrincipalAccountTable: React.FC<Props> = ({
  data,
  key = 0,
  dataDetail,
  isLastItem,
  handleOpenAndClosePrincipalDetail = () => null,
  handleSetPrincipalAccountDetail = () => null,
}) => {
  const dispatch = useDispatch()
  const [hoverRow, setHoverRow] = useState<optionHover>({
    principalId: null,
    status: false,
  })

  const onHandleCopyPrincipalKey = async (event: any, principalKey: string) => {
    onHandleCopyItem(
      principalKey,
      MESSAGES.INFO.I_COPIED_PRINCIPAL_KEY,
      MESSAGES.ERROR.E_COPY_FAILED,
    )
    event.preventDefault()
  }

  const handleViewPrincipalAccountDetail = (data: any) => {
    handleSetPrincipalAccountDetail(data)
    handleOpenAndClosePrincipalDetail(true)
    dispatch(openPrincipalAccountDetail(data?.principalAccountName))
  }

  return (
    <div key={key}>
      <div className="flex justify-start my-5">
        <div className="flex ">
          <img src={IconBag} alt="IconBag" className="w-6 h-6 mt-[2px]" />
          <p className="text-neutral-2 text-body1-med font-bold ml-4">
            {dataDetail?.principalAccountName}
          </p>
        </div>
        <div className="flex">
          <div
            className="bg-neutral-7 min-w-[28px] h-[28px] min-h-[24px] ml-4 rounded-lg cursor-pointer hover:bg-neutral-6"
            onClick={() => handleViewPrincipalAccountDetail(dataDetail)}
          >
            <img src={IconArrowExpand} alt="IconArrowExpand" className="w-5 h-5 ml-1 mt-1" />
          </div>
        </div>
      </div>
      <div className="flex justify-start my-5">
        <div className="flex justify-start">
          <a href={`address:+${dataDetail?.principalAccountAddressLine1}`}>
            <img src={IconAddress} alt="IconAddress" className="w-5 h-5 mr-3" />
          </a>
          <p className="text-body2 text-neutral-4 pr-8">
            {dataDetail?.principalAccountAddressLine1
              ? dataDetail?.principalAccountAddressLine1
              : 'No available data'}
          </p>
        </div>
        <div className="flex justify-start">
          <a href={`tel:+${dataDetail?.principalAccountPhoneNumber}`}>
            <img src={IconPhone} alt="IconPhone" className="w-5 h-5 mr-3" />
          </a>
          <p className="text-body2 text-neutral-4">
            {dataDetail?.principalAccountPhoneNumber
              ? dataDetail?.principalAccountPhoneNumber
              : 'No available data'}
          </p>
        </div>
        <div className="flex justify-start">
          <a href={`mailto:${dataDetail?.principalAccountBusinessEmail}`}>
            <img src={IconEmail} alt="IconEmail" className="w-5 h-5 mr-3 ml-8" />
          </a>
          <p className="text-body2 text-neutral-4">
            {dataDetail?.principalAccountBusinessEmail
              ? dataDetail?.principalAccountBusinessEmail
              : 'No available data'}
          </p>
        </div>
      </div>
      <div className="max-w-[1280px] min-w-[688px] top-[88px] border-2 border-neutral-6 bg-neutral-8 rounded-2xl z-10">
        <ul className="flex bg-primary-shade4 px-6 py-8 justify-between rounded-t-[14px]">
          {tableHeader.map((item, idx) => (
            <li className={item.minW} key={idx}>
              <span className={`font-montserrat font-bold text-base text-neutral-2 `}>
                {item.label}
              </span>
            </li>
          ))}
        </ul>
        <div className="cus-scrollbar px-2 overflow-auto">
          <div className={`mt-5 max-h-[550px] cus-scrollbar`}>
            {data?.map((item, idx) => (
              <ul
                className="flex justify-between px-4 py-5 h-auto font-montserrat font-medium text-base text-neutral-3 hover:bg-neutral-7 hover:text-neutral-1 mb-3"
                key={idx}
                onMouseEnter={() => {
                  setHoverRow({
                    principalId: idx,
                    status: true,
                  })
                }}
                onMouseLeave={() => {
                  setHoverRow({
                    principalId: null,
                    status: false,
                  })
                }}
              >
                <li className="w-[28px] min-w-[28px]">{idx + 1}</li>
                <li className="w-[160px] min-w-[100px]">
                  <p className="w-full">{item?.principalName}</p>
                </li>
                <div className="relative w-[256px] min-w-[160px] break-all px-2">
                  <li style={{ ...stylePrincipalKey }}>{item.principalKey}</li>
                  {hoverRow?.status && idx === hoverRow.principalId && item?.principalKey && (
                    <button
                      className="absolute top-[-8px] right-0"
                      title="Copy this Principal key"
                      onClick={(event) => onHandleCopyPrincipalKey(event, item.principalKey) ?? ''}
                    >
                      <img src={IconHoverCopy} alt="Icon-Hover-Copy" />
                    </button>
                  )}
                </div>
                <li className="w-[156px] min-w-[100px]">
                  <p className="w-full">{item?.agencyName}</p>
                </li>
                <li className="w-[320px] min-w-[140px] px-2">
                  <p className="w-full">{item?.principalAddressLine1}</p>
                </li>
                <li className="w-[130px] min-w-[60px]">
                  <p className="break-all w-full">{item?.recordsCount}</p>
                </li>
              </ul>
            ))}
            {!data?.length && (
              <p className="text-body1 text-neutral-4 text-center pt-2 pb-6">No data available</p>
            )}
          </div>
        </div>
      </div>
      <div>
        <p className="my-5 text-body2 text-neutral-4">
          0{data?.length} {(data?.length as number) > 1 ? 'Principals' : 'Principal'} {`of `}
          {(dataDetail?.countPrincipal as number) < 10 && '0'}
          {dataDetail?.countPrincipal}
        </p>
      </div>
      {!isLastItem && <hr className="border-t-neutral-6 my-10" />}
    </div>
  )
}

export default PrincipalAccountTable
