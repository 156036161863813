import NotFound from '.'
import { PageRoute } from '../../routes/routerInterface'

const notFoundRoutes: PageRoute[] = [
  {
    path: '*',
    element: NotFound,
    isProtected: true,
  },
]

export default notFoundRoutes
