export class Header {
  private _id!: number
  private _name!: string
  private _label!: string
  private _isFilter!: boolean
  private _minWidth!: string

  public static readonly TYPE_CARRIER_LABEL: string = 'carrier'
  public static readonly TYPE_ACTIVITIES_LABEL: string = 'activities_type'
  public static readonly TYPE_DATE_TIME_LABEL: string = 'date_time'
  public static readonly TYPE_JSON_PACKET_LABEL: string = 'request_id'
  public static readonly TYPE_USER_LABELstring: string = 'user'
  public static readonly TYPE_RESPONSE_LABEL: string = 'response'
  public static readonly TYPE_FROM_DATE: string = 'from'
  public static readonly TYPE_TO_DATE: string = 'to'

  //Defined param pass to call api
  public static readonly DEFAULT_OFFSET = 50
  public static readonly TYPE_ACTIVITIES = 'ActivityType'
  public static readonly TYPE_REQUEST_JSON_PACKET = 'Json'
  public static readonly TYPE_CARRIER = 'Carrier'

  //Role
  public static readonly ADMIN = 'ADMIN'
  public static readonly DEVELOPER = 'DEVELOPER'

  /**
   *
   * @param id
   * @param name
   * @param label
   * @param isFilter
   * @param minWidth
   */
  constructor(id: number, name: string, label: string, isFilter: boolean, minWidth: string) {
    this._id = id
    this._name = name
    this._label = label
    this._isFilter = isFilter
    this._minWidth = minWidth
  }

  public get id(): number {
    return this._id
  }

  public get name(): string {
    return this._name
  }

  public get label(): string {
    return this._label
  }

  public get isFilter(): boolean {
    return this._isFilter
  }

  public get minWidth(): string {
    return this._minWidth
  }

  /**
   * Header no scroll with item < 10
   * @returns
   */
  static showHeaderNoScrollDeveloper() {
    return [
      new Header(1, 'Activities type', 'activities_type', true, 'w-[220px] min-w-[110px]'),
      new Header(2, 'Date / time', 'date_time', true, 'w-[150px] min-w-[55px]'),
      new Header(3, 'Request ID - JSON Packet', 'request_id', true, 'w-[400px] min-w-[200px]'),
      new Header(4, 'User', 'user', false, 'w-[178px] min-w-[89px] ml-2'),
      new Header(5, 'Response', 'response', false, 'w-[100px] min-w-[50px]'),
    ]
  }

  /**
   * Header scroll with item >= 10
   * @returns
   */
  static showHeaderWithScrollDeveloper() {
    return [
      new Header(1, 'Activities type', 'activities_type', true, 'w-[220px] min-w-[110px] md:mr-2'),
      new Header(2, 'Date / time', 'date_time', true, 'w-[150px] min-w-[55px] md:mr-2'),
      new Header(3, 'Request ID - JSON Packet', 'request_id', true, 'w-[380px] min-w-[180px]'),
      new Header(4, 'User', 'user', false, 'w-[178px] min-w-[89px] md:pl-1 pl-1'),
      new Header(5, 'Response', 'response', false, 'w-[118px] min-w-[59px] md:pl-[-8px]'),
    ]
  }

  /**
   *
   * @returns
   */
  static showHeaderNoScrollAdmin() {
    return [
      new Header(1, 'Carrier', 'carrier', true, 'w-[168px] min-w-[50px]'),
      new Header(2, 'Activities type', 'activities_type', true, 'w-[200px] min-w-[100px]'),
      new Header(3, 'Date / time', 'date_time', true, 'w-[152px] min-w-[76px]'),
      new Header(4, 'Request ID - JSON Packet', 'request_id', true, 'w-[320px] min-w-[160px]'),
      new Header(5, 'User', 'user', false, 'w-[156px] min-w-[78px]'),
      new Header(6, 'Response', 'response', false, 'w-[110px] min-w-[55px'),
    ]
  }

  /**
   * Header scroll with item >= 10
   * @returns
   */
  static showHeaderWithScrollAdmin() {
    return [
      new Header(1, 'Carrier', 'carrier', true, 'w-[168px] min-w-[50px] md:mr-2'),
      new Header(2, 'Activities type', 'activities_type', true, 'w-[200px] min-w-[100px] md:mr-2'),
      new Header(3, 'Date / time', 'date_time', true, 'w-[152px] min-w-[76px] md:mr-2'),
      new Header(4, 'Request ID - JSON Packet', 'request_id', true, 'w-[320px] min-w-[160px]'),
      new Header(5, 'User', 'user', false, 'w-[156px] min-w-[78px] md:pl-2'),
      new Header(6, 'Response', 'response', false, 'w-[116px] min-w-[58px] md:mr-2'),
    ]
  }
}
