import React from 'react'
import { googleLogout } from '@react-oauth/google'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearUserInfo, logout } from '../../redux/actions/users'
import ButtonBase from '../partials/button'
import IconLogOut from '../../assets/images/log-out.svg'
import { LOGOUT_ACTIVITIES } from '../../queries/adminPortal'
import { LOGOUT } from '../../queries/logout'
import { useMutation } from '@apollo/client'
import { MESSAGES } from '../../constants/messages'
import { useSession } from '../../helpers/checkSession'

interface Props {
  className?: string,
  onClosePopup: () => void
}

const ButtonSignOut: React.FC<Props> = ({ className, onClosePopup }) => {
  const { handleErrorSession } = useSession()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [logOut, { loading: logoutApiKeyLoading }] = useMutation(LOGOUT_ACTIVITIES)
  const [logoutSystem] = useMutation(LOGOUT)

  const onLogoutSuccess = () => {
    localStorage.clear()
    dispatch(logout())
    dispatch(clearUserInfo())
    navigate('/')
  }

  const onLogoutFailure = () => {}

  const onHandleSignOut = async () => {
    try {
      const response = await logOut({ variables: { data: { activityType: 'Logout' } } })
      if (!logoutApiKeyLoading && response) {
        googleLogout()
      }
      await logoutSystem({})
      onClosePopup()
      onLogoutSuccess()
    } catch (error) {
      onLogoutFailure()
      handleErrorSession(error, MESSAGES.ERROR.E_DELETE_AP_KEY)
    }
  }

  return (
    <ButtonBase
      onClick={() => onHandleSignOut()}
      className={`text-red-900 bg-transparent flex items-center w-full px-0 font-medium text-base ${className}`}
    >
      <img src={IconLogOut} alt="" className="pr-3.5" /> Log out
    </ButtonBase>
  )
}

export default ButtonSignOut
