export type Variables = {
  organizationIds: string[]
  kind: string
}
export type VariablesUserList = {
  variables: Variables
}
export enum TxtAction {
  BLOCK = 'block',
  UNBLOCK = 'unblock',
  DELETE = 'delete',
}
export enum TitleDialog {
  BLOCK = 'Are you sure to Block this user?',
  UNBLOCK = 'Are you sure to Unblock this user?',
  DELETE = 'Are you sure to permanently delete this user?',
}
export enum ContentDialog {
  BLOCK = 'Block user',
  UNBLOCK = 'Unblock user',
  DELETE = 'Delete user',
}
export interface textDlg {
  title: string
  content: string
  txtBtn: string
  handleAction: () => void
}
export interface contentDlgInterface {
  block: textDlg
  unblock: textDlg
  delete: textDlg
}
