import React, { useEffect, useRef, useState } from 'react'
import Moment from 'react-moment'
import { useMutation, useQuery } from '@apollo/client'

import Loading from '../../../components/loading'
import SelectionBase from '../../../components/partials/selectionBase'
import { notify } from '../../../components/toastify'
import RowActionNewUserRequest from '../../../components/partials/rowActionNewUserRequest'
import ApproveDialog from '../dialog/approveUserRequest'
import RejectUserQuest from '../dialog/rejectUserRequest'
import {
  GET_NEW_USER_REQUESTS,
  GET_PARTY_NAME,
  UPDATE_CARRIER_FOR_USER_REQUEST,
} from '../../../queries/adminPortal'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { tableHeader } from './data'
import { UserRequest } from './model'
import { useSession } from '../../../helpers/checkSession'
import { KIND } from '../../../constants/common'
import { CODE_TIMEOUT } from '../../../constants/statusCode'

import IconDot from '../../../assets/images/icon-dot.svg'
import MergeAMSDialog from '../dialog/mergeAMS'
import AddAMSToTheSystemDialog from '../dialog/addAMSToTheSystem'

type Option = {
  id: number | string
  name: string
  kind: string
}

interface Props {
  setIsRefetchUserList: (refetchUser: boolean) => void
}
const NewUserRequestTable: React.FC<Props> = ({ setIsRefetchUserList }) => {
  const { timeOutSession, handleErrorSession } = useSession()
  const {
    loading: getNewUserRequestsLoading,
    error: getNewUserRequestsError,
    data: getNewUserRequestsData,
    refetch,
  } = useQuery(GET_NEW_USER_REQUESTS, {
    fetchPolicy: 'no-cache',
  })

  const { data: getPartNameData, error: getPartyNameError } = useQuery(GET_PARTY_NAME, {
    fetchPolicy: 'no-cache',
    variables: {
      kind: KIND.CARRIERS,
    },
  })
  const [updateCarrier, { loading }] = useMutation(UPDATE_CARRIER_FOR_USER_REQUEST)

  const [listUserRequests, setListUserRequests] = useState<UserRequest[]>(
    getNewUserRequestsData?.getUserRequests,
  )
  const [userInfo, setUserInfo] = useState<object>()
  const [carrierList, setCarrierList] = useState<Option[]>(getPartNameData?.getPartyName)
  const [carrier, setCarrier] = useState<Option>()
  const [userIdChanged, setUserIdChanged] = useState<string>()
  const [showAction, setShowAction] = useState({
    userRequestId: null,
    status: false,
    lastItem: null,
  })

  const [isOpenModalAdd, setIsOpenModalAdd] = useState<boolean>(false)
  const [isOpenModalMerge, setIsOpenShowModalMerge] = useState<boolean>(false)
  const [isOpenModalApprove, setIsOpenShowModalApprove] = useState<boolean>(false)
  const [isOpenModalReject, setIsOpenModalReject] = useState<boolean>(false)
  const [isOrganizationType, setIsOrganizationType] = useState<string>()

  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutSide = (e: any) => {
      if (menuRef && !menuRef.current?.contains(e.target)) {
        setShowAction({ lastItem: showAction.userRequestId, userRequestId: null, status: false })
      }
    }
    document.addEventListener('mousedown', handleClickOutSide)
    return () => {
      document.removeEventListener('mousedown', handleClickOutSide)
    }
  }, [showAction])

  const onHandleClickAction = (item: any) => {
    setUserInfo(item)
    if (showAction.lastItem == item.userId) {
      return
    }
    setShowAction({
      userRequestId: item.userId,
      status: true,
      lastItem: item.userId,
    })
  }

  const handleCloseApproveDialog = () => {
    setIsOpenShowModalApprove(false)
    setUserInfo({})
  }

  const handleShowApproveDialog = () => {
    setIsOpenShowModalApprove(true)
  }

  const handleShowAddDialog = () => {
    setIsOpenModalAdd(true)
  }

  const handleCloseAddDialog = () => {
    setIsOpenModalAdd(false)
  }

  const handleShowMergeDialog = () => {
    setIsOpenShowModalMerge(true)
  }

  const handleCloseMergeAMSDialog = () => {
    setIsOpenShowModalMerge(false)
  }

  const onHandleReFetchList = async () => {
    refetch({})
    setIsRefetchUserList(true)
  }

  const handleShowRejectDialog = async () => {
    setIsOpenModalReject(true)
  }

  const handleCloseRejectDialog = () => {
    setIsOpenModalReject(false)
  }

  const handleChangeCarrier = async (val: Option, id: string) => {
    setUserIdChanged(id)
    setCarrier(val)
    try {
      const dataRes = await updateCarrier({
        variables: {
          data: {
            partyId: val?.id,
            kind: val?.kind,
          },
          userId: id,
        },
      })
      if (dataRes) {
        setUserIdChanged('')
        notify(MESSAGES.SUCCESS.S_UPDATED_CARRIER, STATUS.SUCCESS)
      }
      refetch({})
    } catch (error) {
      setUserIdChanged('')
      handleErrorSession(error, MESSAGES.ERROR.E_UPDATE_CARRIER)
    }
  }

  useEffect(() => {
    const onHandleShowListKeys = async () => {
      if (!getNewUserRequestsLoading && !getNewUserRequestsError) {
        const responseData = await getNewUserRequestsData.userId
        if (Array.isArray(responseData)) {
          setListUserRequests(responseData)
        }
      }
    }
    onHandleShowListKeys()
  }, [getNewUserRequestsData?.userId])

  useEffect(() => {
    setListUserRequests(getNewUserRequestsData?.getUserRequests)
    setCarrierList(getPartNameData?.getPartyName)
  }, [getNewUserRequestsData, getPartNameData])

  useEffect(() => {
    if (
      getPartyNameError?.graphQLErrors[0]?.extensions?.code ||
      getNewUserRequestsError?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT
    ) {
      timeOutSession()
    }
  }, [getPartyNameError, getNewUserRequestsError])

  return (
    <div>
      <p className="mb-5 text-headline5A text-neutral-1">New user requests</p>
      <div className="max-w-[1280px] min-w-[688px] top-[88px] border-2 border-neutral-6 bg-neutral-8 rounded-2xl z-10">
        <ul className="flex bg-primary-shade4 px-6 py-8 justify-between rounded-t-[14px]">
          {tableHeader.map((item, idx) => (
            <li className={item.minW} key={idx}>
              <span className={`font-montserrat font-bold text-base text-neutral-2 `}>
                {item.label}
              </span>
            </li>
          ))}
        </ul>
        {getNewUserRequestsLoading && <Loading className="relative py-6" height={30} width={30} />}
        {!listUserRequests?.length && !getNewUserRequestsLoading && (
          <p className="py-6 text-center text-body1 text-neutral-4">No data available.</p>
        )}
        {listUserRequests !== null && listUserRequests?.length > 0 && !getNewUserRequestsLoading && (
          <div className="px-2 overflow-auto cus-scrollbar">
            <div className={`mt-5 max-h-[550px] cus-scrollbar`}>
              {listUserRequests?.map((item, idx) => (
                <ul
                  className="flex-col h-auto px-4 py-5 mb-3 text-base font-medium font-montserrat text-neutral-3 hover:bg-neutral-7 hover:text-neutral-1"
                  key={idx}
                >
                  <div className="flex justify-between pb-2">
                    <li className="w-[28px] min-w-[28px]">{idx + 1}</li>
                    <li className="w-[100px] min-w-[50px] break-all">
                      <Moment format="MM/DD/YYYY">{item?.createdAt ?? ''}</Moment>
                    </li>
                    <li className="w-[152px] min-w-[92px]">
                      {item?.organization?.kind === KIND.CARRIERS ? (
                        <p className="w-full break-all">Carrier</p>
                      ) : (
                        <>
                          {item?.organization?.kind === KIND.AGENCIES ? (
                            <p className="w-full break-words">Agency Management Provider</p>
                          ) : (
                            <p className="w-full break-all">Propeller</p>
                          )}
                        </>
                      )}
                    </li>
                    <li className="w-[148px] min-w-[76px] overflow-hidden text-ellipsis whitespace-nowrap">
                      <p className="w-full break-all">{`Developer`}</p>
                    </li>
                    <li className="w-[156px] min-w-[80px]">
                      <p className="w-full break-words">
                        {item?.firstName} {item?.lastName}
                      </p>
                    </li>
                    <li className="w-[132px] min-w-[68px]">
                      <p className="w-full break-all">{item?.phoneNumber}</p>
                    </li>
                    <li className="w-[228px] min-w-[120px]">
                      <p className="w-full break-all">{item?.email}</p>
                    </li>
                    {item?.organization?.kind === KIND.CARRIERS ? (
                      <div className="w-[162px] min-w-[92px] mt-[-4px]">
                        <SelectionBase
                          id={`${item?.userId}`}
                          options={carrierList}
                          handleOptions={(option: Option) =>
                            handleChangeCarrier(option, `${item?.userId}`)
                          }
                          name="carrier"
                          initialValue={item?.organization}
                          placeHolder="Choose carrier"
                          loading={loading}
                          userIdChanged={userIdChanged}
                        />
                      </div>
                    ) : (
                      <>
                        {item?.organization?.kind === KIND.AGENCIES ? (
                          <div className="relative">
                            <input
                              type="text"
                              className={`border-b border-b-neutral-5 outline-none w-[162px] min-w-[92px] bg-transparent`}
                              value={item?.organization?.name ?? ''}
                              onChange={() => {}}
                            />
                            <span className="absolute right-1 text-body2 text-neutral-5"></span>
                          </div>
                        ) : (
                          <div className="w-[162px] min-w-[92px]">Propeller</div>
                        )}
                      </>
                    )}
                    <button
                      className="min-w-[24px] relative"
                      onClick={() => {
                        onHandleClickAction(item), setIsOrganizationType(item?.organization?.kind)
                      }}
                    >
                      <div className="top-[0px] absolute">
                        <img src={IconDot} alt="Icon-Dot" />
                        {showAction?.userRequestId === item.userId && showAction.status && (
                          <div
                            className={`absolute right-[2px] top-8 ${
                              isOpenModalApprove ? 'z-0' : 'z-10'
                            }`}
                            ref={menuRef}
                          >
                            <RowActionNewUserRequest
                              handleAdd={handleShowAddDialog}
                              handleMerge={handleShowMergeDialog}
                              handleApprove={handleShowApproveDialog}
                              handleReject={handleShowRejectDialog}
                              organizationType={item?.organization?.kind}
                            />
                          </div>
                        )}
                      </div>
                    </button>
                  </div>
                  <div className="flex justify-between ">
                    <li className="w-[28px] min-w-[28px]" />
                    <li className="w-[262px] min-w-[50px] break-all" />
                    <li className="w-[148px] min-w-[76px]">
                      <p className="w-full break-words">{`Business management`}</p>
                    </li>
                    <li className="w-[156px] min-w-[80px]">
                      <p className="w-full break-words">
                        {item?.businessSponsorFirstName} {item?.businessSponsorLastName}
                      </p>
                    </li>
                    <li className="w-[132px] min-w-[68px]">
                      <p className="w-full break-all">{item?.businessSponsorPhoneNumber}</p>
                    </li>
                    <li className="w-[228px] min-w-[120px]">
                      <p className="w-full break-all">{item?.businessSponsorEmail}</p>
                    </li>
                    <li className="w-[162px] min-w-[92px]" />
                    <li className="min-w-[24px] relative mt-[-25px]" />
                  </div>
                </ul>
              ))}
            </div>
            <AddAMSToTheSystemDialog
              modalIsOpen={isOpenModalAdd}
              handleCloseAddAMSDialog={handleCloseAddDialog}
              onHandleReFetchList={onHandleReFetchList}
              userInfo={userInfo}
            />
            <MergeAMSDialog
              modalIsOpen={isOpenModalMerge}
              handleCloseMergeAMSDialog={handleCloseMergeAMSDialog}
              userInfo={userInfo}
              onHandleReFetchList={onHandleReFetchList}
            />
            <ApproveDialog
              modalIsOpen={isOpenModalApprove}
              handleCloseDialog={handleCloseApproveDialog}
              onHandleReFetchList={onHandleReFetchList}
              userInfo={userInfo}
              carrier={carrier}
              organizationType={isOrganizationType}
            />
            <RejectUserQuest
              modalIsOpen={isOpenModalReject}
              handleCloseDialog={handleCloseRejectDialog}
              onHandleReFetchList={onHandleReFetchList}
              userInfo={userInfo}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default NewUserRequestTable
