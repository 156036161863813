import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import DateTimePicker from '../../../../../components/date-picker'
import ButtonBase from '../../../../../components/partials/button'
import {
  setFromDateForAdmin,
  setFromDateForAdminByAMS,
  setToDateForAdmin,
  setToDateForAdminByAMS,
} from '../../../../../redux/actions/adminPortal'
import { setFromDate, setToDate } from '../../../../../redux/actions/developerPortal'
import { RootState } from '../../../../../store'
import { Header } from './header'

type Props = {
  isOpenFilter?: boolean
  id?: string
  idSelected?: string
  handleClickCancel?: () => void
  onRef?: React.Ref<HTMLDivElement> | null
  identifier?: string
  isAgencyByAdmin?: boolean
}

export type TimeType = {
  hour: string
  minute: string
  second: string
  millisecond: string
}

export type InitialStateType = {
  date: Date
  time: TimeType
  identify?: string
}
export const initialState: InitialStateType = {
  date: new Date(),
  time: {
    hour: '00',
    minute: '00',
    second: '00',
    millisecond: '',
  },
}

const DatePickerFilter: React.FC<Props> = ({
  isOpenFilter,
  id,
  idSelected,
  onRef,
  handleClickCancel = async () => null,
  identifier,
  isAgencyByAdmin,
}) => {
  const dispatch = useDispatch()
  const requestFromDate: InitialStateType = useSelector((state: RootState) => {
    return identifier === Header.ADMIN
      ? isAgencyByAdmin
        ? state?.adminPortalReducer?.fromDateByAMS
        : state?.adminPortalReducer?.fromDate
      : state?.developerPortalReducer?.fromDate
  })
  const requestToDate: InitialStateType = useSelector((state: RootState) => {
    return identifier === Header.ADMIN
      ? isAgencyByAdmin
        ? state?.adminPortalReducer?.toDateByAMS
        : state?.adminPortalReducer?.toDate
      : state?.developerPortalReducer?.toDate
  })

  const [requestFrom, setRequestFrom] = useState<InitialStateType>(initialState)
  const [requestTo, setRequestTo] = useState<InitialStateType>(initialState)

  const [AMSRequestFrom, setAMSRequestFrom] = useState<InitialStateType>(initialState)
  const [AMSRequestTo, setAMSRequestTo] = useState<InitialStateType>(initialState)

  /**
   *
   * @param value
   * @param identify
   */
  const onSetCallBackDateTime = (value: InitialStateType, identify: string) => {
    if (value !== null && value !== undefined) {
      if (identify === Header.TYPE_FROM_DATE) {
        if (isAgencyByAdmin) {
          setAMSRequestFrom(value)
        } else {
          setRequestFrom(value)
        }
      } else {
        if (isAgencyByAdmin) {
          setAMSRequestTo(value)
        } else {
          setRequestTo(value)
        }
      }
    }
  }

  /**
   *
   */
  const handleClickFilter = () => {
    if (identifier !== null && identifier !== undefined) {
      if (identifier === Header.ADMIN) {
        if (isAgencyByAdmin) {
          dispatch(setFromDateForAdminByAMS(AMSRequestFrom))
          dispatch(setToDateForAdminByAMS(AMSRequestTo))
        } else {
          dispatch(setFromDateForAdmin(requestFrom))
          dispatch(setToDateForAdmin(requestTo))
        }
      } else {
        dispatch(setFromDate(requestFrom))
        dispatch(setToDate(requestTo))
      }
    }
    handleClickCancel()
  }

  return (
    <div id={id} ref={onRef} className="relative">
      {isOpenFilter && id === idSelected && (
        <div
          ref={onRef}
          className="absolute z-10 h-auto w-[488px] bg-white-50 rounded-2xl p-8 shadow-xl mt-3 top-5 left-[-210px]"
        >
          <p className="pb-4 text-headline4 text-neutral-1">From</p>
          <DateTimePicker
            setCallBackDateTime={onSetCallBackDateTime}
            identify={Header.TYPE_FROM_DATE}
            requestDateTime={requestFromDate}
            maxDate={isAgencyByAdmin ? AMSRequestTo?.date : requestTo?.date}
          />
          <hr className="mt-10 mb-8 border-t-neutral-6" />
          <p className="pb-4 text-headline4 text-neutral-1 font-montserrat">To</p>
          <DateTimePicker
            setCallBackDateTime={onSetCallBackDateTime}
            identify={Header.TYPE_TO_DATE}
            requestDateTime={requestToDate}
            minDate={isAgencyByAdmin ? AMSRequestFrom?.date : requestFrom?.date}
          />
          <hr className="mb-8 border-t-neutral-6 mt-9" />
          <div className="z-20 flex justify-between mt-6">
            <ButtonBase
              type="reset"
              className="w-[206px] h-[40px] bg-white border-2 border-violet-700 text-violet-800 ml-3 hover:bg-violet-500 m-0"
              onClick={handleClickCancel}
            >
              Cancel
            </ButtonBase>
            <ButtonBase
              type="submit"
              className="w-[206px] h-[40px] bg-violet-800 min-w-min"
              onClick={handleClickFilter}
            >
              Apply
            </ButtonBase>
          </div>
        </div>
      )}
    </div>
  )
}

export default DatePickerFilter
