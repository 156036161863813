import Home from '.'
import { PageRoute } from '../../routes/routerInterface'

const homeRoutes: PageRoute[] = [
  {
    path: '/',
    element: Home,
    isProtected: false,
  },
]

export default homeRoutes
