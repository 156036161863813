import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_AGENCY_BY_AMS_ADMIN } from '../../../queries/adminPortal'
import { LocationState } from '../../../constants/interface'
import DeleteAMSByAdmin from '../dialog/deleteAgencyByAdmin'
import CreateAMSByAdmin from '../dialog/createAMS'
import Loading from '../../../components/loading'
import CollapseItemForAgency from '../../../components/partials/collapseItemForAgency'
import ButtonBase from '../../../components/partials/button'

import IconMultipleUsers from '../../../assets/images/icon-multiple-users.svg'
import IconPlus from '../../../assets/images/icon-plus.svg'
import IconTrash from '../../../assets/images/icon-trash.svg'
import { useWindowSize } from '../../../helpers/useWindowSize'
import { screens } from '../../../constants/screens'

export const AgenciesListByAdmin = () => {
  const { state } = useLocation()
  const locationState: LocationState = state as object
  const [listCollapsesOpen, setListCollapsesOpen] = useState<number[]>([])
  const [listSelectedCollapses, setListSelectedCollapses] = useState<number[]>([])
  const [isPopupCreateAgencyByAdmin, setIsPopupCreateAgencyByAdmin] = useState<boolean>(false)
  const [isPopupDeleteAgencyByAdmin, setIsPopupDeleteAgencyByAdmin] = useState<boolean>(false)
  const [agencyByAdminDetail, setAgencyByAdminDetail] = useState<object | null>(null)
  const [isListAgencyAMSByAdmin, setIsListAgencyAMSByAdmin] = useState<any>([])
  const windowSize = useWindowSize()
  const responsive = Number(windowSize.width) <= screens.md

  //Get Agency By Admin List
  const {
    data: listAgencyAMSByAdmin,
    refetch: getAgencyAMSListByAdminRefetch,
    loading: getAgencyAMSListByAdminLoading,
  } = useQuery(GET_AGENCY_BY_AMS_ADMIN, {
    variables: {
      partyId: locationState?.partyId,
      kind: locationState?.kind,
    },
  })

  const handleRefetchData = async () => {
    setAgencyByAdminDetail(null)
    await getAgencyAMSListByAdminRefetch()
  }

  const handleOpenCollapse = (value: number) => {
    if (listCollapsesOpen.includes(value)) {
      setListCollapsesOpen(listCollapsesOpen.filter((val) => val != value))
      setListSelectedCollapses(listSelectedCollapses.filter((val) => val != value))
    } else {
      setListCollapsesOpen(listCollapsesOpen.concat(value))
      setListSelectedCollapses(listSelectedCollapses.concat(value))
      setAgencyByAdminDetail(null)
    }
  }

  const handleClosePopupCreateAgencyByAdmin = () => {
    setIsPopupCreateAgencyByAdmin(false)
  }

  const handleClosePopupDeleteAgencyByAdmin = () => {
    setIsPopupDeleteAgencyByAdmin(false)
  }

  useEffect(() => {
    setIsListAgencyAMSByAdmin(listAgencyAMSByAdmin?.getAgenciesByAMSAdmin)
  }, [listAgencyAMSByAdmin])

  if (getAgencyAMSListByAdminLoading) return <Loading />

  return (
    <div className="px-20 py-7">
      <div className="flex items-center justify-between">
        <p className="text-headline5A text-neutral-1 md:ml-[-40px]">Agency list</p>
        <ButtonBase
          type="submit"
          className="justify-around w-auto mt-3 border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4"
          onClick={() => setIsPopupCreateAgencyByAdmin(true)}
          bgDisabled="bg-primary-shade3"
        >
          <div className="flex justify-between">
            <img src={IconPlus} alt="" className="mr-3" />
            <div>Add Agency</div>
          </div>
        </ButtonBase>
      </div>
      <div className="md:mr-[-40px] md:ml-[-40px]">
        <div>
          {isListAgencyAMSByAdmin?.length ? (
            <div>
              {isListAgencyAMSByAdmin?.map((agencyByAMS: any, idx: number) => (
                <div key={idx}>
                  <CollapseItemForAgency
                    image={<img src={IconMultipleUsers} alt="" className="" />}
                    title={agencyByAMS?.agency?.agencyName}
                    information={
                      <div className={`${!responsive && 'flex'}`}>
                        <div className="flex pt-1 text-neutral-4">
                          <li className="pl-4 text-neutral-5" />
                          <div className="pr-2 font-medium text-body2 mt-[2px]">Agency Key:</div>
                          <div className="font-semibold text-body1 text-neutral-3">
                            <p className="overflow-hidden text-ellipsis whitespace-nowrap md:w-[140px] lg:max-w-[180px] ">
                              {agencyByAMS?.agency?.agencyKey}
                            </p>
                          </div>
                        </div>
                        <div className="flex pt-1 text-neutral-4">
                          <li className="pl-4 text-neutral-5" />
                          <div className="pr-2 font-medium text-body2 mt-[2px]">
                            AMS Agency Key:
                          </div>
                          {agencyByAMS?.agencyAmsKey === null ? (
                            <div className="mt-[2px] italic font-medium text-neutral-3 text-body2">
                              Not created
                            </div>
                          ) : (
                            <div className="max-w-[280px] lg:max-w-[140px] md:w-[100px] overflow-hidden font-semibold text-body1 text-neutral-3 text-ellipsis whitespace-nowrap">
                              {agencyByAMS?.agencyAmsKey}
                            </div>
                          )}
                        </div>
                      </div>
                    }
                    addAgencyKey={
                      <div
                        className="pt-1 pl-2 pr-3 border-2 border-none bg-neutral-7-header-footer hover:bg-neutral-7-header-footer"
                        onClick={(e) => {
                          setAgencyByAdminDetail(agencyByAMS)
                          setIsPopupDeleteAgencyByAdmin(true)
                          e.stopPropagation()
                        }}
                      >
                        <img src={IconTrash} alt="" />
                      </div>
                    }
                    id={idx}
                    handleOpenCollapse={() => handleOpenCollapse(idx)}
                    listCollapse={listCollapsesOpen}
                    isSelectedCollapse={listSelectedCollapses.includes(idx)}
                    agencyId={agencyByAMS?.agency?.agencyId}
                    isAgencyByAdmin
                  ></CollapseItemForAgency>
                </div>
              ))}
            </div>
          ) : (
            <div className="pt-5 pb-3 text-center text-body1 text-neutral-4">
              No data available.
            </div>
          )}
          <CreateAMSByAdmin
            modalIsOpen={isPopupCreateAgencyByAdmin}
            handleCloseDialog={handleClosePopupCreateAgencyByAdmin}
            handleRefetchData={handleRefetchData}
            partyId={locationState?.partyId}
            kind={locationState?.kind}
          ></CreateAMSByAdmin>
          <DeleteAMSByAdmin
            modalIsOpen={isPopupDeleteAgencyByAdmin}
            agencyInfoByAdmin={agencyByAdminDetail}
            handleRefetchData={handleRefetchData}
            handleCloseDialog={handleClosePopupDeleteAgencyByAdmin}
            partyId={locationState?.partyId}
            kind={locationState?.kind}
          ></DeleteAMSByAdmin>
        </div>
      </div>
    </div>
  )
}

export default AgenciesListByAdmin
