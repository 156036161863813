import React, { FC, ReactElement, useEffect } from 'react'
import Modal from 'react-modal'

export const customStyles = {
  content: {
    width: '420px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '24px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    boxShadow: '8px 11px 21px 0px rgba(148,144,144,0.75)',
    background: '#fdfdfd',
    border: '1px solid #F2F2F2',
  },
}

type Props = {
  handleCloseDialog?: () => void
  modalIsOpen?: boolean
  customStyles?: object
  children: ReactElement
}

const BaseDialog: FC<Props> = ({
  handleCloseDialog,
  modalIsOpen = false,
  customStyles,
  children,
}) => {
  useEffect(() => {
    Modal.setAppElement('body')
  })

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'overlay'
    }
  }, [modalIsOpen])

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleCloseDialog}
      style={customStyles}
      contentLabel="Modal"
    >
      {children}
    </Modal>
  )
}

export default BaseDialog
