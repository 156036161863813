import React, { ChangeEvent, useEffect, useState } from 'react'
import DatePicker from 'react-date-picker'
import iconCalendar from '../../assets/images/icon-calendar.svg'
import { DATA_TIME } from '../../constants/dataTime'
import {
  initialState,
  InitialStateType,
  TimeType,
} from '../../pages/developer-portal/table/activities-log/component/dateTimePicker'
import DropdownBase from '../partials/dropDown'
import InputBase from '../partials/inputBase'

type Option = {
  id: number | string
  name: string
}

interface Props {
  setCallBackDateTime?: (value: InitialStateType, identify: string) => void
  identify?: string
  requestDateTime?: InitialStateType
  minDate?: Date
  maxDate?: Date
}

const DateTimePicker: React.FC<Props> = ({
  identify = '',
  requestDateTime,
  setCallBackDateTime = () => initialState,
  minDate,
  maxDate,
}) => {
  const [value, setValue] = useState<Date>(new Date())
  const [time, setTime] = useState<TimeType>({
    hour: '00',
    minute: '00',
    second: '00',
    millisecond: '',
  })

  /**
   *
   */
  useEffect(() => {
    if (requestDateTime) {
      setValue(requestDateTime?.date)
      setTime({
        hour: requestDateTime?.time?.hour,
        minute: requestDateTime?.time?.minute,
        second: requestDateTime?.time?.second,
        millisecond: requestDateTime?.time?.millisecond,
      })
    }
  }, [])

  /**
   *
   * @param val
   * @param name
   */
  const getValue = (val: Option, name?: string) => {
    let object: TimeType = {
      hour: '00',
      minute: '00',
      second: '00',
      millisecond: '',
    }
    switch (name) {
      case 'hour':
        object = {
          ...time,
          hour: val?.name,
        }
        setTime({
          ...time,
          hour: val?.name,
        })
        break
      case 'minute':
        object = {
          ...time,
          minute: val?.name,
        }
        setTime({
          ...time,
          minute: val?.name,
        })
        break
      default:
        object = {
          ...time,
          second: val?.name,
        }
        setTime({
          ...time,
          second: val?.name,
        })
    }
    setCallBackDateTime(
      {
        date: value,
        time: object,
      },
      identify,
    )
  }

  /**
   *
   * @param evt
   */
  const handleChangeMillisecond = (evt: ChangeEvent<HTMLInputElement>) => {
    setTime((prevState) => {
      setCallBackDateTime(
        {
          date: value,
          time: {
            ...prevState,
            millisecond: evt.target.validity.valid ? evt.target.value : time.millisecond,
          },
        },
        identify,
      )

      return {
        ...time,
        millisecond: evt.target.validity.valid ? evt.target.value : time.millisecond,
      }
    })
  }

  /**
   *
   * @param event
   */
  const onChange = (event: Date) => {
    setValue(event)
    setCallBackDateTime(
      {
        date: event,
        time: time,
      },
      identify,
    )
  }

  return (
    <div>
      <DatePicker
        onChange={onChange}
        value={value}
        format={'yyyy-MM-dd'}
        className="w-full h-[52px] rounded-xl"
        calendarIcon={<img src={iconCalendar} width={24} height={24} />}
        clearIcon={null}
        minDate={minDate}
        maxDate={maxDate}
      />
      <div className="flex justify-between w-full gap-3">
        <div className="text-left w-[100px]">
          <p className="mt-4 mb-2 font-semibold text-neutral-2">Hour</p>
          <DropdownBase
            options={DATA_TIME.slice(0, 24)}
            handleOptions={getValue}
            name="hour"
            initialValue={time.hour}
          />
        </div>
        <div className="text-left w-[100px]">
          <p className="mt-4 mb-2 font-semibold text-neutral-2">Minute</p>
          <DropdownBase
            options={DATA_TIME}
            handleOptions={getValue}
            name="minute"
            initialValue={time.minute}
          />
        </div>
        <div className="text-left w-[100px]">
          <p className="mt-4 mb-2 font-semibold text-neutral-2">Second</p>
          <DropdownBase
            options={DATA_TIME}
            handleOptions={getValue}
            name="second"
            initialValue={time.second}
          />
        </div>
        <div className="text-left w-[100px]">
          <p className="mt-4 mb-2 font-semibold text-neutral-2">Millisecond</p>
          <p className="text-neutral-2 font-semibold mt-3 mb-2 text-[20px] absolute z-20 ml-3">.</p>
          <InputBase
            placeholder="000"
            type="text"
            pattern="\d*"
            maxLength={3}
            className="w-[100%] z-0"
            value={time.millisecond ?? ''}
            handleChange={(e) => handleChangeMillisecond(e)}
          />
        </div>
      </div>
    </div>
  )
}

export default DateTimePicker
