import QuickStart from '.'
import { checkProdSite } from '../../helpers/checkProdSite'
import { PageRoute } from '../../routes/routerInterface'

const quickStartRoutes: PageRoute[] = [
  {
    path: '/quick-start',
    element: QuickStart,
    isProtected: false,
    permission: checkProdSite(false, ''),
  },
]

export default quickStartRoutes
