import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch } from 'react-redux'

import { MESSAGES } from '../../../constants/messages'
import { onHandleCopyItem } from '../../../helpers/functionUtils'
import { tableHeader } from './data'
import { optionHover, stylePrincipalKey } from './models'
import IconCopy from '../../../assets/images/icon-hover-copy.svg'
import IconPAccFound from '../../../assets/images/icon-pacc-found.svg'
import { RootState } from '../../../store'
import { searchPrincipal } from '../../../redux/actions/adminPortal'
import Loading from '../../../components/loading'

const Principal = () => {
  const [hoverRowData, setHoverRowData] = useState<optionHover>({
    principalId: null,
    status: false,
  })
  const [isFeatchMore, setIsFetchMore] = useState(false)
  const searchPrincipalState = useSelector(
    (state: RootState) => state?.adminPortalReducer?.searchPrincipal,
  )

  const dispatch = useDispatch()

  const funcLoadMore = () => {
    dispatch(searchPrincipal(searchPrincipalState, 50))
  }

  const onHandleCopyPrincipalKey = async (event: any, principalKey: string) => {
    onHandleCopyItem(
      principalKey,
      MESSAGES.INFO.I_COPIED_PRINCIPAL_KEY,
      MESSAGES.ERROR.E_COPY_FAILED,
    )
    event.preventDefault()
  }

  useEffect(() => {
    if (searchPrincipalState?.data?.length < searchPrincipalState?.count) {
      return setIsFetchMore(true)
    }
    return setIsFetchMore(false)
  }, [searchPrincipalState])

  return (
    <div>
      <div className="flex justify-start mt-10 mb-5">
        <img src={IconPAccFound} alt="IconBag" className="w-6 h-6 mt-[2px]" />
        <span className="text-neutral-2 text-body1-med font-bold ml-4">
          {`${searchPrincipalState?.count} ${
            searchPrincipalState?.count < 2 ? 'Principal' : 'Principals'
          } found`}
        </span>
      </div>
      <div className="max-w-[1280px] min-w-[688px] top-[88px] border-2 border-neutral-6 bg-neutral-8 rounded-2xl z-10">
        <ul className="flex bg-primary-shade4 px-6 py-8 justify-between rounded-t-[14px]">
          {tableHeader.map((item, idx) => (
            <li className={item.minW} key={idx}>
              <span className={`font-montserrat font-bold text-base text-neutral-2 `}>
                {item.label}
              </span>
            </li>
          ))}
        </ul>
        <div className="cus-scrollbar pl-2 pr-1 py-5 overflow-auto">
          <InfiniteScroll
            dataLength={
              searchPrincipalState?.data?.length ? searchPrincipalState?.data?.length : []
            }
            next={funcLoadMore}
            hasMore={isFeatchMore}
            height={'max-content'}
            style={{ maxHeight: '550px' }}
            loader={null}
            endMessage={''}
            className="cus-scrollbar pr-1"
          >
            {searchPrincipalState?.data.map((item: any, idx: number) => (
              <ul
                className="flex justify-between px-4 py-5 h-auto font-montserrat font-medium text-base text-neutral-3 hover:bg-neutral-7 hover:text-neutral-1 mb-3 last:mb-0"
                key={idx}
                onMouseEnter={() => {
                  setHoverRowData({
                    principalId: idx,
                    status: true,
                  })
                }}
                onMouseLeave={() => {
                  setHoverRowData({
                    principalId: null,
                    status: false,
                  })
                }}
              >
                <li className="w-[28px] min-w-[28px]">{idx + 1}</li>
                <li className="w-[160px] min-w-[100px]">
                  <p className="w-full">{item?.principalName}</p>
                </li>
                <div className="relative w-[256px] min-w-[160px] break-all px-2">
                  <li style={{ ...stylePrincipalKey }}>{item.principalKey}</li>
                  {hoverRowData?.status &&
                    idx === hoverRowData.principalId &&
                    'item?.principalKey' && (
                      <button
                        className="absolute top-[-8px] right-0"
                        title="Copy this Principal key"
                        onClick={(event) =>
                          onHandleCopyPrincipalKey(event, item.principalKey) ?? ''
                        }
                      >
                        <img src={IconCopy} alt="Icon-Hover-Copy" />
                      </button>
                    )}
                </div>
                <li className="w-[156px] min-w-[100px]">
                  <p className="w-full">{item?.agencyName}</p>
                </li>
                <li className="w-[320px] min-w-[140px] px-2">
                  <p className="w-full">{item?.principalAddressLine1}</p>
                </li>
                <li className="w-[130px] min-w-[60px]">
                  <p className="break-all w-full">{item?.recordsCount}</p>
                </li>
              </ul>
            ))}
            {isFeatchMore && <Loading className="relative" height={30} width={30} />}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  )
}

export default Principal
