import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ButtonBase from '../../../components/partials/button'
import { MESSAGES } from '../../../constants/messages'
import { useSession } from '../../../helpers/checkSession'
import { SEARCH_PRINCIPAL, SEARCH_PRINCIPAL_ACCOUNT } from '../../../queries/adminPortal'
import {
  clearSearchPrincipal,
  clearValueSearchPrincipalAddress,
  clearValueSearchPrincipalName,
  countSearchPrincipalAccount,
  searchPrincipal,
  setValueSearchPrincipalAddress,
  setValueSearchPrincipalName,
} from '../../../redux/actions/adminPortal'
import { RootState } from '../../../store'
import AutocompletedInput from './autocompletedInput'
import Dropdown, { OptionType } from './dropdow'

export const options: OptionType[] = [
  {
    label: 'Principal Account',
    value: '1',
  },
  {
    label: 'Principal',
    value: '2',
  },
]
export type InputValueType = {
  name: string
  address: string
}
interface Props {
  setDropdownValueProps: (value: OptionType) => void
}
const FormAutocompleInput: React.FC<Props> = ({ setDropdownValueProps }) => {
  const searchBoxRef = useRef<HTMLDivElement>(null)
  const [isFocus, setIsFocus] = useState<boolean>(false)
  const [dropdownValue, setDropdownValue] = useState<OptionType>(options[0])
  const [inputValue, setInputValue] = useState<string | InputValueType>('')
  const [isDisableInput, setIsDisableInput] = useState<boolean>(false)

  const loadMoreSearchPrincipalState = useSelector(
    (state: RootState) => state?.adminPortalReducer?.loadMoreSearchPrincipal,
  )
  const searchPrincipalState = useSelector(
    (state: RootState) => state?.adminPortalReducer?.searchPrincipal,
  )

  const dispatch = useDispatch()
  const { handleErrorSession } = useSession()

  const [handleSearchPrincipal] = useMutation(SEARCH_PRINCIPAL)
  const [handleSearchPrincipalAccount] = useMutation(SEARCH_PRINCIPAL_ACCOUNT)

  const searchPrincipalFun = async (search = false) => {
    const dataPrincipal = {
      skip: !search ? searchPrincipalState?.data?.length : 0,
      take: 50,
      principalName: typeof inputValue === 'string' ? inputValue : inputValue.name,
      principalAddressLine1: typeof inputValue === 'string' ? '' : inputValue.address,
    }
    const dataPrincipalAccount = {
      skip: 0,
      take: 4,
      principalAccountName: typeof inputValue === 'string' ? inputValue : inputValue.name,
      principalAccountAddressLine1: typeof inputValue === 'string' ? '' : inputValue.address,
    }
    try {
      let res: any = null
      dispatch(
        setValueSearchPrincipalName(
          dataPrincipal.principalName || dataPrincipalAccount.principalAccountName,
        ),
      )
      dispatch(
        setValueSearchPrincipalAddress(
          dataPrincipal.principalAddressLine1 || dataPrincipalAccount.principalAccountAddressLine1,
        ),
      )
      dispatch(countSearchPrincipalAccount())
      if (dropdownValue.value === options[1].value) {
        const resPrincipal = await handleSearchPrincipal({
          variables: {
            data: dataPrincipal,
          },
        })
        res = await resPrincipal.data.searchPrincipalByNameAndAddress
      } else {
        const resPrincipal = await handleSearchPrincipalAccount({
          variables: {
            data: dataPrincipalAccount,
          },
        })
        res = resPrincipal.data.searchPrincipalAccountByNameAndAddress
      }
      if (searchPrincipalState.data && !search) {
        res = {
          count: searchPrincipalState.count,
          data: [...searchPrincipalState.data, ...res.data],
        }
      }
      dispatch(searchPrincipal(res, 0))
      setIsDisableInput(true)
      setDropdownValueProps(dropdownValue)
    } catch (error) {
      dispatch(clearSearchPrincipal())
      dispatch(clearValueSearchPrincipalName())
      dispatch(clearValueSearchPrincipalAddress())
      setIsDisableInput(false)
      handleErrorSession(error, MESSAGES.ERROR.E_TRY_AGAIN)
    }
  }

  useEffect(() => {
    if (loadMoreSearchPrincipalState) {
      searchPrincipalFun()
    }
  }, [loadMoreSearchPrincipalState])

  useEffect(() => {
    if (!inputValue) {
      return setIsDisableInput(true)
    }
    return setIsDisableInput(false)
  }, [inputValue])

  useEffect(() => {
    const onHandleClickOutSide = (e: any) => {
      if (searchBoxRef && !searchBoxRef.current?.contains(e.target)) {
        setIsFocus(false)
      }
    }

    document.addEventListener('click', onHandleClickOutSide)
    return () => {
      document.removeEventListener('click', onHandleClickOutSide)
    }
  }, [isFocus])

  return (
    <div className="flex">
      <div
        className={classNames('flex w-full h-[56px] rounded-xl border-[2px]', {
          'border-neutral-3': isFocus,
          'border-neutral-5': !isFocus,
        })}
        ref={searchBoxRef}
      >
        <Dropdown
          options={options}
          classbtn="border-0 border-r-2 rounded-none"
          setIsFocus={setIsFocus}
          setDropdownValue={setDropdownValue}
        />
        <AutocompletedInput
          classInput="border-0 w-full"
          setIsFocus={setIsFocus}
          dropdownValue={dropdownValue}
          setInputValue={setInputValue}
          isHandleSearch={true}
        />
      </div>
      <ButtonBase
        className="ml-5 min-w-[108px] disabled:bg-primary-shade3"
        disabled={isDisableInput}
        onClick={() => searchPrincipalFun(true)}
      >
        Search
      </ButtonBase>
    </div>
  )
}

export default FormAutocompleInput
