import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { setIsTimeOut } from '../redux/actions/users'
import { store } from '../store/index'

const SESSION_TIME_OUT = 2 * 60 * 60 * 1000

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_DEVELOPMENT_API_ENDPOINT,
})

// Config URL for Development site
const httpLinkDev = createHttpLink({
  uri: process.env.REACT_APP_DEVELOPMENT_API_ENDPOINT_DEV,
})

// Config URL for Production site
const httpLinkProd = createHttpLink({
  uri: process.env.REACT_APP_DEVELOPMENT_API_ENDPOINT_PROD,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken')
  const lastTimeRequest = localStorage.getItem('lastTimeRequest') as string
  const currentTime = Date.parse(new Date() as any)
  if (lastTimeRequest && currentTime - Number(lastTimeRequest) >= SESSION_TIME_OUT) {
    store.dispatch(setIsTimeOut(true))
    localStorage.setItem('lastTimeRequest', '')
  } else {
    store.dispatch(setIsTimeOut(false))
    localStorage.setItem('lastTimeRequest', `${currentTime}`)
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
})

// Config client for Development site
export const clientDev = new ApolloClient({
  link: authLink.concat(httpLinkDev),
  cache: new InMemoryCache(),
})

// Config client for Production site
export const clientProd = new ApolloClient({
  link: authLink.concat(httpLinkProd),
  cache: new InMemoryCache(),
})
