import React, { useState } from 'react'
import ActivitiesManagementAdminAMSTable from '../developer-portal/table/activities-log/admin/activitiesMngAdminByAMSTable'
import ActivitiesManagementAdminTable from '../developer-portal/table/activities-log/admin/activitiesMngAdminTable'
import SearchAMS from './components/searchAMS'

const SystemMonitoring = () => {
  const [isAgencyByAdmin] = useState<boolean>(true)
  return (
    <div className="px-20 py-10 md:px-10">
      <p className="mb-5 text-headline5A text-neutral-1">Carrier</p>
      <ActivitiesManagementAdminTable />
      <div className="">
        <div className="pt-20">
          <div className="font-bold text-headline5A text-neutral-1">Search AMS</div>
        </div>
        <SearchAMS />
        <div className="pb-5">
          <div className="font-bold text-headline5A text-neutral-1">AMS Activities</div>
        </div>
        <ActivitiesManagementAdminAMSTable isAgencyByAdmin={isAgencyByAdmin} />
      </div>
    </div>
  )
}

export default SystemMonitoring
