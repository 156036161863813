import PrincipalAccountManagement from '.'
import { PageRoute } from '../../routes/routerInterface'
import { checkProdSite } from '../../helpers/checkProdSite'

const principalAccountManagementRouters: PageRoute[] = [
  {
    path: '/principal-account-management',
    element: PrincipalAccountManagement,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
  },
]

export default principalAccountManagementRouters
