import React from 'react'
import classNames from 'classnames'

import {
  RegisterOptions,
  DeepMap,
  FieldError,
  UseFormRegister,
  Path,
  Controller,
  FieldValues,
  Control,
} from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import InputBase, { InputProps } from './inputBase'
import { FormErrorMessage } from './errorMessageBase'
import { MESSAGES } from '../../constants/messages'
import { get } from 'lodash'

export type FormInputProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>
  rules?: RegisterOptions
  register?: UseFormRegister<TFormValues>
  errors?: Partial<DeepMap<TFormValues, FieldError>>
  inputClassName?: string
  control?: Control<FieldValues, any> | any
} & Omit<InputProps, 'name'>

export const FormInputController = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  className,
  inputClassName,
  control,
  ...props
}: FormInputProps<TFormValues>): JSX.Element => {
  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMessages = get(errors, name)
  const hasError = !!(errors && errorMessages)

  return (
    <div className={className} aria-live="polite">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputBase
            isError={hasError}
            onChange={(e: any) => {
              onChange(e)
            }}
            value={value}
            aria-invalid={hasError}
            className={classNames(
              {
                'border-red-900 focus:border-red-900': hasError,
              },
              inputClassName,
            )}
            {...props}
            {...(register && register(name, rules))}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name as any}
        render={({ message }) => (
          <FormErrorMessage className="mt-1">
            {errors?.[name]?.type === 'maxLength' ? MESSAGES.ERROR.E_MAX_LENGTH : message}
          </FormErrorMessage>
        )}
      />
    </div>
  )
}
