import React, { FC } from 'react'
import { ToastContainer, toast, TypeOptions } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import classNames from 'classnames'
import IconClose from '../../assets/images/ic_close.svg'
import IconCloseBlack from '../../assets/images/ic_close-black.svg'
import { STATUS } from '../../constants/messages'

const title = {
  [STATUS.ERROR]: 'Error',
  [STATUS.INFO]: 'Inform',
  [STATUS.SUCCESS]: 'Congratulations',
  [STATUS.WARNING]: 'Warning',
}

// custtom button close toast
const CloseButton: FC<{ closeToast?: any; typeToast?: TypeOptions }> = ({
  closeToast,
  typeToast,
}) => {
  return (
    <span className={`mr-2 ${typeToast !== STATUS.INFO ? 'mt-2 h-fit' : ''}`} onClick={closeToast}>
      <img
        src={typeToast === toast.TYPE.WARNING ? IconCloseBlack : IconClose}
        alt="Icon close"
        style={{ maxWidth: '24px' }}
      />
    </span>
  )
}

export const notify = (message?: string, type: STATUS = STATUS.INFO) => {
  const CustomToast = () => {
    return (
      <div className="p-[2px] pr-0">
        {type !== STATUS.INFO && (
          <span
            className={`text-headline5A font-montserrat ${
              type === STATUS.WARNING ? 'text-neutral-3' : 'text-neutral-8'
            }`}
          >
            {title[type]}
          </span>
        )}
        <p
          className={classNames('text-body1 font-montserrat', {
            'text-neutral-3': type === STATUS.WARNING,
            'text-neutral-8': type !== STATUS.WARNING,
            'mt-2': type !== STATUS.INFO,
          })}
        >
          {message}
        </p>
      </div>
    )
  }
  switch (type) {
    case STATUS.SUCCESS:
      toast.success(CustomToast, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      break
    case STATUS.WARNING:
      toast.warning(CustomToast, {
        closeButton: <CloseButton typeToast={STATUS.WARNING} />,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      break
    case STATUS.INFO:
      toast.info(CustomToast, {
        closeButton: <CloseButton typeToast={STATUS.INFO} />,
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
      break
    default:
      toast.error(CustomToast, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 7000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
  }
}

const BaseToast: React.FC = () => {
  return (
    <ToastContainer
      closeButton={CloseButton}
      position="top-right"
      autoClose={3000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      icon={false}
      toastStyle={{
        borderRadius: '16px',
      }}
    />
  )
}

export default BaseToast
